import { Injectable, NgZone } from '@angular/core';
import { ReplaySubject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { UpgradeFirmwareCommand } from 'connectapi/src/ApiMessage/apiCommandTypes';
import { ConnectApi } from 'connectapi/src/ConnectApi';
import { ParamResponseResult } from 'connectapi/src/CAN_Messages/CanMessages';
import {
  EcuDisconnectEvent,
  ParameterChangedEvent,
  PeriodicDataChangedEvent,
  UpgradeFwStatusEvent,
  UpgradeEventType,
  ClearAlarmResponseEvent,
  AckAlarmResponseEvent,
  StringParameterChangedEvent,
} from 'connectapi/src/ApiMessage/apiResponseTypes';

import { BleCapacitorService } from './ble/ble-capacitor.service';

import { IParameterLink, STRINGREADPARAMETERIDS, UNITS_WITH_DEFINITION_FILES } from '@constants/parameters';
import { ToastService } from './toast.service';

import { DefinitionsQuery } from '@state/definitions/definitions.query';
import { DefinitionsService } from '@state/definitions/definitions.service';
import { DeviceService } from '@state/device/device.service';
import { DeviceQuery } from '@state/device/device.query';
import { AlarmService } from '@state/alarm/alarm.service';
import { FirmwareService } from '@state/firmware/firmware.service';

import { ModalService } from './modal.service';

import { WorkerService } from '@worker/worker.service';
import { WorkerMessage } from '@worker/models/worker-message.model';
import { WORKER_TOPIC } from '@worker/models/worker-topic.constants';

//import * as firebase from "firebase/app";

import { IAlarm } from '@interfaces/alarm.interface';
import { IFirmwareFile } from '@interfaces/firmware.interface';
import {
  IparameterDidUpdate,
  IparameterForUpdate,
  IstringParameterDidUpdate,
  IstringParameterForUpdate,
} from '@interfaces/request.interface';
import { IAlarmLog } from '@interfaces/alarm-log.interface';
import { AppQuery } from '@shared/state/app/app.query';
import { AlertController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AlarmQuery } from '@shared/state/alarm/alarm.query';

import { CONFIG } from '@shared/constants/config';
import { UserQuery } from '@shared/state/user/user.query';
import { AlarmStore } from '@shared/state/alarm/alarm.store';

import { FirmwareQuery } from '@shared/state/firmware/firmware.query';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { arrayUpsert } from '@datorama/akita';

import { RemoteUserService } from '@shared/state/remote-user/remote-user.service';
import { AppService } from '@shared/state/app/app.service';

import { FirestoreService } from './firestore.service';
import { Firestore } from '@angular/fire/firestore';
import { updateDoc, arrayUnion, doc } from 'firebase/firestore';

@Injectable({
  providedIn: 'root',
})
export class ConnectApiService {
  private definitionQueue: {
    ecuId: number;
    swVersion: string;
    buildNumber: number;
    compVersion: number;
    isExperimental: boolean;
    isUpgradeable: boolean;
    isSparePart: boolean;
    softwareVarient: number;
    softwareSubVarient: number;
  }[] = [];

  public connectAPi: ConnectApi;

  public firmware: IFirmwareFile;
  public checkboxes: any;

  //for sparePart firmware upload
  public firmwareSparePart: IFirmwareFile;
  public checkboxesSparePart: any;

  public periodicForUpdate: PeriodicDataChangedEvent[] = [];

  private workerUpdate$: Subscription;

  private parametersForUpdate$: Subscription;
  private stringParametersForUpdate$: Subscription;
  private getStringParameters$: Subscription;

  // private sound: Howl;

  private pendingClearAlarmLog$: Subscription;

  // private sound = new Howl({
  //   src: ['/assets/sounds/empty_loop_for_js_performance.ogg', '/assets/sounds/empty_loop_for_js_performance.wav'],
  //   volume: 0.1,
  //   autoplay: true, loop: true,
  // });

  private autoCalibrationParameters$: Subscription;

  private stopAutoCalibration$: Subscription;
  private timedOutParameters$: Subscription;
  private showFWUpgradeModal = true;
  private timedoutParameters: number[] = [];

  private isBackuping = false;
  private backUpParametersTimeoutMap: Map<number, number> = new Map<number, number>();

  constructor(
    private router: Router,
    private definitionsService: DefinitionsService,
    private definitionsQuery: DefinitionsQuery,
    private bleCapacitorService: BleCapacitorService,
    // private bleWebService: BleWebService,
    private deviceService: DeviceService,
    private deviceQuery: DeviceQuery,
    private alarmService: AlarmService,
    private toastService: ToastService,
    private firmwareService: FirmwareService,
    private remoteUserService: RemoteUserService,
    private workerService: WorkerService,
    private modalService: ModalService,
    private appQuery: AppQuery,
    private alertController: AlertController,
    private translateService: TranslateService,
    private angularFirestore: Firestore,
    private userQuery: UserQuery,
    protected platform: Platform,
    private ngZone: NgZone,
    private alarmStore: AlarmStore,
    private alarmQuery: AlarmQuery,
    private firmwareQuery: FirmwareQuery,
    private insomnia: Insomnia,
    private fireStoreService: FirestoreService,
    private appService: AppService // public readonly bleWeb: BluetoothCore
  ) {
    this.deviceQuery.isBackuping$.subscribe((res) => {
      this.isBackuping = res;
    });
  }

  async init(): Promise<void> {
    this.workerService.workerInit();
    await this.definitionsService.loadDefinitions();
    this.ngZone.runOutsideAngular(() => {
      this.workerUpdate$ = this.workerService.workerUpdate$.subscribe((res) => {
        switch (res.topic) {
          case WORKER_TOPIC.sendToDevice:
            // console.log ("send to device")
            this.sendToDevice(res.data);
            break;
          case WORKER_TOPIC.fetchDefinition:
            // console.log ("fetch definition")
            this.fetchDefinition(
              res.data.ecuId,
              res.data.swVersion,
              res.data.buildNumber,
              res.data.compVersion,
              res.data.isSparePart,
              res.data.softwareVarient,
              res.data.softwareSubVarient,
              res.data.isUpgradeable,
              res.data.isExperimental
            );
            break;
          case WORKER_TOPIC.parameter: {
            //console.log('parameter');
            this.updateParameterData(res.data);
            break;
          }
          case WORKER_TOPIC.stringParameter: {
            //console.log ("string parameter")
            //TODO: UPDATE PARAMETER STATE WITH THIS VALUE ALSO
            // this.updateParameterData(res.data);
            this.updateStringParameterData(res.data);
            break;
          }
          case WORKER_TOPIC.periodic: {
            this.updatePeriodicData(res.data);
            break;
          }
          case WORKER_TOPIC.periodicAlarm:
            this.alarmService.updateAlarmService(res.data as IAlarm);
            break;
          case WORKER_TOPIC.removePeriodicAlarm:
            this.alarmService.removeSpecificAlarm(res.data as { alarmId: number; ecuId: number });
            break;
          case WORKER_TOPIC.runFirmwareUpgrade:
            this.runFirmwareUpgrade(res.data as UpgradeFirmwareCommand[]);
            break;
          case WORKER_TOPIC.fwStatus:
            this.fwStatus(res.data as UpgradeFwStatusEvent);
            break;
          case WORKER_TOPIC.recieveAlarmLog:
            if (res.data.alarmLogStatus > 2) {
              this.toastService.warningToast('alarmlogs.timeout', true, {
                ecuId: res.data.ecuId,
                status: res.data.alarmLogStatus,
              });
            } else {
              this.recieveAlarmLog(res.data.alarms as IAlarmLog[]);
            }
            break;
          case WORKER_TOPIC.alarmlogCleared:
            this.alarmlogCleared(res.data as ClearAlarmResponseEvent);
            break;
          case WORKER_TOPIC.alarmCleared:
            this.alarmCleared(res.data.data as AckAlarmResponseEvent);
            break;
          case WORKER_TOPIC.handlePendingAlarmLog:
            this.handlePendingAlarmLog(res.data as boolean);
            break;
          // case WORKER_TOPIC.handleClearAlarmLog:
          //   this.handleClearAlarmLog(res.data as boolean);
          //   break;
          case WORKER_TOPIC.ecuDisconnect:
            this.ecuDisconnect(res.data as EcuDisconnectEvent);
            break;
          case WORKER_TOPIC.stopAutoCalibrate:
            this.deviceService.resetAutoCalibrationFlag();
            break;
          case WORKER_TOPIC.connectionRequestChanged:
            //console.log('checking',res.data);

            if (res.data !== 0) {
              var approved = res.data === 1 || res.data === 2;
              this.remoteUserService.setConnectionApproval(approved);
            } else {
              this.toastService.infoToast('toasts.connection not approved', true);
              this.resetConnectApi();
              this.router.navigate(['/start']);
            }

          default:
            break;
        }
      });
    });

    // Setup connectapi in webworker
    this.setUpSubscriptions();
  }

  private sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  private setUpSubscriptions() {
    const setupMessage = new WorkerMessage(WORKER_TOPIC.setupSubscriptions, {});
    this.workerService.doWork(setupMessage);

    this.parametersForUpdate$ = this.deviceQuery.parametersForUpdate$.subscribe((parametersForUpdate) => {
      if (parametersForUpdate?.length > 0) {
        for (const parameter of parametersForUpdate) {
          this.writeParameterToDevice(parameter);
        }
      }
    });

    this.stringParametersForUpdate$ = this.deviceQuery.stringParametersForUpdate$.subscribe(
      async (stringParametersForUpdate) => {
        if (stringParametersForUpdate?.length > 0) {
          for (const parameter of stringParametersForUpdate) {
            this.writeStringParameterToDevice(parameter);
            await this.sleep(200);
          }
        }
      }
    );

    this.autoCalibrationParameters$ = this.deviceQuery.autoCalibrationParameters$?.subscribe(
      (autoCalibrationParameters) => {
        if (autoCalibrationParameters?.length > 0) {
          //console.log(autoCalibrationParameters, 'autoCalibrationParameters')
          for (const parameter of autoCalibrationParameters) {
            this.autoCalibrate(parameter);
          }
        }
      }
    );

    this.stopAutoCalibration$ = this.deviceQuery.stopAutoCalibration$.subscribe((stopAutoCalibration) => {
      if (stopAutoCalibration) {
        this.stopAutoCalibration(stopAutoCalibration);
      }
    });

    this.getStringParameters$ = this.deviceQuery.getStringParameters$.subscribe(async (res) => {
      if (res?.boolean) {
        var params = STRINGREADPARAMETERIDS.stringResources.filter((x) => res.params.includes(x.parameterId));
        for (const param of params) {
          // await this.sleep(500);
          this.sendReadStringParameters({ ...param, value: '' });
        }

        this.deviceService.getStringParameters(false);
      }
    });

    this.timedOutParameters$ = this.deviceQuery.timedOutParameters$.subscribe(async (res) => {
      if (res.length > 0) {
        // setTimeout(() => {
        //   this.toastService.infoToast(`You have stringparameters that are timed out ${res.length}`)
        // }, 5000);
      }
    });
  }

  public async fetchStringParameters(params: number[]) {
    for (const param of STRINGREADPARAMETERIDS.stringResources.filter((x) => params.includes(x.parameterId))) {
      // await this.sleep(500);
      this.sendReadStringParameters({ ...param, value: '' });
    }
  }

  public resetConnectApi(): void {
    // console.log("RESETTING CONNECT API");
    this.workerUpdate$?.unsubscribe();
    this.parametersForUpdate$?.unsubscribe();
    this.autoCalibrationParameters$?.unsubscribe();
    this.stopAutoCalibration$?.unsubscribe();
    this.getStringParameters$?.unsubscribe();
    this.workerService.close();
  }

  private async sendToDevice(bytes: number[]): Promise<void> {
    this.bleCapacitorService.addBleDataToOutQueue(bytes);
  }

  /**
   * Start api connection
   */
  public connect(): void {
    var name = this.userQuery.currentUser?.firstName + ' ' + this.userQuery.currentUser?.lastName;

    const workerMessage = new WorkerMessage(WORKER_TOPIC.apiConnect, { name: name ?? 'Name could not be found' });
    this.workerService.doWork(workerMessage);
  }

  /**
   * Sends bytes into webworker
   * @param bytes Uint8Array
   */
  public handleResult(bytes: Uint8Array): void {
    //  console.log("handleResult", bytes.length);
    const message = new WorkerMessage(WORKER_TOPIC.handleResult, bytes);
    this.workerService.doWork(message);
  }

  // private playSound() {
  //   this.sound.play();
  // }

  // private stopSound() {
  //   this.sound.stop();
  // }

  /**
   * Sends readParameters into webworker
   * @param readParameters IParameterLink
   */
  public sendReadParameters(readParameters: IParameterLink[]): void {
    const message = new WorkerMessage(WORKER_TOPIC.readParameters, readParameters);
    this.workerService.doWork(message);
  }

  public sendReadStringParameters(readParameter: IstringParameterForUpdate): void {
    const message = new WorkerMessage(WORKER_TOPIC.readStringParameter, readParameter);
    this.workerService.doWork(message);
  }

  private fwStatus(fw: UpgradeFwStatusEvent): void {
    switch (fw['status']) {
      case UpgradeEventType.STARTING:
        // console.log('starting 1', fw);
        // this.modalService.openFirmwareUpgradeModal();
        this.toastService.infoToast('alerts.started', true, { node: this.getEcuAbbreviation(fw['ecuId']) });
        this.firmwareService.updateProgress(fw);
        this.firmwareService.setOngoing(true);
        break;
      case UpgradeEventType.RUNNING:
        //console.log('RUNNING 2', fw);
        this.firmwareService.updateProgress(fw);
        break;
      case UpgradeEventType.ABORTED:
        // this.stopSound();
        //  console.log('ABORTED 3', fw);
        this.toastService.warningToast('alerts.aborted', true, { node: this.getEcuAbbreviation(fw['ecuId']) });
        this.showFailedAlert(fw);
        this.firmwareService.setOngoing(false);
        this.connect();
        this.insomnia
          .allowSleepAgain()
          .then((ok) => console.log('APP IS NO LONGER AWAKE', ok))
          .catch((err) => console.log('ERROR', err));
        // const setupMessage = new WorkerMessage(WORKER_TOPIC.setupSubscriptions, {});
        // this.workerService.doWork(setupMessage);
        break;
      case UpgradeEventType.FINISHED:
        // console.log('FINISHED 4', fw);
        try {
          this.fireStoreService.updateLastUpdated(fw, this.firmware, this.getEcuAbbreviation(fw['ecuId']));
        } catch (error) {
          console.log('COULD NOT UPDATE LASTUPDATED');
        }
        this.firmwareService.updateProgress(fw);
        if (this.upgradeCommands.length > 0) {
          this.toastService.successToast('alerts.succeed', true, 'softwareupdate.software update', {
            node: this.getEcuAbbreviation(fw['ecuId']),
          });
          setTimeout(() => {
            this.firmwareSubject.next(this.upgradeCommands[0]);
          }, 2000);
        } else {
          // this.stopSound();
          this.clearExistingAlarms();
          this.firmwareService.setOngoing(false);
          this.connect();
          this.firmwareService.updateFirmwareCount();

          if (this.firmwareQuery.keepGoing) {
            this.runAutoUpdateFirmware();
          }

          if (!this.firmwareQuery.keepGoing) {
            this.firmwareService
              .updateFirmwareHasBeenUsed(this.firmware)
              .then((res) => {
                // console.log('RE SAVE OF FIRMWARE FILE SUCCEEDED');
              })
              .catch((err) => {
                console.error(err, 'RE SAVE OF FIRMWARE FILE FAILED');
              });
            this.showAllFinishedAlert();
            this.insomnia
              .allowSleepAgain()
              .then((ok) => console.log('APP IS NO LONGER AWAKE', ok))
              .catch((err) => console.log('ERROR', err));
          }

          // const setupMessage = new WorkerMessage(WORKER_TOPIC.setupSubscriptions, {});
          // this.workerService.doWork(setupMessage);
        }
        break;
      case UpgradeEventType.FAILED:
        //console.log('FAILED 5', fw);
        this.toastService.warningToast('toasts.unable to start upgrade', true);
        this.firmwareService.setOngoing(false);
        break;
      case UpgradeEventType.BUSY:
        //console.log('BUSY 6', fw);
        this.toastService.warningToast('toasts.unable to start upgrade', true);
        this.firmwareService.setOngoing(false);
        break;
      default:
        //console.log('FAILED UNKNOWN SCENARIO', fw);
        this.tempAlert(fw);
        this.toastService.warningToast('UKNOWN SCENARIO: ' + JSON.stringify(fw), false);
        this.firmwareService.setOngoing(false);
        break;
    }
  }

  private ecuDisconnect(data: EcuDisconnectEvent): void {
    this.alarmService.removeActiveAlarmsForEcu(data['ecuId']);
    this.deviceService.removeDefinitionVersion(data['ecuId']);
    // console.log('ecuDisconnect: ', data);
  }

  private clearExistingAlarms() {
    const message = new WorkerMessage(WORKER_TOPIC.clearExistingAlarms, {});
    this.workerService.doWork(message);
  }

  /**
   * From other services
   */
  public writeParameterToDevice(parameter: IparameterForUpdate): void {
    if (!this.deviceQuery.isOldData) {
      const message = new WorkerMessage(WORKER_TOPIC.writeParameter, parameter);
      this.workerService.doWork(message);
    }
  }

  public writeStringParameterToDevice(parameter: IstringParameterForUpdate): void {
    const message = new WorkerMessage(WORKER_TOPIC.writeStringParameter, parameter);
    this.workerService.doWork(message);
  }

  // public clearAlarm(alarm: IAlarm): void {
  //   const sendAlarmMessage = new WorkerMessage(WORKER_TOPIC.clearAlarm, {
  //     ecuId: alarm.ecuId,
  //     alarmCode: alarm.errorCodeId,
  //   });
  //   this.workerService.doWork(sendAlarmMessage);
  // }

  private autoCalibrate(item: any) {
    // console.log (item, 'autoCalibrate- ConnectAPIService')
    const message = new WorkerMessage(WORKER_TOPIC.autoCalibrate, item);
    this.workerService.doWork(message);
  }

  private stopAutoCalibration(item: any) {
    const message = new WorkerMessage(WORKER_TOPIC.stopAutoCalibrate, item);
    this.workerService.doWork(message);
  }

  public removeAlarm(alarm: IAlarm) {
    const sendAlarmMessage = new WorkerMessage(WORKER_TOPIC.clearAlarm, {
      ecuId: alarm.ecuId,
      alarmCode: alarm.errorCodeId,
    });
    this.workerService.doWork(sendAlarmMessage);
  }

  private firmwareSubject = new ReplaySubject<UpgradeFirmwareCommand>();

  private upgradeCommands: UpgradeFirmwareCommand[] = [];

  public async updateFirmware(firmware: IFirmwareFile, checkboxes: any): Promise<void> {
    this.firmware = firmware;
    this.checkboxes = checkboxes;
    const updateMessage = new WorkerMessage(WORKER_TOPIC.sendFirmware, { firmware: firmware, checkboxes: checkboxes });
    this.workerService.doWork(updateMessage);
  }

  public async updateFirmwareSparePart(firmwareSparePart: IFirmwareFile, checkboxesSparePart: any): Promise<void> {
    this.firmwareSparePart = firmwareSparePart;
    this.checkboxesSparePart = checkboxesSparePart;
    const updateMessage = new WorkerMessage(WORKER_TOPIC.sendFirmware, {
      firmware: firmwareSparePart,
      checkboxes: checkboxesSparePart,
    });
    this.workerService.doWork(updateMessage);
  }

  public async runAutoUpdateFirmware() {
    setTimeout(() => {
      if (this.firmwareQuery.keepGoing) {
        const updateMessage = new WorkerMessage(WORKER_TOPIC.sendFirmware, {
          firmware: this.firmware,
          checkboxes: this.checkboxes,
        });
        this.workerService.doWork(updateMessage);
      }
    }, 8000);
  }

  private fetchDefinition(
    ecuId: number,
    swVersion: string,
    buildNumber: number,
    compVersion: number,
    isSparePart: boolean,
    softwareVarient: number,
    softwareSubVarient: number,
    isUpgradeable: boolean,
    isExperimental: boolean
  ) {
    //  console.log('fetch def', this.definitionQueue);

    if (!this.definitionQueue.find((x) => x.ecuId === ecuId)) {
      // console.log('ADDING TO QUEUE');

      this.definitionQueue.push({
        ecuId,
        swVersion,
        buildNumber,
        compVersion,
        isExperimental,
        isUpgradeable,
        isSparePart,
        softwareVarient,
        softwareSubVarient,
      });
      console.log(this.definitionQueue);
    }

    if (!this.firmwareQuery.ongoingUpgrade) {
      this.appService.SetFetchingDefinition(true);
      for (const def of this.definitionQueue) {
        //  console.log('HANDLING ECU', def);
        if (!def.softwareVarient && def.ecuId === 1) {
          def.softwareVarient = 100;
        }
        this.deviceService.addDefinitionVersion(
          def.ecuId,
          this.getEcuAbbreviation(def.ecuId),
          this.getSystemAbbreviation(def.softwareVarient),
          def.swVersion,
          def.buildNumber,
          def.compVersion,
          def.isExperimental,
          def.isUpgradeable,
          def.isSparePart,
          def.softwareVarient,
          def.softwareSubVarient
        );

        // const definition = this.definitionsQuery.file(ecuId, compVersion); // OLD
        if (UNITS_WITH_DEFINITION_FILES.includes(def.ecuId)) {
          const definition = this.definitionsQuery.compatibleFile(def.ecuId, def.buildNumber, def.compVersion);

          if (definition) {
            // console.log('ADDING DEFINITION',def);

            const message = new WorkerMessage(WORKER_TOPIC.addDefinition, { ecuId: def.ecuId, definition });
            this.workerService.doWork(message);
          } else {
            this.toastService.warningToast('toasts.could not find definition', true, {
              ecuId: def.ecuId,
              compVersion: def.compVersion,
            });
          }
          // console.log('ECU HANDELED', def);
        }
      }
      // console.log('RESETTING QUEUE');
      this.definitionQueue = [];
      setTimeout(() => {
        if (this.appQuery.fetchingDefinition) {
          this.appService.SetFetchingDefinition(false);
          this.deviceService.refreshParameters();
        }
      }, 5000);
    }
  }

  private getEcuAbbreviation(EcuID: number): string {
    switch (EcuID) {
      case 1:
        return 'TCU';

      case 2:
        return 'CCU';

      case 3:
        return 'HCU';

      case 4:
        return 'JLE';

      case 5:
        return 'JRI';

      case 6:
        return 'HMI';

      case 7:
        return 'CAC1';

      case 8:
        return 'CAC2';

      case 9:
        return 'DCU';

      default:
        return 'Unknown';
    }
  }

  /*
   *for TCS*/
  private getSystemAbbreviation(SV: number): string {
    //console.log (SV)
    switch (SV) {
      case 0:
        return 'not a system';

      case 1:
        return 'Test';

      case 2:
        //return 'MIC 4.0';
        return 'RCS';

      case 3:
        return 'Standalone';

      case 4:
        return 'MIC 4.0';

      case 5:
        return 'TCS';

      default:
        return 'Unknown';
    }
  }

  private recieveAlarmLog(alarmLog: IAlarmLog[]): void {
    this.alarmService.updateAlarmLog(alarmLog);
  }

  private alarmlogCleared(data: ClearAlarmResponseEvent): void {
    this.alarmStore.update((state) => ({
      latestAlarmLogToBeCleared: arrayUpsert(state.latestAlarmLogToBeCleared, data['ecuId'], data, 'ecuId'),
    }));
  }

  private alarmCleared(data: AckAlarmResponseEvent): void {
    this.alarmStore.update({ latestAlarmToBeDismissed: [data] });
    // const latestAlarmToBeDismissed = this.alarmQuery.latestAlarmToBeDismissed;
    const status = data['alarmStatus'];
    if (status > 2) {
      this.toastService.warningToast('toasts.alarm dismiss fail', true, { status: status });
    } else {
      this.toastService.successToast('toasts.alarm dismiss', true, 'toasts.alarm');
    }
    // const alarmsArray = this.alarmQuery.alarms;

    // const alarmToBeRemoved = alarmsArray.findIndex((element) => element.errorCodeId === latestAlarmToBeDismissed.alarmCode);

    // if (alarmToBeRemoved !== -1) {
    //   alarmsArray.splice(alarmToBeRemoved, 1);
    // }

    //   this.alarmStore.update((state) => ({ ...state, alarms: alarmsArray }));
  }

  private handlePendingAlarmLog(bool: boolean): void {
    this.alarmService.updatePendingAlarmLog(bool);
  }

  // private handleClearAlarmLog(bool: boolean): void {
  //   this.alarmService.updateClearAlarmLog(bool);

  //   this.pendingClearAlarmLog$ = this.alarmQuery.pendingClearAlarmLog$.subscribe(alarm => {
  //     if (alarm === 0) {
  //       const setupMessage = new WorkerMessage(WORKER_TOPIC.getAlarmLog, {});
  //       this.workerService.doWork(setupMessage);
  //     }
  //   })
  // }

  private checkIfWrittenFromServiceTool(data) {
    const userUid = this.userQuery.userUid;
    const docUid = this.deviceQuery.requestUid;
    //console.log(userUid, docUid);
    const { ecuId, parameterId, value, status } = data;
    // console.log('data', data);
    // let paramToAck: IparameterForUpdate;
    // var param = this.deviceQuery.parametersForUpdate.find(el => el.ecuId === data['ecuId']);
    // if (param) {
    //   paramToAck.push(param);
    // }
    // if (this.userQuery.serviceToolHasBleConnection) {
    //   if (this.deviceQuery.parametersForUpdateServiceTool.find((el => el.parameterId === parameterId))) {
    //     var parameterDidUpdate: IparameterDidUpdate = {ecuId, parameterId, value, status};
    //     this.deviceService.addParameterDidUpdate(parameterDidUpdate);
    //   }
    // } else {
    // console.log('this.deviceQuery?.parametersForUpdate', this.deviceQuery?.parametersForUpdate);
    if (this.deviceQuery?.parametersForUpdate?.find((el) => el.parameterId === parameterId)) {
      this.toastService.successToast('toasts.parameter update from service', true, 'toasts.parameter update', {
        parameterId: parameterId,
      });
      // console.log(this.userQuery.serviceToolHasBleConnection);
      if (!this.userQuery.serviceToolHasBleConnection) {
        // console.log('i am here in checkIfWrittenFromServiceTool ');
        try {
          // Create a reference to the document
          const userDocRef = doc(
            this.angularFirestore,
            `${CONFIG.firebaseCollection.users}/${userUid}/${CONFIG.firebaseCollection.remoteRequests}/${docUid}`
          );

          // Update the document
          updateDoc(userDocRef, {
            updatedParameters: arrayUnion({
              ecuId: ecuId,
              parameterId: parameterId,
              status: status,
              value: value,
            }),
          });

          console.log('Document successfully updated!');
        } catch (error) {
          console.error('Error updating document:', error);
        }
      }
    }
  }

  private checkIfStringWrittenFromServiceTool(data) {
    const userUid = this.userQuery.userUid;
    const docUid = this.deviceQuery.stringRequestUid;
    const { ecuId, parameterId, value, status } = data;
    // let paramToAck: IparameterForUpdate;
    // var param = this.deviceQuery.parametersForUpdate.find(el => el.ecuId === data['ecuId']);
    // if (param) {
    //   paramToAck.push(param);
    // }
    // if (this.userQuery.serviceToolHasBleConnection) {
    //   if (this.deviceQuery.parametersForUpdateServiceTool.find((el => el.parameterId === parameterId))) {
    //     var parameterDidUpdate: IparameterDidUpdate = {ecuId, parameterId, value, status};
    //     this.deviceService.addParameterDidUpdate(parameterDidUpdate);
    //   }
    // } else {
    if (this.deviceQuery.stringParametersForUpdate.find((el) => el.parameterId === parameterId)) {
      this.toastService.successToast('toasts.parameter update from service', true, 'toasts.parameter update', {
        parameterId: parameterId,
      });
      if (!this.userQuery.serviceToolHasBleConnection) {
        try {
          // Create a reference to the document
          const userDocRef = doc(
            this.angularFirestore,
            `${CONFIG.firebaseCollection.users}/${userUid}/${CONFIG.firebaseCollection.remoteRequests}/${docUid}`
          );

          // Update the document
          updateDoc(userDocRef, {
            updatedStringParameters: arrayUnion({
              ecuId: ecuId,
              parameterId: parameterId,
              status: status,
              value: value,
            }),
          });

          // console.log('Document successfully updated!');
        } catch (error) {
          console.error('Error updating document:', error);
        }

        /*this.angularFirestore.collection(CONFIG.firebaseCollection.users)
          .doc(userUid)
          .collection(CONFIG.firebaseCollection.remoteRequests)
          .doc(docUid)
          .update({ updatedStringParameters: firebase.firestore.FieldValue.arrayUnion({ ecuId: ecuId, parameterId: paramId, status: status, value: stringValue }) })*/
      }
      // }
    }
  }

  private checkIfWrittenFromServiceToolWithBleConnection(data) {
    const { ecuId, parameterId, value, status } = data;
    if (this.userQuery.serviceToolHasBleConnection) {
      if (this.deviceQuery.parametersForUpdateServiceTool.find((el) => el.parameterId === parameterId)) {
        var parameterDidUpdate: IparameterDidUpdate = { ecuId, parameterId, value, status };
        this.deviceService.addParameterDidUpdate(parameterDidUpdate);
        //console.log('ADDED', parameterId, ' TO STATE');
      }
    }
  }

  private checkIfStringWrittenFromServiceToolWithBleConnection(data) {
    const { paramId, stringValue, status, eventType, parameterDef } = data;
    if (this.userQuery.serviceToolHasBleConnection) {
      if (this.deviceQuery.stringParametersForUpdateServiceTool.find((el) => el.parameterId === paramId)) {
        var parameterDidUpdate: IstringParameterDidUpdate = {
          ecuId: 6,
          parameterId: paramId,
          value: stringValue,
          status,
        };
        this.deviceService.addStringParameterDidUpdate(parameterDidUpdate);
      }
    }
  }

  private updateParameterData(data: any): void {
    console.log(data);
    const { ecuId, parameterId, value, status, parameterDef } = data;

    this.checkIfWrittenFromServiceToolWithBleConnection(data);
    console.log('status', status);
    switch (status) {
      case ParamResponseResult.OK:
        //console.log('SUCCESS: ', data);
        this.checkIfWrittenFromServiceTool(data);
        const parameter = new ParameterChangedEvent(parameterId, value, status);
        this.deviceService.updateParameterValue(parameter, parameterDef);
        if (this.isBackuping) {
          this.deviceService.removeParameterForBackup({ parameterId: parameterId, ecuId: ecuId, value: value });
        }

        this.timedoutParameters.filter((x) => x != parameterId);
        this.backUpParametersTimeoutMap.delete(parameterId);
        break;
      case ParamResponseResult.NOT_SUPPOTED:
        console.log('NOT_SUPPOTED: ', data);
        this.toastService.warningToast(`NOT_SUPPOTED ${ecuId}-${parameterId}`);
        if (this.isBackuping) {
          this.deviceService.removeParameterForBackup({ parameterId: parameterId, ecuId: ecuId, value: value });
        }
        break;
      case ParamResponseResult.UNKNOWN_PARAM:
        console.log('UNKNOWN_PARAM: ', data);
        this.toastService.warningToast(`UNKNOWN_PARAM ${ecuId}-${parameterId}`);
        if (this.isBackuping) {
          this.deviceService.removeParameterForBackup({ parameterId: parameterId, ecuId: ecuId, value: value });
        }
        break;
      case ParamResponseResult.OUT_OF_RANGE:
        console.log('OUT_OF_RANGE: ', data);
        this.toastService.warningToast(`OUT_OF_RANGE ${ecuId}-${parameterId}`);

        if (this.isBackuping) {
          this.deviceService.removeParameterForBackup({ parameterId: parameterId, ecuId: ecuId, value: value });
        }
        break;
      case ParamResponseResult.BUSY:
        console.log('BUSY: ', data);

        if (this.isBackuping) {
          var ref = this.backUpParametersTimeoutMap.get(parameterId);
          if (ref !== undefined && ref > 5) {
            // STOP RETRYING
            // this.toastService.warningToast(`TIMEOUT ${ecuId}-${parameterId}`);
            this.backUpParametersTimeoutMap.delete(parameterId);
            this.deviceService.removeParameterForBackup({ parameterId: parameterId, ecuId: ecuId, value: value });
          } else {
            console.info(`REWRITING PARAMETER FOR BACKUP WITH PARAMETER ID: ${parameterId}`);
            var param = this.deviceQuery.parametersAndStringParametersForBackup.find(
              (param) => param.parameterId === parameterId
            );
            if (param) {
              if (ref !== undefined) {
                ref += 1;
              } else {
                this.backUpParametersTimeoutMap.set(parameterId, 0);
              }
              setTimeout(() => {
                this.writeParameterToDevice({
                  parameterId: param.parameterId,
                  value: param.value as number,
                  ecuId: param.ecuId,
                });
              }, 1000);
            }
          }
        } else {
          this.toastService.warningToast(`BUSY ${ecuId}-${parameterId}`);
        }

        break;
      case ParamResponseResult.ACCESS_NOT_ALLOWED:
        console.log('ACCESS_NOT_ALLOWED: ', data);

        if (this.isBackuping) {
          this.deviceService.removeParameterForBackup({ parameterId: parameterId, ecuId: ecuId, value: value });
        } else {
          this.toastService.warningToast(`ACCESS_NOT_ALLOWED ${ecuId}-${parameterId}`);
        }
        break;
      case ParamResponseResult.TIMEOUT:
        // console.trace("TIMEOUT");
        //   console.log('TIMEOUT: ', data);

        if (this.isBackuping) {
          var ref = this.backUpParametersTimeoutMap.get(parameterId);
          if (ref !== undefined && ref > 5) {
            // STOP RETRYING
            //this.toastService.warningToast(`TIMEOUT ${ecuId}-${parameterId}`);
            this.backUpParametersTimeoutMap.delete(parameterId);
            this.deviceService.removeParameterForBackup({ parameterId: parameterId, ecuId: ecuId, value: value });
          } else {
            console.info(`REWRITING PARAMETER FOR BACKUP WITH PARAMETER ID: ${parameterId}`);
            var param = this.deviceQuery.parametersAndStringParametersForBackup.find(
              (param) => param.parameterId === parameterId
            );
            if (param) {
              if (ref !== undefined) {
                ref += 1;
              } else {
                this.backUpParametersTimeoutMap.set(parameterId, 0);
              }

              setTimeout(() => {
                this.writeParameterToDevice({
                  parameterId: param.parameterId,
                  value: param.value as number,
                  ecuId: param.ecuId,
                });
              }, 1000);
            }
          }
        } else {
          const parameterTimedOut = new ParameterChangedEvent(parameterId, parameterDef?.defaultValue ?? 0, status);

          this.deviceService.upsertTimedOutParameter(parameterTimedOut);
          if (!this.timedoutParameters.includes(parameterId)) {
            setTimeout(() => {
              this.sendReadParameters([{ ecuId: ecuId, parameterId: parameterId }]);
              this.timedoutParameters.push(parameterId);
            }, 2000);
          } else {
            this.deviceService?.updateParameterValue(parameterTimedOut, parameterDef);
            //this.toastService.warningToast(`TIMEOUT ${ecuId}-${parameterId}`);
          }

          this.deviceService.updateParameterValue(parameterTimedOut, parameterDef);
          // this.toastService.warningToast(`TIMEOUT ${ecuId}-${parameterId}`);
        }

        break;
      default:
        // console.log('default: ', data);

        if (this.isBackuping) {
          this.deviceService.removeParameterForBackup({ parameterId: parameterId, ecuId: ecuId, value: value });
        }
        break;
    }
  }

  private updateStringParameterData(data: any): void {
    const { paramId, stringValue, status, eventType, parameterDef, ecuId } = data;
    const parameter = new StringParameterChangedEvent(paramId, stringValue, status, eventType);
    if (stringValue === undefined && status === ParamResponseResult.OK) {
      if (paramId !== 0) {
        this.sendReadStringParameters({ parameterId: paramId, value: '', ecuId: 6 });
      }
    } else {
      switch (status) {
        case ParamResponseResult.OK:
          // console.log('SUCCESS: ', data);
          this.checkIfStringWrittenFromServiceToolWithBleConnection(data);
          this.checkIfStringWrittenFromServiceTool(data);
          this.deviceService.updateStringParameterValue(parameter, parameterDef);

          if (this.isBackuping) {
            this.deviceService.removeParameterForBackup({ parameterId: paramId, ecuId: ecuId, value: stringValue });
          }

          this.timedoutParameters.filter((x) => x != paramId);
          this.backUpParametersTimeoutMap.delete(paramId);
          break;
        case ParamResponseResult.NOT_SUPPOTED:
          //e.log('NOT_SUPPOTED: ', data);
          this.toastService.warningToast(`NOT_SUPPOTED ${ecuId}-${paramId}`);

          if (this.isBackuping) {
            this.deviceService.removeParameterForBackup({ parameterId: paramId, ecuId: ecuId, value: stringValue });
          }
          break;
        case ParamResponseResult.UNKNOWN_PARAM:
          // console.log('UNKNOWN_PARAM: ', data);
          this.toastService.warningToast(`UNKNOWN_PARAM ${ecuId}-${paramId}`);

          if (this.isBackuping) {
            this.deviceService.removeParameterForBackup({ parameterId: paramId, ecuId: ecuId, value: stringValue });
          }
          break;
        case ParamResponseResult.OUT_OF_RANGE:
          // console.log('OUT_OF_RANGE: ', data);
          this.toastService.warningToast(`OUT_OF_RANGE ${ecuId}-${paramId}`);

          if (this.isBackuping) {
            this.deviceService.removeParameterForBackup({ parameterId: paramId, ecuId: ecuId, value: stringValue });
          }
          break;
        case ParamResponseResult.BUSY:
          // console.log('BUSY: ', data);
          if (this.isBackuping) {
            var ref = this.backUpParametersTimeoutMap.get(paramId);
            if (ref !== undefined && ref > 5) {
              // STOP RETRYING
              this.toastService.warningToast(`BUSY ${ecuId}-${paramId}`);
              this.backUpParametersTimeoutMap.delete(paramId);
              this.deviceService.removeParameterForBackup({ parameterId: paramId, ecuId: ecuId, value: stringValue });
            } else {
              console.info(`REWRITING PARAMETER FOR BACKUP WITH PARAMETER ID: ${paramId}`);
              var param = this.deviceQuery.parametersAndStringParametersForBackup.find(
                (param) => param.parameterId === paramId
              );
              if (param) {
                if (ref !== undefined) {
                  ref += 1;
                } else {
                  this.backUpParametersTimeoutMap.set(paramId, 0);
                }

                setTimeout(() => {
                  this.writeStringParameterToDevice({
                    parameterId: param.parameterId,
                    value: param.value as string,
                    ecuId: param.ecuId,
                  });
                }, 2000);
              }
            }
          } else {
            if (!this.timedoutParameters.includes(paramId)) {
              setTimeout(() => {
                this.sendReadStringParameters({ ecuId: ecuId, value: '', parameterId: paramId });
                this.timedoutParameters.push(paramId);
              }, 2000);
            } else {
              this.deviceService.updateStringParameterValue(parameter, parameterDef);
              this.toastService.warningToast(`BUSY ${ecuId}-${paramId}`);
            }
          }
          break;
        case ParamResponseResult.ACCESS_NOT_ALLOWED:
          // console.log('ACCESS_NOT_ALLOWED: ', data);

          if (this.isBackuping) {
            this.deviceService.removeParameterForBackup({ parameterId: paramId, ecuId: ecuId, value: stringValue });
          } else {
            this.toastService.warningToast(`ACCESS_NOT_ALLOWED ${ecuId}-${paramId}`);
          }
          break;
        case ParamResponseResult.TIMEOUT:
          // console.trace("TIMEOUT");

          // console.log('TIMEOUT: ', data);
          if (this.isBackuping) {
            const ref = this.backUpParametersTimeoutMap.get(paramId);
            if (ref !== undefined && ref > 5) {
              // STOP RETRYING
              // this.toastService.warningToast(`TIMEOUT ${ecuId}-${paramId}`);
              this.backUpParametersTimeoutMap.delete(paramId);
              this.deviceService.removeParameterForBackup({ parameterId: paramId, ecuId: ecuId, value: stringValue });
            } else {
              console.info(`REWRITING PARAMETER FOR BACKUP WITH PARAMETER ID: ${paramId}`);
              var param = this.deviceQuery.parametersAndStringParametersForBackup.find(
                (param) => param.parameterId === paramId
              );
              if (param) {
                if (ref !== undefined) {
                  this.backUpParametersTimeoutMap.set(paramId, ref + 1);
                } else {
                  this.backUpParametersTimeoutMap.set(paramId, 0);
                }

                setTimeout(() => {
                  this.writeStringParameterToDevice({
                    parameterId: param.parameterId,
                    value: param.value as string,
                    ecuId: param.ecuId,
                  });
                }, 2000);
              }
            }
          } else {
            // this.toastService.warningToast(`STRING PARAMETER TIMEOUT ${ecuId}-${parameter.ParameterId}`);
            if (!this.timedoutParameters.includes(paramId)) {
              setTimeout(() => {
                // this.sendReadStringParameters({ ecuId: ecuId, value: '', parameterId: paramId })
                this.timedoutParameters.push(paramId);
              }, 2000);
            } else {
              this.deviceService.updateStringParameterValue(parameter, parameterDef);
              // this.toastService.warningToast(`TIMEOUT ${ecuId}-${paramId}`);
            }
          }
          // const parameterTimedOut = new StringParameterChangedEvent(paramId, '', parameterDef?.defaultValue ?? 0, status);

          // this.deviceService.upsertTimedOutParameter(parameterTimedOut);

          // this.toastService.warningToast(`TIMEOUT ${ecuId}-${parameterId}`);
          break;
        default:
          // console.log('default: ', data);
          if (this.isBackuping) {
            this.deviceService.removeParameterForBackup({ parameterId: paramId, ecuId: ecuId, value: stringValue });
          }
          break;
      }
    }
  }

  private updatePeriodicData(data: any): void {
    const { periodic, periodicParameter } = data;
    const { ecuId, fieldId, value } = periodic;
    const periodicData = new PeriodicDataChangedEvent(ecuId, fieldId, value);

    this.deviceService.updatePeriodicData(periodicData, periodicParameter);
  }

  private async runFirmwareUpgrade(data: UpgradeFirmwareCommand[]): Promise<void> {
    // Resettar formwaresubject när uppdateringen körs igen
    this.firmwareSubject = new ReplaySubject<UpgradeFirmwareCommand>();
    const address = this.deviceQuery.currentDevice.address;
    this.upgradeCommands = data.sort((a, b) => b['ecuId'] - a['ecuId']);
    this.firmwareSubject.subscribe((res) => {
      const sendFirmware = new WorkerMessage(WORKER_TOPIC.sendFirmareUpdate, res);
      this.workerService.doWork(sendFirmware);
      this.upgradeCommands.shift();
    });

    //TODO: Tömma firmwareSubject efter avslut eller vid misslyckande?
    if (this.appQuery.platform === 'android') {
      // await this.bleCapacitorService.requestConnectionPriority(address, 'high');
      // console.log("HÄR KÖR VI PRIO_CONNECTION");
    }

    this.firmwareSubject.next(this.upgradeCommands[0]);
  }

  // ALERTS
  public async showAllFinishedAlert(): Promise<void> {
    const header = await this.translateService.instant(`alerts.finished.header`);
    const message = await this.translateService.instant(`alerts.finished.message`);
    const buttonText = await this.translateService.instant(`auth.ok`);

    const body = `<div class="text-center">
      <p class="text-22pt text-roto-green py-2 font-semibold">${header}</p>
      <p class=" text-20pt text-roto-green">${message}</p>
    </div>`;

    const buttons = [{ text: buttonText, role: 'close', cssClass: 'no-button-borders' }];
    const alert = await this.alertController.create({
      message: body,
      keyboardClose: false,
      backdropDismiss: false,
      buttons,
    });
    alert.present();

    alert.onDidDismiss().then((res) => {
      // console.log('res: ', res);
      if (res.role === 'close') {
        alert.dismiss();
      }
    });
  }

  public async showFailedAlert(fw: UpgradeFwStatusEvent): Promise<void> {
    const node = fw['ecuId'];
    const header = await this.translateService.instant(`alerts.failed.header`);
    const message = await this.translateService.instant(`alerts.failed.message`, {
      node: node,
      errMessage: fw['message'],
      errStatus: fw['status'],
    });
    const close = await this.translateService.instant(`alerts.remote-support.close`);

    const body = `<div class="text-center">
      <p class="text-22pt text-roto-green py-2 font-semibold">${header}</p>
      <p class=" text-20pt text-roto-green">${message}</p>
    </div>`;

    const buttons = [{ text: close, role: 'close', cssClass: 'no-button-borders' }];
    const alert = await this.alertController.create({
      message: body,
      keyboardClose: false,
      backdropDismiss: false,
      buttons,
      cssClass: 'full-height',
    });
    alert.present();

    alert.onDidDismiss().then((res) => {
      // console.log('res: ', res);
      if (res.role === 'close') {
        alert.dismiss();
      }
    });
  }

  private async tempAlert(fw: UpgradeFwStatusEvent): Promise<void> {
    const node = fw['ecuId'];
    const header = 'FAILED, UNKNOWN REASON';
    const message = JSON.stringify(fw) + JSON.stringify(fw) + JSON.stringify(fw);
    const close = await this.translateService.instant(`alerts.remote-support.close`);

    const body = `<div class="text-center">
      <p class="text-22pt text-roto-green py-2 font-semibold">${header}</p>
      <p class=" text-20pt text-roto-green">${message}</p>
    </div>`;

    const buttons = [{ text: close, role: 'close', cssClass: 'no-button-borders' }];
    const alert = await this.alertController.create({
      message: body,
      keyboardClose: false,
      backdropDismiss: false,
      buttons,
      cssClass: 'full-height',
    });
    alert.present();

    alert.onDidDismiss().then((res) => {
      //  console.log('res: ', res);
      if (res.role === 'close') {
        alert.dismiss();
      }
    });
  }
}
