import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { interval, Observable, Subscription } from 'rxjs';
import {
  BluetoothLE,
  BondedStatus,
  CharacteristicParams,
  Characteristics,
  CurrConnectionStatus,
  DescriptorParams,
  Descriptors,
  Device,
  DeviceInfo,
  MTU,
  OperationResult,
  ScanParams,
  ScanStatus,
  Service,
  Services,
} from '@ionic-native/bluetooth-le/ngx';
import { BLE, BLEScanOptions } from '@awesome-cordova-plugins/ble/ngx';
import { AlertController, Platform } from '@ionic/angular';

import { DeviceService } from '@state/device/device.service';
import { DeviceQuery } from '@state/device/device.query';
import { RemoteUserService } from '@shared/state/remote-user/remote-user.service';
import { ICurrentDevice, IPreviousDevice } from '@interfaces/device.interface';
import { WorkerMessage } from '@shared/worker/models/worker-message.model';
import { ToastService } from '../toast.service';
import { AlarmService } from '@shared/state/alarm/alarm.service';
import { takeWhile } from 'rxjs/operators';
import { AlarmQuery } from '@shared/state/alarm/alarm.query';
import { TranslateService } from '@ngx-translate/core';
import { IAlarm } from '@shared/interfaces/alarm.interface';
import { BleWebService } from './ble-web.service';
// import { BluetoothCore } from '@manekinekko/angular-web-bluetooth';
import { AnyRecord } from 'dns';
import { FirmwareQuery } from '@shared/state/firmware/firmware.query';
import { WorkerService } from '@shared/worker/worker.service';
import { WORKER_TOPIC } from '@shared/worker/models/worker-topic.constants';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { UserQuery } from '@shared/state/user/user.query';

import { Capacitor } from '@capacitor/core';

declare global {
  interface Window {
    NativeBridge: {
      startBLEForegroundService: () => void;
      stopBLEForegroundService: () => void;
      connectToDevice: (address: string) => void;
      disconnectFromDevice: () => void;
    };
  }
}

@Injectable({
  providedIn: 'root',
})
export class BleCapacitorService {
  private triedToReconnect = false;
  private runTick = true;
  private sendQueue: number[][] = [];
  private timeStampDebug: number;
  private alarmSubscription: Subscription;
  private alarmClearSubscription: Subscription;
  private connectSubscription: Subscription;
  private alarmLogSubscription: Subscription;
  private alarmLogGetSubscription: Subscription;

  private bleQueue = [];

  constructor(
    private ble: BluetoothLE,
    private deviceService: DeviceService,
    private router: Router,
    private ngZone: NgZone,
    private deviceQuery: DeviceQuery,
    private platform: Platform,
    private toastService: ToastService,
    private alarmService: AlarmService,
    private alarmQuery: AlarmQuery,
    private translateService: TranslateService,
    private alertController: AlertController,
    private bleWebService: BleWebService,
    private remoteUserService: RemoteUserService,
    // private bleWeb: BluetoothCore,
    private newBle: BLE,
    private firmwareQuery: FirmwareQuery,
    private workerService: WorkerService,
    private userQuery: UserQuery
  ) {
    this.timeStampDebug = 0;
    // this.RunSendThread();
  }

  public async isEnabled(): Promise<{ isEnabled: boolean }> {
    return await this.ble.isEnabled();
  }

  public async hasPermission(): Promise<{ hasPermission: boolean }> {
    return await this.ble.hasPermission();
  }

  public async requestPermission(): Promise<{ requestPermission: boolean }> {
    return await this.ble.requestPermission();
  }

  public async isLocationEnabled(): Promise<{ isLocationEnabled: boolean }> {
    return await this.ble.isLocationEnabled();
  }

  public async requestLocation(): Promise<{ requestLocation: boolean }> {
    return await this.ble.requestLocation();
  }

  private prepareDisconnect(bleChip: string) {
    //this.deviceService.updateLastConnected();
    this.remoteUserService.updateLatestConnectionInfo();
    this.alarmSubscription?.unsubscribe();
    this.alarmClearSubscription?.unsubscribe();
    this.alarmLogSubscription?.unsubscribe();
    this.alarmLogGetSubscription?.unsubscribe();
    this.connectSubscription?.unsubscribe();
    this.unsubscribe(bleChip, this.deviceQuery.characteristicsUuid, this.deviceQuery.serviceUuid);
    this.remoteUserService.resetStoreTotally();
    //this.toastService.warningToast('toasts.device disconnected', true);
    this.deviceService.resetDeviceData();
    this.alarmService.resetAlarmData();

    const clearWorkerState = new WorkerMessage(WORKER_TOPIC.clearWorkerState, '');

    this.workerService.doWork(clearWorkerState);

    //console.log('close here');
    this.deviceService.stopConnection();
    this.nativeDisconnectToDevice();
    this.stopForegroundService();
    this.close();
  }

  public async disconnect(currentDevice: ICurrentDevice): Promise<any> {
    this.prepareDisconnect(currentDevice.currentConnectedBluetoothChip);
    currentDevice.hasBLEConnection = false;
    return await this.newBle.disconnect(currentDevice.currentConnectedBluetoothChip);
  }

  public read(address: string, characteristic: string, service: string) {
    return this.newBle.read(address, service, characteristic);
  }

  public subscribe(address: string, characteristic: string, service: string) {
    const params: DescriptorParams = {
      address,
      service,
      characteristic,
    };
    return this.newBle.startNotification(address, service, characteristic);
  }

  public unsubscribe(address: string, characteristic: string, service: string) {
    const params: DescriptorParams = {
      address,
      characteristic,
      service,
    };
    return this.newBle.stopNotification(address, service, characteristic);
  }

  public initialize() {
    return this.newBle.isEnabled();
  }

  public initializeIOS() {
    return this.ble.initialize();
  }

  public startScan(): Observable<any> {
    setTimeout(() => {
      this.stopScan();
    }, 10000);

    //console.log('SCANNED STARTED');
    const scanParams: ScanParams = {
      allowDuplicates: true,
      scanMode: this.ble.SCAN_MODE_LOW_LATENCY,
      matchMode: this.ble.MATCH_MODE_AGGRESSIVE,
      matchNum: this.ble.MATCH_NUM_MAX_ADVERTISEMENT,
      callbackType: this.ble.CALLBACK_TYPE_ALL_MATCHES,
    };
    this.deviceService.updateIsScanning(true);
    // return this.ble.startScan(scanParams);
    // const options: = {
    //   scanMode: "lowPower",
    //   matchMode: this.ble.MATCH_MODE_AGGRESSIVE,
    //   numOfMatches: this.ble.MATCH_NUM_MAX_ADVERTISEMENT,
    //   callbackType: this.ble.CALLBACK_TYPE_ALL_MATCHES,
    // }
    return this.newBle.startScan([]);
  }

  public async isScanning(): Promise<{ isScanning: boolean }> {
    return await this.ble.isScanning();
  }

  public async stopScan(): Promise<any> {
    this.deviceService.updateIsScanningForPreviousDevices(false);
    this.deviceService.updateIsScanning(false);
    return this.newBle.stopScan().then(() => console.log('Stopped scan'));
    // const isScanning = await this.isScanning();
    // if (isScanning.isScanning) {
    //   this.deviceService.updateIsScanning(false);
    //   return await this.ble.stopScan();
    // }
  }

  public async retrieveConnected(services: string[]): Promise<DeviceInfo[]> {
    const params = {
      services,
    };
    const data = await this.ble.retrieveConnected(params);
    return JSON.parse(JSON.stringify(data)) as DeviceInfo[];
  }

  public scan(services: string[]): Observable<any> {
    const params = {
      services,
    };
    return this.newBle.scan(services, 15);
  }

  public connect(address: string): Observable<any> {
    const params = {
      address,
      autoConnect: true,
    };

    return this.newBle.connect(address);
  }

  public async mtu(address: string): Promise<MTU> {
    return await this.newBle.requestMtu(address, 512);
  }

  public async close() {
    this.deviceService.removeCurrentDevice();
    // return await this.ble.close(params);
  }

  public async isConnected(address: string): Promise<any> {
    const params = { address };
    return await this.newBle.isConnected(address);
  }

  public async discover(address: string): Promise<Device> {
    const params = { address, clearCache: true };
    return await this.ble.discover(params);
  }

  public runMtu = true;

  private async writeWeb(bytes: Uint8Array): Promise<void> {
    if (this.bleQueue.length === 0) {
      //console.log("ADDING TO QUEUE");
      this.bleQueue.push(bytes);
      this.sendAll();
    } else if (this.bleQueue.length === 1) {
      //console.log("ADDING TO QUEUE");
      this.bleQueue.push(bytes);
    } else {
      if (this.bleQueue[this.bleQueue.length - 1].length + bytes.length <= 244) {
        //console.log("MODIFYING QUEUE");
        this.bleQueue[this.bleQueue.length - 1] = new Uint8Array([
          ...this.bleQueue[this.bleQueue.length - 1],
          ...bytes,
        ]);
      } else {
        // console.log("ADDING TO QUEUE");
        this.bleQueue.push(bytes);
      }
    }
  }

  private async sendAll() {
    while (this.bleQueue.length > 0 && this.bleWebService.characteristics) {
      //console.log(" STR WRITE STARTED: ");
      await this.bleWebService.characteristics[0]
        .writeValueWithoutResponse(this.bleQueue[0])
        .then(() => {
          //console.log(" STR WRITE DONE: "+this.bleQueue[0].length);
          // sent = true;
          this.bleQueue.shift();
        })
        .catch(async (err) => {
          console.log(err);
        });
    }
  }

  public async write(bytes: Uint8Array) {
    var i,
      j,
      temporary,
      chunk = 244; //TODO is this size correct for all devices?
    let promise: Promise<OperationResult>;

    for (i = 0, j = bytes.length; i < j; i += chunk) {
      temporary = bytes.slice(i, i + chunk);
      if (this.platform.is('mobileweb') || this.platform.is('desktop')) {
        if (!this.deviceQuery.currentDevice?.currentConnectedBluetoothChip) {
          this.bleQueue = [];
        } else {
          this.writeWeb(temporary)
            .then(() => {
              console.log('DONE');
            })
            .catch((err) => {
              console.log(err, 'ERROR IN WRITE');
            });
        }
      } else {
        const address = this.deviceQuery.currentDevice.currentConnectedBluetoothChip;
        const service = this.deviceQuery.serviceUuid;
        const characteristic = this.deviceQuery.characteristicsUuid;
        if (address) {
          this.newBle.writeWithoutResponse(address, service, characteristic, temporary.buffer);
          //console.log('address', address);
        }
      }
    }
  }

  public async addBleDataToOutQueue(bytes: number[]) {
    if (this.platform.is('mobileweb') || this.platform.is('desktop')) {
      // this.sendQueue.push(bytes);
      this.write(new Uint8Array(bytes));
    } else {
      this.write(new Uint8Array(bytes));
    }

    // console.log("data written to queue", bytes);
  }
  private RunSendThread() {
    interval(1)
      .pipe(takeWhile(() => this.runTick))
      .subscribe(() => {
        let joinedData: number[][] = [];
        let timeMs = new Date().valueOf;
        // console.log("mata");

        while (this.sendQueue.length > 0) {
          /*Pull all available data from out queue*/
          let data = this.sendQueue[0];
          joinedData.push(data);
          this.sendQueue.shift();
        }
        if (joinedData.length > 0) {
          /*Concat outgoing data to one array and call write*/
          let joinedDataAsOneArray = [].concat.apply([], joinedData);
          this.write(new Uint8Array(joinedDataAsOneArray));
        }
      });
  }
  /**
   * iOS specific services
   */
  public async services(address: string, services: string[]): Promise<Services> {
    const params = { address, services };
    return await this.ble.services(params);
  }

  public async characteristics(
    address: string,
    service: string,
    characteristics: string[]
  ): Promise<{ characteristics: Characteristics }> {
    const params: CharacteristicParams = { address, service, characteristics };
    return await this.ble.characteristics(params);
  }

  public async descriptors(
    address: string,
    service: string,
    characteristic: string
  ): Promise<{ descriptors: Descriptors }> {
    const params: DescriptorParams = { address, service, characteristic };
    return await this.ble.descriptors(params);
  }

  /**
   * Android specific services
   */
  public enable(): Promise<any> {
    // this.ble.enable();
    return this.newBle.enable();
  }

  public showSettings() {
    this.newBle.showBluetoothSettings();
  }

  public disable(): void {
    this.ble.disable();
  }

  public bond(address: string): Observable<{ status: DeviceInfo }> {
    const params = { address };
    return this.ble.bond(params); // .pipe(take(2));
  }

  public async unbond(address: string): Promise<{ status: DeviceInfo }> {
    const params = { address };
    return await this.ble.unbond(params);
  }

  public async requestConnectionPriority(
    address: string,
    connectionPriority: 'low' | 'balanced' | 'high'
  ): Promise<DeviceInfo> {
    const params = { address, connectionPriority };
    return await this.ble.requestConnectionPriority(params);
  }

  public async isBonded(address: string): Promise<BondedStatus> {
    const params = { address };
    return await this.ble.isBonded(params);
  }

  /**
   * Functions
   */
  public encodedStringToBytes(val: string): Uint8Array {
    return this.ble.encodedStringToBytes(val);
  }

  public bytesToEncodedString(val: Uint8Array): string {
    return this.ble.bytesToEncodedString(val);
  }

  public stringToBytes(val: string): Uint8Array {
    return this.ble.stringToBytes(val);
  }

  public bytesToString(val: Uint8Array): string {
    return this.ble.bytesToString(val);
  }

  /**
   * Operator specific functions
   */

  public handleCharacteristicValueChanged(event) {
    console.log(event);
    // const value = event.target.value;
    // console.log('Received ' + value);
    // TODO: Parse Heart Rate Measurement value.
    // See https://github.com/WebBluetoothCG/demos/blob/gh-pages/heart-rate-sensor/heartRateSensor.js
  }

  public async connectToDevice(address: string, name: string): Promise<void> {
    this.triedToReconnect = false;
    this.deviceService.resetDeviceData();
    this.deviceService.addInitialName(name);
    this.alarmService.resetAlarmData();
    this.subscribeToAlarms();
    // console.log('address', address);
    this.isConnected(address)
      .then(async (isConnected) => {
        // console.log(isConnected.isConnected);
        if (isConnected.isConnected) {
          // const closeBle = await this.close(address);
          // if (closeBle.status === 'closed') {
          // console.log(isConnected.isConnected);
          setTimeout(() => {
            this.startSubscribeConnection(address, name);
          }, 2000);
          // }
        } else {
          this.startSubscribeConnection(address, name);
        }
      })
      .catch((err) => {
        // this.toastService.warningToast("ERROR IN connectToDevice")
        console.error('err: ', err);
        this.startSubscribeConnection(address, name);
      });
  }

  public subscribeToAlarms() {
    this.alarmSubscription?.unsubscribe();
    this.alarmSubscription = this.alarmQuery.alarms$.subscribe((alarms) => {
      if (alarms.length > 0) {
        alarms.forEach((alarm) => {
          if (alarm && alarm.isOldAlarm === false) {
            // if (!this.firmwareQuery.ongoingUpgrade && !this.firmwareQuery.keepGoing && (!this.userQuery.isOperator || ((alarm.errorCodeId - (alarm.ecuId * 1000)) < 800))) {
            //   this.showNewAlarm(alarm);
            // }

            this.alarmService.setAlarmToOld(alarm);
          }
        });
      }
    });

    // For alarm clear from servicetool
    this.alarmClearSubscription = this.alarmQuery.pendingAlarmClear$.subscribe((alarms) => {
      if (alarms.length > 0) {
        alarms.forEach((alarm) => {
          this.alarmService.removeAlarm(alarm, alarm.requestUid);
          this.alarmService.removeAlarmToBeCleared(alarm);
        });
      }
    });

    // For alarmlog clear from servicetool
    this.alarmLogSubscription = this.alarmQuery.pendingAlarmLogClear$.subscribe((req) => {
      if (req && req.length > 0) {
        req.forEach((val) => {
          this.alarmService.clearLog(val.clearType, val.requestUid);
          this.alarmService.removeClearAlarmLog();
        });
      }
    });

    this.alarmLogGetSubscription = this.alarmQuery.pendingGetLog$.subscribe((req) => {
      if (req && req > 0) {
        const setupMessage = new WorkerMessage(WORKER_TOPIC.getAlarmLog, {});
        this.workerService.doWork(setupMessage);
        this.alarmService.removeGetAlarmLog();
      }
    });
  }

  // IS NOT IN USE ANYMORE AFTER NEW DESIGN 2022 OCTOBER
  public async showNewAlarm(alarm: IAlarm): Promise<void> {
    // console.log(alarm);

    const dismiss = this.translateService.instant('alerts.dismiss');
    const later = this.translateService.instant('alerts.later');
    const alert = await this.alertController.create({
      header: 'New Alarm',
      subHeader: 'Name: ' + alarm.Name + ', ECU ID: ' + alarm.ecuId + ', Error Code ID: ' + alarm.errorCodeId,
      message: alarm.comment,
      cssClass: 'alert-wrapper',
      keyboardClose: false,
      backdropDismiss: false,
      buttons: alarm.alarmInfoList.AckAlarm
        ? [
            {
              text: dismiss,
              role: 'dismiss',
            },
            {
              text: later,
              role: 'later',
            },
          ]
        : [
            {
              text: 'OK',
              role: 'ok',
            },
          ],
    });
    alert.present();

    alert.onDidDismiss().then(async (res: { data: {}; role: 'dismiss' | 'later' | 'ok' }) => {
      //console.log('alert res: ', res);
      switch (res.role) {
        case 'dismiss':
          this.alarmService.removeAlarm(alarm);
          break;
        case 'later':
          this.toastService.dismisstoast();
          this.toastService.infoToast('toasts.alarm dismiss later', true);
          break;
        case 'ok':
          this.toastService.dismisstoast();
        default:
          break;
      }
    });
  }

  public startSubscribeConnection(address: string, name: string): void {
    this.deviceService.addInitialName(name);
    this.initialize()
      .then(async (init) => {
        // console.log(init);
      })
      .then(() => {
        this.connectSubscription = this.connect(address).subscribe(
          async (deviceInfo) => {
            if (deviceInfo) {
              // console.log('deviceInfo', deviceInfo);
              if (this.platform.is('android')) {
                await this.startForegroundService();
                const mtu = await this.mtu(address);
                await this.nativeConnectToDevice(address);
                if (mtu) {
                  // this.discover(address).then((discoverRes) => {
                  // if (discoverRes.services.length > 0) {
                  const newDevice: ICurrentDevice = {
                    name: deviceInfo.name,
                    status: 'waiting for serialnumber',
                    services: deviceInfo.characteristics,
                    bluetoothConnectors: [address],
                    currentConnectedBluetoothChip: address,
                    hasBLEConnection: true,
                  };
                  this.ngZone.run(() => {
                    this.triedToReconnect = false;
                    this.deviceService.addCurrentDevice(newDevice);
                    this.deviceService.stopConnection();
                    this.router.navigate(['/']);
                  });
                }
              } else {
                const newDevice: ICurrentDevice = {
                  name: deviceInfo.name,
                  status: 'waiting for serialnumber',
                  services: deviceInfo.characteristics,
                  bluetoothConnectors: [address],
                  currentConnectedBluetoothChip: address,
                  hasBLEConnection: true,
                };
                this.ngZone.run(() => {
                  this.triedToReconnect = false;
                  this.deviceService.addCurrentDevice(newDevice);
                  this.deviceService.stopConnection();
                  this.router.navigate(['/']);
                });
              }
            } else if (deviceInfo.status === 'disconnected') {
              // console.log(deviceInfo.status);
              this.deviceService.updateLastConnected();
              //this.toastService.warningToast('toasts.device disconnected', true);
              this.deviceService.resetDeviceData();
              this.alarmService.resetAlarmData();
              this.router.navigate(['/']);
              await this.stopForegroundService();
              await this.nativeDisconnectToDevice();
              //console.log('close here');
              if (!this.triedToReconnect) {
                this.deviceService.startReconnectTimer(7);
                var reConnectInterval = setInterval(() => {
                  this.deviceService.updateReconnectTimer();
                }, 1000);
                setTimeout(() => {
                  this.ble.close({ address }).then((res) => {
                    // console.log('close: ', close, 'res: ', res);
                    this.startSubscribeConnection(address, name);
                    this.triedToReconnect = true;
                    clearInterval(reConnectInterval);
                    this.deviceService.startReconnectTimer(0);
                  });
                }, 7000);
              }
            }
          },
          async (err) => {
            console.error(err, err.errorMessage);
            if (err.errorMessage === 'Peripheral Disconnected') {
              this.deviceService.updateLastConnected();
              //this.toastService.warningToast('toasts.device disconnected', true);
              this.deviceService.resetDeviceData();
              this.alarmService.resetAlarmData();
              this.router.navigate(['/']);
              if (!this.triedToReconnect) {
                this.deviceService.startReconnectTimer(7);
                var reConnectInterval = setInterval(() => {
                  this.deviceService.updateReconnectTimer();
                }, 1000);
                //console.log('close here');
                setTimeout(() => {
                  this.deviceService.startConnection();
                  this.startSubscribeConnection(address, name);
                  this.triedToReconnect = true;
                  clearInterval(reConnectInterval);
                  this.deviceService.startReconnectTimer(0);
                }, 7000);
              }
              await this.stopForegroundService();
              this.deviceService.stopConnection();
              await this.nativeDisconnectToDevice();
              this.close();
            } else if (err.errorMessage === 'Bluetooth Disabled') {
              // console.log('here');
              this.deviceService.updateLastConnected();
              this.toastService.warningToast('ble.disabled', true);
              this.deviceService.resetDeviceData();
              this.alarmService.resetAlarmData();
              this.deviceService.updateIsBluetoothIsActive(false);
              this.router.navigate(['/']);
              await this.stopForegroundService();
              this.deviceService.stopConnection();
              await this.nativeDisconnectToDevice();
              this.close();
            } else {
              // console.log('here');
              this.deviceService.updateLastConnected();
              this.toastService.warningToast('ble.general error', true);
              this.deviceService.resetDeviceData();
              this.alarmService.resetAlarmData();
              this.deviceService.updateIsBluetoothIsActive(false);
              this.router.navigate(['/']);
              await this.stopForegroundService();
              this.deviceService.stopConnection();
              await this.nativeDisconnectToDevice();
              this.close();
            }
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async startForegroundService() {
    if (Capacitor.getPlatform() === 'android') {
      // console.log('NativeBridge:', window.NativeBridge);
      if (window.NativeBridge && typeof window.NativeBridge.startBLEForegroundService === 'function') {
        window.NativeBridge.startBLEForegroundService();
        //console.log('Foreground service started.');
      } else {
        console.error('Native method startBLEForegroundService is not available.');
      }
    }
  }

  async stopForegroundService() {
    if (Capacitor.getPlatform() === 'android') {
      if (window.NativeBridge && typeof window.NativeBridge.stopBLEForegroundService === 'function') {
        window.NativeBridge.stopBLEForegroundService();
        // console.log('Foreground service stopped.');
      } else {
        console.error('Native method stopBLEForegroundService is not available.');
      }
    }
  }

  async nativeConnectToDevice(address: string) {
    if (Capacitor.getPlatform() === 'android') {
      if (window.NativeBridge) {
        window.NativeBridge.connectToDevice(address);
        // console.log('device Connected.');
      } else {
        console.error('Native method connectToDevice is not available.');
      }
    }
  }
  async nativeDisconnectToDevice() {
    if (Capacitor.getPlatform() === 'android') {
      if (window.NativeBridge) {
        window.NativeBridge.disconnectFromDevice();
        // console.log('device disconnected.');
      } else {
        console.error('Native method disconnectFromDevice is not available.');
      }
    }
  }
}
