// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card {
  --ion-card-background: transparent;
  box-shadow: none !important;
  padding: 0 1rem;
}`, "",{"version":3,"sources":["webpack://./src/app/layouts/auth/components/auth-card/auth-card.component.scss","webpack://./../../New%20folder/connect-4.1/src/app/layouts/auth/components/auth-card/auth-card.component.scss"],"names":[],"mappings":"AAAA;EACC,kCAAA;EACA,2BAAA;EACA,eAAA;ACCD","sourcesContent":["ion-card {\r\n\t--ion-card-background: transparent;\r\n\tbox-shadow: none !important;\r\n\tpadding: 0 1rem;\r\n}","ion-card {\n  --ion-card-background: transparent;\n  box-shadow: none !important;\n  padding: 0 1rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
