// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
  color: white;
}

ion-content#container {
  --background: url("/assets/images/GUI_Splash_app2022_light_logo.jpg") 0 0/100% 100% no-repeat;
}

ion-content::part(background) {
  background-size: cover;
}

.content-width {
  width: 26rem;
}

@media screen and (max-width: 1200px) {
  .content-width {
    width: 100%;
    max-width: 26rem !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/layouts/auth/pages/token-password/token-password.component.scss","webpack://./../../New%20folder/connect-4.1/src/app/layouts/auth/pages/token-password/token-password.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;ACCF;;ADEA;EAEE,6FAAA;ACAF;;ADGA;EACE,sBAAA;ACAF;;ADGA;EACE,YAAA;ACAF;;ADGA;EACE;IACE,WAAA;IACA,2BAAA;ECAF;AACF","sourcesContent":["h1 {\r\n  color: white;\r\n}\r\n\r\nion-content#container {\r\n  // --ion-background-color: linear-gradient(180deg, rgba(233,233,233,1) 55%, rgba(255,255,255,1) 100%);\r\n  --background: url('/assets/images/GUI_Splash_app2022_light_logo.jpg') 0 0/100% 100% no-repeat;\r\n}\r\n\r\nion-content::part(background) {\r\n  background-size: cover;\r\n}\r\n\r\n.content-width {\r\n  width: 26rem;\r\n}\r\n\r\n@media screen and (max-width: 1200px) {\r\n  .content-width {\r\n    width: 100%;\r\n    max-width: 26rem !important;\r\n  }\r\n}\r\n","h1 {\n  color: white;\n}\n\nion-content#container {\n  --background: url(\"/assets/images/GUI_Splash_app2022_light_logo.jpg\") 0 0/100% 100% no-repeat;\n}\n\nion-content::part(background) {\n  background-size: cover;\n}\n\n.content-width {\n  width: 26rem;\n}\n\n@media screen and (max-width: 1200px) {\n  .content-width {\n    width: 100%;\n    max-width: 26rem !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
