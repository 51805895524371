import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import {
  Firestore,
  collectionData,
  CollectionReference,
  where,
  getDocs,
  query as firestoreQuery,
} from '@angular/fire/firestore';
import { collection } from 'firebase/firestore';
import { Storage, ref, listAll, ListResult, StorageReference, getMetadata } from '@angular/fire/storage';

import { Subject, Subscription, timer, from, Observable, of } from 'rxjs';
import { map, mergeMap, takeUntil } from 'rxjs/operators';

//********************import services******************* */
import { FirestoreService } from '@shared/services/firestore.service'; //to access the firebase
import { ModalService } from '@shared/services/modal.service'; //to interact with the modal dialog like close the dialog
import { DeviceService } from '@shared/state/device/device.service';
import { ConnectApiService } from '@shared/services/connectapi.service';
import { StorageService } from '@shared/services/storage.service';
import { FirmwareService } from '@shared/state/firmware/firmware.service';

import { FirmwareQuery } from '@shared/state/firmware/firmware.query';
import { DeviceQuery } from '@shared/state/device/device.query';
import { DefinitionsQuery } from '@shared/state/definitions/definitions.query';
//**************constants**************** */
import { CONFIG } from '@shared/constants/config';
import { environment } from '@env/environment';
//*****************************Interfaces****************************** */
import {
  ICurrentDevice,
  IParameterData,
  IHardwareVersion,
  stringparametertemp,
} from '@shared/interfaces/device.interface';
import { IstringParameterForUpdate } from '@shared/interfaces/request.interface';
import { IFirmwareUpgrade } from '@interfaces/request.interface';

import { ParamResponseResult } from 'connectapi/src/CAN_Messages/CanMessages';
import { StringParameterEventType } from 'connectapi/src/ApiMessage/apiResponseTypes';
import { IFirmwareFile } from '@shared/interfaces/firmware.interface';

@Component({
  selector: 'app-spare-part-modal-service',

  templateUrl: './spare-part-modal-service.component.html',
  styleUrl: './spare-part-modal-service.component.scss',
})
export class SparePartModalServiceComponent implements OnInit, OnDestroy {
  @Input()
  public serialNumber: string = ''; //serial number of the unit entered by the user to identified the existing unit in database
  public serialNumberInput: string = '';
  public softwareVarient: number = -1; //variable to save the vale of softwareVarient of unit fond with entered serial number

  public step: number = 1; // for the navigation of dialog pages

  public parameter: IParameterData;
  public ecuName: string = '';

  public fetchFactorySettings: boolean;

  public items = []; //array for released version firmware
  public checkBoxes: any[] = [];
  public checkBoxes$: Observable<any>;
  public fileSelected: boolean = false;
  public isChecked: boolean = false;
  public isFirmwareDiff: boolean = false;
  public firmwareSent: boolean = false;
  public firmwareSparePart: IFirmwareFile;

  public softwareFile: StorageReference;
  private hardwareSubs: Subscription;
  public upgradeProgressSparePart$ = this.firmwareQuery.upgradeProgress$;
  public ongoingUpgradeSparePart$ = this.firmwareQuery.ongoingUpgrade$;
  private upgradeProgressSubscription: Subscription;

  private writingData = false;
  private writingData$ = new Subject<boolean>();
  private parametersAndStringParametersForBackupSubscription: Subscription;

  constructor(
    private angularFireStorage: Storage,
    private httpClient: HttpClient,
    private insomnia: Insomnia,
    //services//
    private modalService: ModalService,
    private firestoreService: FirestoreService,
    private deviceService: DeviceService,
    private storageService: StorageService,
    private angularFirestore: Firestore,
    private firmwareService: FirmwareService,
    private connectApiService: ConnectApiService,

    //Queries//
    private deviceQuery: DeviceQuery,
    private definitionQuery: DefinitionsQuery,
    private firmwareQuery: FirmwareQuery
  ) {}
  ngOnInit(): void {
    this.step = 1;
    this.ecuName = this.getEcuNameFromEcuId(this.parameter.ecuId);
  }

  private getEcuNameFromEcuId(ecuID: number): string {
    switch (ecuID) {
      case 1:
        return 'TCU';

      case 2:
        return 'CCU';

      case 3:
        return 'HCU';

      case 4:
        return 'JLE';

      case 5:
        return 'JRI';

      case 6:
        return 'HMI';

      case 7:
        return 'CAC1';

      case 8:
        return 'CAC2';

      default:
        return 'Unknown';
    }
  }

  public getSerialNumber(value: string) {
    this.serialNumber = value.trim(); // trim to remove leading/trailing whitespaces
  }

  public async searchOnSerialNumber(): Promise<void> {
    this.serialNumberInput = ((document.getElementById('serial-number-input') as any).value as string).trim();

    const result = await this.findDeviceOnSerial(this.serialNumberInput);
    if (result) {
      this.step = 4; //temporary skip firmware update
      const { softwareVariant, uid } = result;

      this.softwareVarient = softwareVariant;
      //this.uid= uid;

      const firmwareRef = ref(this.angularFireStorage, `systemversion/${this.softwareVarient}/0/release`); // storage reference for released version
      //creating the array of released firmware
      from(listAll(firmwareRef))
        .pipe(
          map((result: ListResult) => result.items), // Extract items array
          mergeMap((items: StorageReference[]) =>
            from(items).pipe(
              mergeMap((item: StorageReference) => from(getMetadata(item)).pipe(map((metaData) => metaData)))
            )
          )
        )
        .subscribe((metaData) => {
          var truncatedName = metaData?.name.split('_').slice(1).join('_').replace('.json', '');

          this.items.push({ ...metaData, truncatedName: truncatedName });
          // console.log(this.items);
        });
    }

    console.log(this.softwareVarient);

    /*
      var mess = this.translateService.instant('auth.could not find tiltrotator', {
        serialNumber: serialNumber,
      });
      var messNotConnected = this.translateService.instant('auth.no current user', {
        serialNumber: serialNumber,
      });
      var messWrongRole = this.translateService.instant('auth.wrong role', {
        serialNumber: serialNumber,
      });
  
      //console.log(serialNumber, 'serialNumber');
  
      var serialWithHW = await this.findDeviceOnSerial(serialNumber);
      // console.log(serialWithHW, 'serialWithHW');
  
      const db = getDatabase();
      const deviceRef = ref(db, '/devices/' + serialWithHW); // Create a reference to the device
  
      get(deviceRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const obj = snapshot.val();
            const myDate = new Date();
            if (obj !== null) {
              // console.log(obj);
              const userId = obj.lastConnectedUser as string;
              const userConnectedAt = obj.lastConnected as Date;
              //if (userConnectedAt > myDate) {
              const user = this.allUsers.find((user) => user.uid === userId);
              // console.log(user);
              if (user && (user.role === 'operator' || user.role === 'developer') && user.latestConnection) {
                this.initiatePairing(user);
                this.clearInput();
                /*} else {
                  this.toastService.warningToast(messWrongRole, true);
                  this.clearInput();
                }*/
    /* } else {
                //this.toastService.dangerToast(messNotConnected, true);
                this.createNoConnectedUser(messNotConnected);
                this.clearInput();
              }
            }
          } else {
            this.toastService.warningToast(mess, true);
            this.clearInput();
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          this.toastService.warningToast(mess, true);
          this.clearInput();
        });**/
  }

  private async findDeviceOnSerial(serialNumber: string) {
    if (serialNumber) {
      try {
        const q = firestoreQuery(
          collection(this.angularFirestore, CONFIG.firebaseCollection.devices),
          where('address', '==', serialNumber),
          where('ecuId', '==', this.parameter.ecuId)
        );

        const unitFound = await getDocs(q);
        console.log(unitFound);
        const mapped = unitFound.docs.map((y) => ({ ...(y.data() as any), uid: y.id }));
        console.log(mapped);
        const unitData = mapped.find((device) => !device.isSparePart && !device.isInactive) as IHardwareVersion;
        console.log(unitData);
        if (!unitData) {
          this.step = -1;
          console.warn(`Device with serial number "${serialNumber}" not found or is inactive/spare.`);

          return null; // or throw an error if you prefer
        }
        return {
          softwareVariant: unitData.softwareVarient,
          uid: unitData.uid,
        };
      } catch (error) {
        this.step = -1;

        console.error('Error querying Firestore:', error);
      }
    }

    /* if (serialNumber) {
      var unitFound = await this.angularFirestore
        .collection(CONFIG.firebaseCollection.devices, ref => ref.where('address', '==', serialNumber))
        .get()
        .toPromise();

      var maped = unitFound.docs.map(y => ({ ...y.data() as any, uid: y.id }))

      var unitData = maped.find(device => !device.isSparePart) as IHardwareVersion;

      return unitData?.uid;
    }*/
  }

  public async downloadChosenFile() {
    this.checkBoxes = [];
    this.checkBoxes$ = of(this.checkBoxes);

    if (this.softwareFile) {
      this.fileSelected = true;
      const firmwareUpgrade: IFirmwareUpgrade = {
        version: '1.0.0',
        fullPath: this.softwareFile.fullPath,
        name: this.softwareFile.name,
      };

      try {
        const jsonData = await this.httpClient
          .get<IFirmwareFile>(environment.functions.storageDownload, {
            responseType: 'json',
            params: { path: firmwareUpgrade.fullPath },
          })
          .toPromise();

        const fileIsSaved = await this.storageService.fileWrite(jsonData, firmwareUpgrade.fullPath);
        if (fileIsSaved) {
          this.fileSelected = true;
          console.log('Your file has been downloaded', this.fileSelected);
        } else {
          this.fileSelected = false;
          console.log('Something went wrong while downloading');
        }

        this.firmwareSparePart = jsonData;
        this.ChangeValues(this.firmwareSparePart);
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    }
  }

  public ChangeValues(event: any) {
    this.hardwareSubs?.unsubscribe();
    var event = event;

    this.hardwareSubs = this.deviceQuery.hardwareVersions$.subscribe((hardwareVersions) => {
      console.log('event', event, hardwareVersions);
      this.generateCheckboxes(event, hardwareVersions);
    });
  }
  private generateCheckboxes(event: any, hardwareVersions: IHardwareVersion[]) {
    this.checkBoxes = [];
    this.checkBoxes$ = of(this.checkBoxes);

    let isItemChecked;
    let fromBuildNumber;

    var matched = event.targets?.find((x) => x.ecuId === this.parameter.ecuId);
    var hardwareMatch = hardwareVersions.find(
      (x) => x.ecuId === (matched?.ecuId ?? matched?.hexFileDefinitions[0].ecuId)
    );

    if (!hardwareMatch) {
      isItemChecked = false;
      fromBuildNumber = '-';
      this.isFirmwareDiff = true;
    } else {
      //isItemChecked = hardwareMatch?.buildNumber < (target.buildNumber ?? target.hexFileDefinitions[0].buildNumber);
      isItemChecked = false;
      fromBuildNumber = hardwareMatch?.swVersion + '_' + hardwareMatch?.buildNumber;
      //this.isChecked = true;
    }
    var toBuildNumber =
      matched?.firmwareVersion ??
      matched?.hexFileDefinitions[0].firmwareVersion + '_' + matched?.hexFileDefinitions[0].buildNumber;
    var disabled = hardwareMatch ? false : true;
    this.checkBoxes.push({
      disabled: disabled,
      value: matched?.ecuName,
      ecuId: matched?.ecuId ?? matched?.hexFileDefinitions[0].ecuId,
      fromBuildNumber: fromBuildNumber,
      toBuildNumber: toBuildNumber,
      isItemChecked: isItemChecked,
    });
    /*
    event.targets?.forEach((target) => {
      let isItemChecked;
      let fromBuildNumber;
      var hardwareMatch = hardwareVersions.find(
        (x) => x.ecuId === (target.ecuId ?? target.hexFileDefinitions[0].ecuId)
      );

      //console.log (target.hexFileDefinitions[0].ecuId)
      if (!hardwareMatch) {
        isItemChecked = false;
        fromBuildNumber = '-';
        this.isFirmwareDiff = true;
      } else {
        //isItemChecked = hardwareMatch?.buildNumber < (target.buildNumber ?? target.hexFileDefinitions[0].buildNumber);
        isItemChecked = false;
        fromBuildNumber = hardwareMatch?.swVersion + '_' + hardwareMatch?.buildNumber;
        //this.isChecked = true;
      }

      //console.log("this.isFirmwareDiff=", this.isFirmwareDiff, "this.isChecked=",this.isChecked)
      var toBuildNumber =
        target.firmwareVersion ??
        target.hexFileDefinitions[0].firmwareVersion + '_' + target.hexFileDefinitions[0].buildNumber;
      var disabled = hardwareMatch ? false : true;

      this.checkBoxes.push({
        disabled: disabled,
        value: target.ecuName,
        ecuId: target.ecuId ?? target.hexFileDefinitions[0].ecuId,
        fromBuildNumber: fromBuildNumber,
        toBuildNumber: toBuildNumber,
        isItemChecked: isItemChecked,
      });
    });*/

    this.checkBoxes$ = of(this.checkBoxes);
  }
  public testingChange(event: any) {
    this.isChecked = this.checkBoxes.some((i) => i.isItemChecked === true);
  }

  private getSerialnumberFromEcuId(ecuId: number): string {
    switch (ecuId) {
      case 1:
        return this.deviceQuery.specificParameterData(1074)?.value?.toString();

      case 2:
        return this.deviceQuery.specificParameterData(2137)?.value?.toString();
      default:
        return '';
    }
  }

  private getHardwareNumberFromEcuId(ecuId: number): string {
    switch (ecuId) {
      case 1:
        return this.deviceQuery.specificParameterData(1008)?.value?.toString();

      case 2:
        return this.deviceQuery.specificParameterData(2008)?.value?.toString();
      default:
        return '';
    }
  }

  //for previous settings
  public handleGetPrevious() {
    this.deviceService.setIsBackuping(true);
    this.fetchOldSettings();
    this.step = 5;
  }

  public async fetchOldSettings() {
    var currentSerialNumber = this.getSerialnumberFromEcuId(this.parameter.ecuId);
    var currentHardwareNumber = this.getHardwareNumberFromEcuId(this.parameter.ecuId);
    this.fetchFactorySettings = false;
    this.firestoreService
      .fetchBackupParametersForSerialNumber(
        this.serialNumber,
        this.parameter.ecuId,
        this.fetchFactorySettings,
        currentHardwareNumber
      )
      .then(async (res) => {
        this.firestoreService
          .logBackup(
            currentSerialNumber,
            this.parameter.ecuId === 6
              ? this.deviceQuery.parameterData.find((x) => x.parameterId === 6008)?.value?.toString()
              : this.serialNumber,
            this.getEcuNameFromEcuId(this.parameter.ecuId)
          )
          .then(() => {
            try {
              this.writeParameters(res.parameterData);
              this.startSubscribeForBackUpParameters(res, currentHardwareNumber);
            } catch (error) {
              console.error(error);
              // this.noUnitFound(this.serialNumber);
            }
          })
          .catch((error) => {
            console.error('COULD NOT LOG DATA', error);
            //this.noUnitFound(this.qrResult);
            this.serialNumber = '';
            this.step = 5;
          });
      })
      .catch((err) => {
        console.error('COULD NOT FETCH BACKUP PARAMETERS', err);
        // this.noUnitFound(this.qrResult);
        this.serialNumber = '';
        this.step = 5;
      });
  }

  public handleGetBackup() {
    this.step = 5;
    this.deviceService.setIsBackuping(true);
    this.fetchFactorySettings = true;
    var currentSerialNumber = this.getSerialnumberFromEcuId(this.parameter.ecuId);
    var currentHardwareNumber = this.getHardwareNumberFromEcuId(this.parameter.ecuId);

    this.firestoreService
      .fetchBackupParametersForSerialNumber(
        this.serialNumber,
        this.parameter.ecuId,
        this.fetchFactorySettings,
        currentHardwareNumber
      )
      .then(async (res) => {
        this.firestoreService
          .logBackup(
            currentSerialNumber,
            this.parameter.ecuId === 6
              ? this.deviceQuery.parameterData.find((x) => x.parameterId === 6008)?.value?.toString()
              : this.serialNumber,
            this.getEcuNameFromEcuId(this.parameter.ecuId)
          )
          .then(() => {
            try {
              this.writeParameters(res.parameterData);
              this.startSubscribeForBackUpParameters(res, currentHardwareNumber);
            } catch (error) {
              console.error(error);
              // this.noUnitFound(this.qrResult);
            }
          })
          .catch((error) => {
            console.error('COULD NOT LOG DATA', error);
            //this.noUnitFound(this.qrResult);
            this.serialNumber = '';
            this.step = 5;
          });
      })
      .catch((err) => {
        console.error('COULD NOT FETCH BACKUP PARAMETERS', err);
        //this.noUnitFound(this.qrResult);
        this.serialNumber = '';
      });
  }
  public updateFirmware() {
    this.step = 3;
    this.insomnia
      .keepAwake()
      .then((ok) => console.log('APP IS AWAKE', ok))
      .catch((err) => console.log('ERROR', err));
    this.firmwareService.setOngoing(true);
    this.connectApiService.updateFirmwareSparePart(this.firmwareSparePart, this.checkBoxes);

    this.firmwareSparePart = {} as IFirmwareFile;
    this.softwareFile = {} as StorageReference;
    this.fileSelected = false;
    this.isChecked = false;
    this.isFirmwareDiff = false;

    this.upgradeProgressSubscription = this.upgradeProgressSparePart$.subscribe((progressArray) => {
      if (progressArray?.length > 0) {
        const progress = progressArray[0]; // directly get the first (and only) item
        //console.log(progress.progress, progress.updgradeStatus);

        if (progress.progress === 1 && progress.updgradeStatus === 'Finished') {
          this.step = 6;

          setTimeout(() => {
            this.deviceService.setIsBackuping(false);
            this.firmwareService.setOngoing(false);
            this.upgradeProgressSubscription?.unsubscribe();
          }, 2000);
        }
      }
    });
  }

  private async writeParameters(res: any) {
    this.writingData = true;
    this.deviceService.resetBackupParameters();

    if (this.parameter.ecuId === 6) {
      var dataWithStringParameters = res?.data() as {
        parameterData: IParameterData[];
        stringParameterData: stringparametertemp[];
      };

      if (this.fetchFactorySettings) {
        // FETCH DEFAULT SETTINGS FOR THE PARAMETERS THAT WERENT UPLOADED AT THE FACTORY
        dataWithStringParameters = this.mergedataWithStringParametersFromDefFile(dataWithStringParameters);
      }

      for (var parameter of dataWithStringParameters?.parameterData) {
        if (parameter.parameterId < 1000) {
          parameter.parameterId = 6 * 1000 + parameter.parameterId;
        }

        if (parameter.parameterId === 6107) {
          parameter.value = 0;
        }

        if (!parameter?.parameter || parameter?.parameter?.accessType !== 'RO') {
          var param = {
            parameterId: parameter.parameterId,
            ecuId: parameter.ecuId,
            value: parameter.value,
          };
          this.deviceService.addParameterForBackup(param);
          this.connectApiService.writeParameterToDevice(parameter);
          await this.sleep(25);
        }
      }
      await this.sleep(2500);
      for (const parameter of dataWithStringParameters?.stringParameterData) {
        if (parameter.paramId < 1000) {
          parameter.paramId = 6 * 1000 + parameter.paramId;
        }
        var parameterToWrite: IstringParameterForUpdate = {
          parameterId: parameter.paramId,
          value: parameter.stringValue ?? '',
          ecuId: 6,
        };
        if (!parameter.parameter || (parameter?.parameter?.accessType !== 'RO' && parameter.paramId !== 6300)) {
          var stringParam = {
            parameterId: parameter.paramId,
            ecuId: 6,
            value: parameter.stringValue,
          };
          this.deviceService.addParameterForBackup(stringParam);
          this.connectApiService.writeStringParameterToDevice(parameterToWrite);
          await this.sleep(100);
        }
      }
      // DONE
      // setTimeout(() => {
      //   this.step = 4;
      //   this.firestoreService.forceFlag = true;
      // }, 2500);
    } else {
      var dataWithoutStringParameters = res?.data() as { parameterData: IParameterData[] };

      if (this.fetchFactorySettings) {
        // FETCH DEFAULT SETTINGS FOR THE PARAMETERS THAT WERENT UPLOADED AT THE FACTORY
        dataWithoutStringParameters = this.mergedataWithoutStringParametersFromDefFile(dataWithoutStringParameters);
      }

      // console.log(dataWithoutStringParameters);
      for (var parameter of dataWithoutStringParameters?.parameterData) {
        if (parameter.parameterId < 1000) {
          parameter.parameterId = parameter.ecuId * 1000 + parameter.parameterId;
        }

        if (parameter.parameterId === 1151 || parameter.parameterId === 2162) {
          parameter.value = 0;
        }

        if (parameter.parameterId === 1074 || parameter.parameterId === 2137) {
          parameter.value = parseInt(this.serialNumber);
        }

        if (!parameter.parameter || parameter?.parameter?.accessType !== 'RO') {
          var param = {
            parameterId: parameter.parameterId,
            ecuId: parameter.ecuId,
            value: parameter.value,
          };
          this.deviceService.addParameterForBackup(param);

          this.connectApiService.writeParameterToDevice(parameter);
          await this.sleep(25);
        }
      }
    }

    // this.startSubscribeForBackUpParameters(res, currentSerialNumber, currentHardwareNumber);
  }

  private startSubscribeForBackUpParameters(res, currentHardwareNumber) {
    console.log('STARTING SUBSCRIPTION');
    this.parametersAndStringParametersForBackupSubscription = this.deviceQuery.parametersAndStringParametersForBackup$
      .pipe(takeUntil(timer(this.parameter.ecuId === 6 ? 30000 : 15000)), takeUntil(this.writingData$))
      .subscribe({
        next: (params) => {
          console.log(`PARAM LEFT TO HANDLE ${params.length}`, params);

          if (params && params.length === 0) {
            // DONE
            this.writingData$.next(true);
          }
        },
        complete: async () => {
          //console.log('Data emission completed.');
          if (this.deviceQuery.parametersAndStringParametersForBackup.length === 0) {
            // console.log('All data written.');
            this.writingData = false;
            this.handleBackUpIsDone(res, currentHardwareNumber);
            setTimeout(() => {
              this.firestoreService.forceFlag = true;
            }, 1000);
            setTimeout(() => {
              this.step = 2;
            }, 3000);
          } else {
            this.startSubscribeForBackUpParameters(res, currentHardwareNumber);
            console.log('All data not written.', this.deviceQuery.parametersAndStringParametersForBackup);
            for (const parameter of this.deviceQuery.parametersAndStringParametersForBackup) {
              if (typeof parameter.value === 'string') {
                this.connectApiService.writeStringParameterToDevice({ ...parameter, value: parameter.value as string });
              } else {
                this.connectApiService.writeParameterToDevice({ ...parameter, value: parameter.value as number });
              }

              await this.sleep(100);
            }
          }
        },
      });
  }

  private handleBackUpIsDone(res, currentHardwareNumber) {
    var unitToSetAsActive = res.unitsToDeactivate.find((device) =>
      this.parameter.ecuId === 6
        ? device.uid === this.serialNumber
        : device.uid === `${this.serialNumber}-${currentHardwareNumber}`
    );
    this.firestoreService.setDeviceAsActive(unitToSetAsActive);
    setTimeout(() => {
      res.unitsToDeactivate
        .filter((device) =>
          this.parameter.ecuId === 6
            ? device.uid !== this.serialNumber
            : device.uid !== `${this.serialNumber}-${currentHardwareNumber}`
        )
        .forEach((unit) => {
          this.firestoreService.setDeviceAsInactive(unit);
        });

      this.firestoreService.updateFirestoreDevice();
    }, 2000);
  }
  private mergedataWithoutStringParametersFromDefFile(dataWithoutStringParameters: {
    parameterData: IParameterData[];
  }) {
    // FETCH DEFAULT SETTINGS FOR THE PARAMETERS THAT WERENT UPLOADED AT THE FACTORY

    dataWithoutStringParameters.parameterData = dataWithoutStringParameters.parameterData.map((param) => {
      var paramIdAsNumber = parseInt(param.parameterId.toString());
      var valueAsNumber = parseInt(param.value.toString());
      return {
        ...param,
        parameterId: paramIdAsNumber < 1000 ? this.parameter.ecuId * 1000 + paramIdAsNumber : paramIdAsNumber,
        value: valueAsNumber,
      };
    });

    var compHW = this.deviceQuery.hardwareVersions.find((x) => x.ecuId === this.parameter.ecuId);
    var compFile = this.definitionQuery.compatibleFile(compHW.ecuId, compHW.buildNumber, compHW.compVersion);

    var defaultParameters = compFile.parameterDefinition.parameterList.filter(
      (param) =>
        !dataWithoutStringParameters.parameterData.find((x) => x.parameterId === param.parameterId) &&
        param.defaultValue !== undefined &&
        param.defaultValue !== null
    );

    dataWithoutStringParameters.parameterData = [
      ...dataWithoutStringParameters.parameterData,
      ...defaultParameters.map((param) => {
        return {
          parameterId: param.parameterId < 1000 ? this.parameter.ecuId * 1000 + param.parameterId : param.parameterId,
          ecuId: this.parameter.ecuId,
          value: param.defaultValue,
          id: this.parameter.ecuId,
          responseType: 'OK',
          status: ParamResponseResult.OK,
          parameter: {
            ...param,
          },
        };
      }),
    ];

    //console.log('DATA WITHOUT STRINGPARAMS', dataWithoutStringParameters, 'DEFAULTPARAMETERS', defaultParameters);
    return dataWithoutStringParameters;
  }

  private mergedataWithStringParametersFromDefFile(dataWithStringParameters: {
    parameterData: IParameterData[];
    stringParameterData: stringparametertemp[];
  }) {
    // FETCH DEFAULT SETTINGS FOR THE PARAMETERS THAT WERENT UPLOADED AT THE FACTORY

    dataWithStringParameters.parameterData = dataWithStringParameters.parameterData.map((param) => {
      var paramIdAsNumber = parseInt(param.parameterId.toString());
      var valueAsNumber = parseInt(param.value.toString());
      return {
        ...param,
        parameterId: paramIdAsNumber < 1000 ? 6 * 1000 + paramIdAsNumber : paramIdAsNumber,
        value: valueAsNumber,
      };
    });

    dataWithStringParameters.stringParameterData = dataWithStringParameters.stringParameterData.map((param) => {
      var paramIdAsNumber = parseInt(param.paramId.toString());
      var valueAsString = param.stringValue.toString();
      return {
        ...param,
        paramId: paramIdAsNumber < 1000 ? 6 * 1000 + paramIdAsNumber : paramIdAsNumber,
        stringValue: valueAsString,
      };
    });

    var compHW = this.deviceQuery.hardwareVersions.find((x) => x.ecuId === this.parameter.ecuId);
    var compFile = this.definitionQuery.compatibleFile(compHW.ecuId, compHW.buildNumber, compHW.compVersion);

    var defaultStringParameters = compFile.parameterDefinition.parameterList.filter(
      (param) =>
        !dataWithStringParameters.stringParameterData.find((x) => x.paramId === param.parameterId) &&
        param.defaultValue !== undefined &&
        param.defaultValue !== null &&
        param.dataType === 'STR'
    );

    var defaultParameters = compFile.parameterDefinition.parameterList.filter(
      (param) =>
        !dataWithStringParameters.parameterData.find((x) => x.parameterId === param.parameterId) &&
        param.defaultValue !== undefined &&
        param.defaultValue !== null &&
        param.dataType !== 'STR'
    );

    dataWithStringParameters.parameterData = [
      ...dataWithStringParameters.parameterData,
      ...defaultParameters.map((param) => {
        return {
          parameterId: param.parameterId < 1000 ? 6 * 1000 + param.parameterId : param.parameterId,
          ecuId: 6,
          value: param.defaultValue,
          id: 6,
          responseType: 'OK',
          status: ParamResponseResult.OK,
          parameter: {
            ...param,
          },
        };
      }),
    ];

    dataWithStringParameters.stringParameterData = [
      ...dataWithStringParameters.stringParameterData,
      ...defaultStringParameters.map((param) => {
        return {
          parameter: {
            ...param,
          },
          stringValue: param.defaultValue.toString(),
          paramId: param.parameterId < 1000 ? 6 * 1000 + param.parameterId : param.parameterId,
          status: ParamResponseResult.OK,
          eventType: StringParameterEventType.READ,
        };
      }),
    ];

    /*console.log(
        'DATA WITH STRINGPARAMETERS',
        dataWithStringParameters,
        'DEFAULT STRING PARAMETERS',
        defaultStringParameters,
        'DEFAULT PARAMETERS',
        defaultParameters
      );*/
    return dataWithStringParameters;
  }

  private sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  public closeModal() {
    this.deviceService.setIsBackuping(false);

    this.parametersAndStringParametersForBackupSubscription?.unsubscribe();
    this.modalService.dismissModal('SparePartModalServiceComponent');
    this.modalService.dismissModal('InactiveUnitModalComponent');
    if (this.upgradeProgressSubscription) {
      this.upgradeProgressSubscription.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.deviceService.setIsBackuping(false);
    this.parametersAndStringParametersForBackupSubscription?.unsubscribe();
    this.modalService.dismissModal('SparePartModalServiceComponent');
    this.modalService.dismissModal('InactiveUnitModalComponent');
    if (this.upgradeProgressSubscription) {
      this.upgradeProgressSubscription.unsubscribe();
    }
  }
}
