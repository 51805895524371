import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { InputComponent } from './components/input/input.component';
import { I18nPipe } from './pipes/i18n.pipe';
import { ButtonComponent } from './components/button/button.component';
// import { BluetoothLE } from '@ionic-native/bluetooth-le/ngx';
import { ModalService } from './services/modal.service';
import { BLE } from '@awesome-cordova-plugins/ble/ngx';
import { EcuNamePipe } from './pipes/ecu-name.pipe';
import { UpgradeProgressPipe } from './pipes/upgrade-progress.pipe';

import { AuthCardComponent } from '@auth/components/auth-card/auth-card.component';
import { AuthButtonComponent } from '@auth/components/auth-button/auth-button.component';
import { AuthCheckboxComponent } from '@auth/components/auth-checkbox/auth-checkbox.component';
const AUTHCOMPONENTS = [AuthCardComponent, AuthButtonComponent, AuthCheckboxComponent];

const SHAREDMODULES = [CommonModule, IonicModule, TranslateModule, ReactiveFormsModule];

const SHAREDPIPES = [I18nPipe, EcuNamePipe, UpgradeProgressPipe];

@NgModule({
  declarations: [InputComponent, ButtonComponent, SHAREDPIPES, AUTHCOMPONENTS],
  imports: [SHAREDMODULES, RouterModule],
  exports: [SHAREDMODULES, InputComponent, ButtonComponent, SHAREDPIPES, AUTHCOMPONENTS],
  providers: [SHAREDPIPES, BLE, ModalService],
})
export class SharedModule {}
