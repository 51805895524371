// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-badge {
  font-weight: 600 !important;
  position: absolute;
  top: 3px;
  z-index: 2000;
  right: -31px;
  transform: rotate(45deg);
  width: 87px;
  text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss","webpack://./../../New%20folder/connect-4.1/src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACC,2BAAA;EACA,kBAAA;EACA,QAAA;EACA,aAAA;EACA,YAAA;EACA,wBAAA;EACA,WAAA;EACA,yBAAA;ACCD","sourcesContent":["ion-badge {\r\n\tfont-weight: 600!important;\r\n\tposition: absolute;\r\n\ttop: 3px;\r\n\tz-index: 2000;\r\n\tright: -31px;\r\n\ttransform: rotate(45deg);\r\n\twidth: 87px;\r\n\ttext-transform: uppercase;\r\n}","ion-badge {\n  font-weight: 600 !important;\n  position: absolute;\n  top: 3px;\n  z-index: 2000;\n  right: -31px;\n  transform: rotate(45deg);\n  width: 87px;\n  text-transform: uppercase;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
