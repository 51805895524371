import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
@Injectable({
  providedIn: 'root',
})
export class IonLoaderService {
  constructor(public loadingController: LoadingController) {}

  // Simple loader
  simpleLoader() {
    this.loadingController
      .create({
        message: 'Loading...',
        spinner: 'bubbles',
        cssClass: 'loaderClass',
      })
      .then((response) => {
        response.present();
      });
  }
  simpleConnector() {
    this.loadingController
      .create({
        message: 'Connecting...',
        spinner: 'bubbles',
        cssClass: 'loaderClass',
      })
      .then((response) => {
        response.present();
      });
  }
  // Dismiss loader
  dismissLoader() {
    this.loadingController
      .dismiss()
      .then((response) => {
        console.log('Loader closed!', response);
      })
      .catch((err) => {
        console.log('Error occurred : ', err);
      });
  }
  // Auto hide show loader
  autoLoader() {
    this.loadingController
      .create({
        message: 'Loading...',
        spinner: 'bubbles',
        duration: 6000,
        cssClass: 'loaderClass',
      })
      .then((response) => {
        response.present();
        response.onDidDismiss().then((response) => {
          console.log('Loader dismissed', response);
        });
      });
  }
  // Custom style + hide on tap loader
  customLoader() {
    this.loadingController
      .create({
        message: 'Loader with custom style',
        duration: 4000,
        cssClass: 'loaderClass',
        backdropDismiss: true,
      })
      .then((res) => {
        res.present();
      });
  }
}
