import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ForegroundService } from '@awesome-cordova-plugins/foreground-service/ngx';
import { RouteReuseStrategy } from '@angular/router';
import { provideHttpClient, HttpClient } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';

import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideDatabase, getDatabase } from '@angular/fire/database';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideStorage, getStorage, StorageModule } from '@angular/fire/storage';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { environment } from '@env/environment';

import { SharedModule } from '@shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { WebBluetoothModule } from '@manekinekko/angular-web-bluetooth';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { WorkerService } from '@shared/worker/worker.service';
import { ServiceWorkerModule } from '@angular/service-worker';
//import { AuthButtonComponent } from '@auth/components/auth-button/auth-button.component';
import { AuthCardComponent } from '@auth/components/auth-card/auth-card.component';
import { AuthButtonComponent } from '@auth/components/auth-button/auth-button.component';
import { AuthCheckboxComponent } from '@auth/components/auth-checkbox/auth-checkbox.component';
//import { TokenPasswordComponent } from './pages/token-password/token-password.component';

//const AUTHCOMPONENTS = [AuthCardComponent, AuthButtonComponent, AuthCheckboxComponent];

import { TokenPasswordComponent } from '@auth/pages/token-password/token-password.component';

export function createTranslateLoader(http: HttpClient) {
  // console.log(http);
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  // console.log(http);
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent, TokenPasswordComponent],
  bootstrap: [AppComponent],
  imports: [
    /* AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule.enablePersistence(),
        AngularFirestoreModule,
        AngularFireAuthModule,*/

    WebBluetoothModule.forRoot({ enableTracing: true }),
    BrowserModule,
    IonicModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({ animated: false, mode: 'md' }),
    StorageModule,

    TranslateModule.forRoot({
      defaultLanguage: 'sv',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    CommonModule,
    SharedModule,
    StoreModule.forRoot({}),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    WorkerService,
    StatusBar,
    SplashScreen,
    ForegroundService,

    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideDatabase(() => getDatabase()),
    provideFirestore(() => getFirestore()),
    provideHttpClient(),
    provideStorage(() => getStorage()),

    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },

    // FileTransfer,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ScreenOrientation,
  ],
})
export class AppModule {}
