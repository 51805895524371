// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item {
  border-top: 1px solid #ffd278;
  --ion-safe-area-left: 0;
  --inner-padding-end: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/layouts/main/pages/alarm-view/alarm-view.component.scss","webpack://./../../New%20folder/connect-4.1/src/app/layouts/main/pages/alarm-view/alarm-view.component.scss"],"names":[],"mappings":"AAAA;EACC,6BAAA;EACA,uBAAA;EACA,sBAAA;ACCD","sourcesContent":["ion-item {\r\n\tborder-top: 1px solid #ffd278;\r\n\t--ion-safe-area-left: 0;\r\n\t--inner-padding-end: 0;\r\n}","ion-item {\n  border-top: 1px solid #ffd278;\n  --ion-safe-area-left: 0;\n  --inner-padding-end: 0;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
