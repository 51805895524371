import { Component, Input, OnInit } from '@angular/core';
import { IHardwareVersion } from '@shared/interfaces/device.interface';
import { IparameterForUpdate } from '@shared/interfaces/request.interface';
import { ConnectApiService } from '@shared/services/connectapi.service';
import { ModalService } from '@shared/services/modal.service';
import { DeviceQuery } from '@shared/state/device/device.query';

@Component({
  selector: 'app-inactive-unit-modal',
  templateUrl: './inactive-unit-modal.component.html',
  styleUrls: ['./inactive-unit-modal.component.scss'],
})
export class InactiveUnitModalComponent implements OnInit {
  @Input()
  public hardwareVersion: IHardwareVersion;

  public serialNumber: string;

  public hide: boolean = false;

  constructor(
    private modalService: ModalService,
    private deviceQuery: DeviceQuery,
    private connectApiService: ConnectApiService
  ) {}

  ngOnInit(): void {
    this.serialNumber = this.getSerialNumber(this.hardwareVersion);
  }

  public closeModal() {
    this.modalService.dismissModal('InactiveUnitModalComponent');
  }

  private getSerialNumber(hardwareVersion: IHardwareVersion): string {
    switch (hardwareVersion.ecuId) {
      case 1:
        return this.deviceQuery.specificParameterData(1074)?.value?.toString();
      case 2:
        return this.deviceQuery.specificParameterData(2137)?.value?.toString();
      case 6:
        return this.deviceQuery.specificParameterData(6008)?.value?.toString();
      default:
        return '';
    }
  }

  public resetUnit() {
    var parameterId = this.getSparepartParameterId(this.hardwareVersion.ecuId);

    if (parameterId) {
      var parameterForUpdate: IparameterForUpdate = {
        parameterId: parameterId,
        ecuId: this.hardwareVersion.ecuId,
        value: 1,
      };

      this.connectApiService.writeParameterToDevice(parameterForUpdate);
      this.hide = true;
    }
  }

  private getSparepartParameterId(ecuId: number): number {
    switch (ecuId) {
      case 1:
        return 1151;
      case 2:
        return 2162;
      case 6:
        return 6107;
      default:
        return 0;
    }
  }
}
