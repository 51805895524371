// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-checkbox {
  --border-radius: 0.2em;
  --background-checked: white;
  --border-color-checked: transparent;
  --checkmark-color: black;
  --size: 1.3em;
  vertical-align: middle;
}

ion-item {
  --background: transparent;
  --color: white;
  width: 100%;
  margin: auto;
}

ion-text {
  color: var(--ion-card-color, var(--ion-item-color, var(--ion-color-step-600, #666666)));
}`, "",{"version":3,"sources":["webpack://./src/app/layouts/auth/components/auth-checkbox/auth-checkbox.component.scss","webpack://./../../New%20folder/connect-4.1/src/app/layouts/auth/components/auth-checkbox/auth-checkbox.component.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,2BAAA;EACA,mCAAA;EACA,wBAAA;EACA,aAAA;EACA,sBAAA;ACCJ;;ADEA;EACI,yBAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;ACCJ;;ADEA;EACI,uFAAA;ACCJ","sourcesContent":["ion-checkbox {\r\n    --border-radius: 0.2em;\r\n    --background-checked: white;\r\n    --border-color-checked: transparent;\r\n    --checkmark-color: black;\r\n    --size: 1.3em;\r\n    vertical-align: middle;\r\n}\r\n\r\nion-item {\r\n    --background: transparent;\r\n    --color: white;\r\n    width: 100%;\r\n    margin: auto;\r\n}\r\n\r\nion-text {\r\n    color: var(--ion-card-color, var(--ion-item-color, var(--ion-color-step-600, #666666)));\r\n}","ion-checkbox {\n  --border-radius: 0.2em;\n  --background-checked: white;\n  --border-color-checked: transparent;\n  --checkmark-color: black;\n  --size: 1.3em;\n  vertical-align: middle;\n}\n\nion-item {\n  --background: transparent;\n  --color: white;\n  width: 100%;\n  margin: auto;\n}\n\nion-text {\n  color: var(--ion-card-color, var(--ion-item-color, var(--ion-color-step-600, #666666)));\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
