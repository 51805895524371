// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-footer {
  background: var(--ion-color-primary);
}

ion-spinner {
  height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/layouts/main/pages/ble-devices/ble-devices.component.scss","webpack://./../../New%20folder/connect-4.1/src/app/layouts/main/pages/ble-devices/ble-devices.component.scss"],"names":[],"mappings":"AAAA;EACC,oCAAA;ACCD;;ADEA;EACC,YAAA;ACCD","sourcesContent":["ion-footer {\r\n\tbackground: var(--ion-color-primary);\r\n}\r\n\r\nion-spinner {\r\n\theight: 20px;\r\n}","ion-footer {\n  background: var(--ion-color-primary);\n}\n\nion-spinner {\n  height: 20px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
