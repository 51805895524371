import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

//import * as firebase from 'firebase/app';
//import 'firebase/auth';
import {
  Auth,
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  confirmPasswordReset,
  UserCredential,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from '@angular/fire/auth';

//import { AngularFireAuth } from '@angular/fire/auth';
import { resetStores } from '@datorama/akita';

import { environment } from '@env/environment';

import { ICurrentUser, IUser } from '@interfaces/user.interface';
import { ISignUp } from '@interfaces/sign-up.interface';

import { UserService } from '@state/user/user.service';
import { UserStore } from '@state/user/user.store';
// import { WorkerService } from '@shared/worker/worker.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public user: IUser;

  constructor(
    private auth: Auth,
    private userService: UserService,
    // private WorkerService: WorkerService,
    private userStore: UserStore,
    private router: Router,
    private ngZone: NgZone,
    private httpClient: HttpClient,
    private translateService: TranslateService
  ) {}

  public onAuthStateChanged(): void {
    onAuthStateChanged(this.auth, (user) => {
      if (user) {
        this.ngZone.run(() => {
          this.userStore.setLoading(true);
          this.userService.setUserState(user.uid);
          this.userService.rtdb_and_local_fs_presence();
        });
      } else {
        resetStores();
        // this.router.navigate(['/auth']);
      }
    });
  }

  public async sendVerificationEmail() {
    const currentUser = this.auth.currentUser;

    if (currentUser) {
      await sendEmailVerification(currentUser);
    } else {
      throw new Error('No user is currently signed in.');
    }
    // return firebase.auth().currentUser.sendEmailVerification();
  }

  public async signInWithEmailAndPassword(email: string, password: string): Promise<UserCredential> {
    return await signInWithEmailAndPassword(this.auth, email, password);

    //return await this.angularFireAuth.signInWithEmailAndPassword(email, password);
  }

  public async createUserWithEmailAndPassword(data: ISignUp): Promise<any> {
    return await this.httpClient.post(environment.functions.signup, data).toPromise();
  }

  public async deleteUser(uid: string, userIsDenied?: boolean, mailData?: any): Promise<any> {
    return await this.httpClient.post(environment.functions.deleteUser, { uid, userIsDenied, mailData }).toPromise();
  }

  public async sendUserGotAcceptedEmail(mailData: any) {
    return await this.httpClient.post(environment.functions.userGotAccepted, mailData).toPromise();
  }

  public async sendPasswordResetEmail(email: string): Promise<void> {
    const actionCodeSettings = {
      url: 'https://' + environment.firebase.authDomain + '/auth/signin',
    };
    this.auth.languageCode = this.translateService.currentLang;
    return await sendPasswordResetEmail(this.auth, email, actionCodeSettings);
    /*
    const actionCodeSettings: firebase.auth.ActionCodeSettings = {
      url: 'https://' + environment.firebase.authDomain + '/auth/signin',
    };
    firebase.auth().languageCode = this.translateService.currentLang;
    return await this.angularFireAuth.sendPasswordResetEmail(email, actionCodeSettings);*/
  }

  public async confirmPasswordReset(code: string, newPassword: string): Promise<void> {
    return await confirmPasswordReset(this.auth, code, newPassword);
    //return await this.angularFireAuth.confirmPasswordReset(code, newPassword);
  }

  public async signOut(): Promise<void> {
    this.userService.goOffline();
    this.userService.unsubscribe();
    localStorage.clear();
    sessionStorage.clear();
    await this.auth.signOut();

    // Temp redirect fix
    setTimeout(() => {
      this.router.navigate(['auth/signin']);
    }, 50);
  }
}
