import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';
import { IParameterData, stringparametertemp } from '@shared/interfaces/device.interface';
import { Subject, Subscription, timer } from 'rxjs';

import { FirestoreService } from '@shared/services/firestore.service';
import { ConnectApiService } from '@shared/services/connectapi.service';
import { IstringParameterForUpdate } from '@shared/interfaces/request.interface';
import { ModalService } from '@shared/services/modal.service';
import { DeviceQuery } from '@shared/state/device/device.query';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from '@shared/state/device/device.service';
import { takeUntil } from 'rxjs/operators';
import { DefinitionsQuery } from '@shared/state/definitions/definitions.query';
import { ParamResponseResult } from 'connectapi/src/CAN_Messages/CanMessages';
import { StringParameterEventType } from 'connectapi/src/ApiMessage/apiResponseTypes';

@Component({
  selector: 'app-spare-part-modal',
  templateUrl: './spare-part-modal.component.html',
  styleUrls: ['./spare-part-modal.component.scss'],
})
export class SparePartModalComponent implements OnInit, OnDestroy {
  @Input()
  public parameter: IParameterData;

  public ecuName: string = '';

  public qrResult: string = '';

  public qrResultConfirmation: string = '';

  private backButtonSubscription: Subscription;

  private harwareSubscription: Subscription;

  private parametersAndStringParametersForBackupSubscription: Subscription;

  public readingQrScan: boolean = false;

  public step: number = 1;

  private prevStep: number;

  public isMobile = false;

  public fetchFactorySettings: boolean;

  private handleStopScanQRBound;

  private writingData = false;

  private writingData$ = new Subject<boolean>();

  private paramsToExclude = [1074, 2137, 6008];

  public manuallyEnterSerialNumber: boolean = false;

  private fetchHasStarted = false;

  constructor(
    private modalService: ModalService,
    private platform: Platform,
    private firestoreService: FirestoreService,
    private connectApiService: ConnectApiService,
    private deviceQuery: DeviceQuery,
    private alertController: AlertController,
    private translateService: TranslateService,
    private deviceService: DeviceService,
    private definitionQuery: DefinitionsQuery
  ) {}

  ngOnInit(): void {
    this.harwareSubscription = this.deviceQuery.hardwareVersions$.subscribe((hardwares) => {
      if (!hardwares.find((x) => x.ecuId === this.parameter.ecuId)) {
        if (!this.prevStep) {
          this.prevStep = this.step;
        }

        this.lostConnectionToSparePart();
      } else if (this.prevStep) {
        this.step = this.prevStep;
        this.prevStep = undefined;
      }
    });

    this.ecuName = this.getEcuNameFromEcuId(this.parameter.ecuId);

    if (this.platform.is('capacitor')) {
      this.isMobile = true;
      // BarcodeScanner.prepare();
    } else {
      this.isMobile = false;
    }
  }

  public handleGetPrevious() {
    this.step = 2;
  }

  public handleGetBackup() {
    this.fetchFactorySettings = true;
    this.step = 2;
  }

  private lostConnectionToSparePart() {
    this.step = 5;
  }

  private getSerialnumberFromEcuId(ecuId: number): string {
    switch (ecuId) {
      case 1:
        return this.deviceQuery.specificParameterData(1074)?.value?.toString();

      case 2:
        return this.deviceQuery.specificParameterData(2137)?.value?.toString();
      default:
        return '';
    }
  }

  private getHardwareNumberFromEcuId(ecuId: number): string {
    switch (ecuId) {
      case 1:
        return this.deviceQuery.specificParameterData(1008)?.value?.toString();

      case 2:
        return this.deviceQuery.specificParameterData(2008)?.value?.toString();
      default:
        return '';
    }
  }

  public async fetchOldSettings() {
    if (!this.fetchHasStarted) {
      if (!this.manuallyEnterSerialNumber || this.qrResult === this.qrResultConfirmation) {
        this.fetchHasStarted = true;
        this.manuallyEnterSerialNumber = false;
        if (this.platform.is('capacitor')) {
          this.stopScanQR();
        }

        var currentSerialNumber = this.getSerialnumberFromEcuId(this.parameter.ecuId);
        var currentHardwareNumber = this.getHardwareNumberFromEcuId(this.parameter.ecuId);

        this.step = 3;
        this.firestoreService
          .fetchBackupParametersForSerialNumber(
            this.qrResult,
            this.parameter.ecuId,
            this.fetchFactorySettings,
            currentHardwareNumber
          )
          .then(async (res) => {
            this.firestoreService
              .logBackup(
                currentSerialNumber,
                this.parameter.ecuId === 6
                  ? this.deviceQuery.parameterData.find((x) => x.parameterId === 6008)?.value?.toString()
                  : this.qrResult,
                this.getEcuNameFromEcuId(this.parameter.ecuId)
              )
              .then(() => {
                try {
                  this.writeParameters(res.parameterData);
                  this.startSubscribeForBackUpParameters(res, currentHardwareNumber);
                } catch (error) {
                  console.error(error);
                  this.noUnitFound(this.qrResult);
                }
              })
              .catch((error) => {
                console.error('COULD NOT LOG DATA', error);
                this.noUnitFound(this.qrResult);
                this.qrResult = '';
                this.step = 2;
              });
          })
          .catch((err) => {
            console.error('COULD NOT FETCH BACKUP PARAMETERS', err);
            this.noUnitFound(this.qrResult);
            this.qrResult = '';
            this.step = 2;
          });
      } else {
        this.mismatchingSerialNumber();
      }
    }
  }

  private mergedataWithStringParametersFromDefFile(dataWithStringParameters: {
    parameterData: IParameterData[];
    stringParameterData: stringparametertemp[];
  }) {
    // FETCH DEFAULT SETTINGS FOR THE PARAMETERS THAT WERENT UPLOADED AT THE FACTORY

    dataWithStringParameters.parameterData = dataWithStringParameters.parameterData.map((param) => {
      var paramIdAsNumber = parseInt(param.parameterId.toString());
      var valueAsNumber = parseInt(param.value.toString());
      return {
        ...param,
        parameterId: paramIdAsNumber < 1000 ? 6 * 1000 + paramIdAsNumber : paramIdAsNumber,
        value: valueAsNumber,
      };
    });

    dataWithStringParameters.stringParameterData = dataWithStringParameters.stringParameterData.map((param) => {
      var paramIdAsNumber = parseInt(param.paramId.toString());
      var valueAsString = param.stringValue.toString();
      return {
        ...param,
        paramId: paramIdAsNumber < 1000 ? 6 * 1000 + paramIdAsNumber : paramIdAsNumber,
        stringValue: valueAsString,
      };
    });

    var compHW = this.deviceQuery.hardwareVersions.find((x) => x.ecuId === this.parameter.ecuId);
    var compFile = this.definitionQuery.compatibleFile(compHW.ecuId, compHW.buildNumber, compHW.compVersion);

    var defaultStringParameters = compFile.parameterDefinition.parameterList.filter(
      (param) =>
        !dataWithStringParameters.stringParameterData.find((x) => x.paramId === param.parameterId) &&
        param.defaultValue !== undefined &&
        param.defaultValue !== null &&
        param.dataType === 'STR'
    );

    var defaultParameters = compFile.parameterDefinition.parameterList.filter(
      (param) =>
        !dataWithStringParameters.parameterData.find((x) => x.parameterId === param.parameterId) &&
        param.defaultValue !== undefined &&
        param.defaultValue !== null &&
        param.dataType !== 'STR'
    );

    dataWithStringParameters.parameterData = [
      ...dataWithStringParameters.parameterData,
      ...defaultParameters.map((param) => {
        return {
          parameterId: param.parameterId < 1000 ? 6 * 1000 + param.parameterId : param.parameterId,
          ecuId: 6,
          value: param.defaultValue,
          id: 6,
          responseType: 'OK',
          status: ParamResponseResult.OK,
          parameter: {
            ...param,
          },
        };
      }),
    ];

    dataWithStringParameters.stringParameterData = [
      ...dataWithStringParameters.stringParameterData,
      ...defaultStringParameters.map((param) => {
        return {
          parameter: {
            ...param,
          },
          stringValue: param.defaultValue.toString(),
          paramId: param.parameterId < 1000 ? 6 * 1000 + param.parameterId : param.parameterId,
          status: ParamResponseResult.OK,
          eventType: StringParameterEventType.READ,
        };
      }),
    ];

    /*console.log(
      'DATA WITH STRINGPARAMETERS',
      dataWithStringParameters,
      'DEFAULT STRING PARAMETERS',
      defaultStringParameters,
      'DEFAULT PARAMETERS',
      defaultParameters
    );*/
    return dataWithStringParameters;
  }

  private mergedataWithoutStringParametersFromDefFile(dataWithoutStringParameters: {
    parameterData: IParameterData[];
  }) {
    // FETCH DEFAULT SETTINGS FOR THE PARAMETERS THAT WERENT UPLOADED AT THE FACTORY

    dataWithoutStringParameters.parameterData = dataWithoutStringParameters.parameterData.map((param) => {
      var paramIdAsNumber = parseInt(param.parameterId.toString());
      var valueAsNumber = parseInt(param.value.toString());
      return {
        ...param,
        parameterId: paramIdAsNumber < 1000 ? this.parameter.ecuId * 1000 + paramIdAsNumber : paramIdAsNumber,
        value: valueAsNumber,
      };
    });

    var compHW = this.deviceQuery.hardwareVersions.find((x) => x.ecuId === this.parameter.ecuId);
    var compFile = this.definitionQuery.compatibleFile(compHW.ecuId, compHW.buildNumber, compHW.compVersion);

    var defaultParameters = compFile.parameterDefinition.parameterList.filter(
      (param) =>
        !dataWithoutStringParameters.parameterData.find((x) => x.parameterId === param.parameterId) &&
        param.defaultValue !== undefined &&
        param.defaultValue !== null
    );

    dataWithoutStringParameters.parameterData = [
      ...dataWithoutStringParameters.parameterData,
      ...defaultParameters.map((param) => {
        return {
          parameterId: param.parameterId < 1000 ? this.parameter.ecuId * 1000 + param.parameterId : param.parameterId,
          ecuId: this.parameter.ecuId,
          value: param.defaultValue,
          id: this.parameter.ecuId,
          responseType: 'OK',
          status: ParamResponseResult.OK,
          parameter: {
            ...param,
          },
        };
      }),
    ];

    //console.log('DATA WITHOUT STRINGPARAMS', dataWithoutStringParameters, 'DEFAULTPARAMETERS', defaultParameters);
    return dataWithoutStringParameters;
  }

  private async writeParameters(res: any) {
    this.writingData = true;
    this.deviceService.resetBackupParameters();

    if (this.parameter.ecuId === 6) {
      var dataWithStringParameters = res?.data() as {
        parameterData: IParameterData[];
        stringParameterData: stringparametertemp[];
      };

      if (this.fetchFactorySettings) {
        // FETCH DEFAULT SETTINGS FOR THE PARAMETERS THAT WERENT UPLOADED AT THE FACTORY
        dataWithStringParameters = this.mergedataWithStringParametersFromDefFile(dataWithStringParameters);
      }

      for (var parameter of dataWithStringParameters?.parameterData) {
        if (parameter.parameterId < 1000) {
          parameter.parameterId = 6 * 1000 + parameter.parameterId;
        }

        if (parameter.parameterId === 6107) {
          parameter.value = 0;
        }

        if (!parameter?.parameter || parameter?.parameter?.accessType !== 'RO') {
          var param = {
            parameterId: parameter.parameterId,
            ecuId: parameter.ecuId,
            value: parameter.value,
          };
          this.deviceService.addParameterForBackup(param);
          this.connectApiService.writeParameterToDevice(parameter);
          await this.sleep(25);
        }
      }
      await this.sleep(2500);
      for (const parameter of dataWithStringParameters?.stringParameterData) {
        if (parameter.paramId < 1000) {
          parameter.paramId = 6 * 1000 + parameter.paramId;
        }
        var parameterToWrite: IstringParameterForUpdate = {
          parameterId: parameter.paramId,
          value: parameter.stringValue ?? '',
          ecuId: 6,
        };
        if (!parameter.parameter || (parameter?.parameter?.accessType !== 'RO' && parameter.paramId !== 6300)) {
          var stringParam = {
            parameterId: parameter.paramId,
            ecuId: 6,
            value: parameter.stringValue,
          };
          this.deviceService.addParameterForBackup(stringParam);
          this.connectApiService.writeStringParameterToDevice(parameterToWrite);
          await this.sleep(100);
        }
      }
      // DONE
      // setTimeout(() => {
      //   this.step = 4;
      //   this.firestoreService.forceFlag = true;
      // }, 2500);
    } else {
      var dataWithoutStringParameters = res?.data() as { parameterData: IParameterData[] };

      if (this.fetchFactorySettings) {
        // FETCH DEFAULT SETTINGS FOR THE PARAMETERS THAT WERENT UPLOADED AT THE FACTORY
        dataWithoutStringParameters = this.mergedataWithoutStringParametersFromDefFile(dataWithoutStringParameters);
      }

      // console.log(dataWithoutStringParameters);
      for (var parameter of dataWithoutStringParameters?.parameterData) {
        if (parameter.parameterId < 1000) {
          parameter.parameterId = parameter.ecuId * 1000 + parameter.parameterId;
        }

        if (parameter.parameterId === 1151 || parameter.parameterId === 2162) {
          parameter.value = 0;
        }

        if (parameter.parameterId === 1074 || parameter.parameterId === 2137) {
          parameter.value = parseInt(this.qrResult);
        }

        if (!parameter.parameter || parameter?.parameter?.accessType !== 'RO') {
          var param = {
            parameterId: parameter.parameterId,
            ecuId: parameter.ecuId,
            value: parameter.value,
          };
          this.deviceService.addParameterForBackup(param);

          this.connectApiService.writeParameterToDevice(parameter);
          await this.sleep(25);
        }
      }
    }

    // this.startSubscribeForBackUpParameters(res, currentSerialNumber, currentHardwareNumber);
  }

  private startSubscribeForBackUpParameters(res, currentHardwareNumber) {
    // console.log('STARTING SUBSCRIPTION');
    this.parametersAndStringParametersForBackupSubscription = this.deviceQuery.parametersAndStringParametersForBackup$
      .pipe(takeUntil(timer(this.parameter.ecuId === 6 ? 30000 : 15000)), takeUntil(this.writingData$))
      .subscribe({
        next: (params) => {
          // console.log(`PARAM LEFT TO HANDLE ${params.length}`, params);

          if (params && params.length === 0) {
            // DONE
            this.writingData$.next(true);
          }
        },
        complete: async () => {
          //console.log('Data emission completed.');
          if (this.deviceQuery.parametersAndStringParametersForBackup.length === 0) {
            // console.log('All data written.');
            this.writingData = false;
            this.handleBackUpIsDone(res, currentHardwareNumber);
            setTimeout(() => {
              this.step = 4;
              this.firestoreService.forceFlag = true;
            }, 1500);
          } else {
            this.startSubscribeForBackUpParameters(res, currentHardwareNumber);
            console.log('All data not written.', this.deviceQuery.parametersAndStringParametersForBackup);
            for (const parameter of this.deviceQuery.parametersAndStringParametersForBackup) {
              if (typeof parameter.value === 'string') {
                this.connectApiService.writeStringParameterToDevice({ ...parameter, value: parameter.value as string });
              } else {
                this.connectApiService.writeParameterToDevice({ ...parameter, value: parameter.value as number });
              }

              await this.sleep(100);
            }
          }
        },
      });
  }

  private handleBackUpIsDone(res, currentHardwareNumber) {
    var unitToSetAsActive = res.unitsToDeactivate.find((device) =>
      this.parameter.ecuId === 6
        ? device.uid === this.qrResult
        : device.uid === `${this.qrResult}-${currentHardwareNumber}`
    );
    this.firestoreService.setDeviceAsActive(unitToSetAsActive);
    setTimeout(() => {
      res.unitsToDeactivate
        .filter((device) =>
          this.parameter.ecuId === 6
            ? device.uid !== this.qrResult
            : device.uid !== `${this.qrResult}-${currentHardwareNumber}`
        )
        .forEach((unit) => {
          this.firestoreService.setDeviceAsInactive(unit);
        });

      this.firestoreService.updateFirestoreDevice();
    }, 2000);
  }

  public back() {
    if (this.step > 1) {
      this.fetchFactorySettings = undefined;
      this.step -= 1;
    }
  }
  // ****************** for scanning QR code**************** //
  public async startScanQR() {
    this.manuallyEnterSerialNumber = false;
    await BarcodeScanner.checkPermissions().then(async (res) => {
      if (res.camera !== 'granted') {
        await BarcodeScanner.requestPermissions();
      }
    });

    this.handleStopScanQRBound = this.stopScanQR.bind(this);
    document.getElementById('stop-icon').addEventListener('click', this.handleStopScanQRBound);
    this.readingQrScan = true;

    document.querySelector('body').classList.add('barcode-scanner-active');
    document.querySelector('#cancel-qr-container').classList.remove('hidden');

    // BarcodeScanner.hideBackground();

    this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(1000, () => {
      this.stopScanQR();
    });

    const listener = await BarcodeScanner.addListener('barcodeScanned', async (result) => {
      this.stopScanQR();
      // console.log(result.barcode);
      this.qrResult = result.barcode.displayValue;
      setTimeout(() => {
        this.readingQrScan = false;
        this.fetchOldSettings();
      }, 250);
    });

    await BarcodeScanner.startScan();

    // const result = await BarcodeScanner.startScan();

    // if (result.hasContent) {
    //   // this.qrResult = result.content;
    //   this.qrResult = result.content;
    //   console.log(result.content);
    //   BarcodeScanner.showBackground();
    //   setTimeout(() => {
    //     this.readingQrScan = false;
    //     this.fetchOldSettings();
    //   }, 2500);
    // }

    // document.querySelector('body').classList.remove('barcode-scanner-active');
    // document.querySelector('#cancel-qr-container').classList.add('hidden');
  }

  public async stopScanQR() {
    this.readingQrScan = false;
    this.fetchHasStarted = false;
    document.querySelector('body').classList.remove('barcode-scanner-active');
    // Remove all listeners
    await BarcodeScanner.removeAllListeners();

    // Stop the barcode scanner
    await BarcodeScanner.stopScan();

    var cancelButton = document.querySelector('#cancel-qr-container');
    if (!cancelButton.classList.contains('hidden')) {
      cancelButton.classList.add('hidden');
    }

    document.getElementById('stop-icon').removeEventListener('click', this.handleStopScanQRBound);
  }

  private getEcuNameFromEcuId(ecuID: number): string {
    switch (ecuID) {
      case 1:
        return 'TCU';

      case 2:
        return 'CCU';

      case 3:
        return 'HCU';

      case 4:
        return 'JLE';

      case 5:
        return 'JRI';

      case 6:
        return 'HMI';

      case 7:
        return 'CAC1';

      case 8:
        return 'CAC2';

      default:
        return 'Unknown';
    }
  }

  private sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  public closeModal() {
    this.deviceService.setIsBackuping(false);
    this.parametersAndStringParametersForBackupSubscription?.unsubscribe();
    this.modalService.dismissModal('SparePartModalComponent');
    this.modalService.dismissModal('InactiveUnitModalComponent');
  }

  public reSync() {
    this.fetchOldSettings();
    this.step = 3;
  }

  public async noUnitFound(serialNumber: string): Promise<void> {
    const buttonText = await this.translateService.instant(`auth.ok`);
    var header = this.translateService.instant(`spare parts.error`);
    var message = this.translateService.instant(`spare parts.could not find unit`, {
      ecuName: this.ecuName,
      serialNumber: serialNumber,
    });

    const body = `<div class="text-center">
    <p class="text-22pt text-roto-green py-2 font-semibold">${header}</p>
      <p class=" text-20pt text-roto-green">${message}</p>
    </div>`;

    const buttons = [{ text: buttonText, role: 'close', cssClass: 'no-button-borders' }];
    const alert = await this.alertController.create({
      message: body,
      keyboardClose: false,
      backdropDismiss: false,
      buttons,
    });
    alert.present();

    alert.onDidDismiss().then((res) => {
      // console.log('res: ', res);
      if (res.role === 'close') {
        alert.dismiss();
      }
    });
  }

  private async mismatchingSerialNumber(): Promise<void> {
    const buttonText = await this.translateService.instant(`auth.ok`);
    var header = this.translateService.instant(`spare parts.error`);
    var message = this.translateService.instant(`spare parts.mismatching serial number`);

    const body = `<div class="text-center">
    <p class="text-22pt text-roto-green py-2 font-semibold">${header}</p>
      <p class=" text-20pt text-roto-green">${message}</p>
    </div>`;
    const buttons = [{ text: buttonText, role: 'close', cssClass: 'no-button-borders' }];
    const alert = await this.alertController.create({
      message: body,
      keyboardClose: false,
      backdropDismiss: false,
      buttons,
    });
    alert.present();
    alert.onDidDismiss().then((res) => {
      // console.log('res: ', res);
      if (res.role === 'close') {
        alert.dismiss();
      }
    });
  }

  public enterManually() {
    this.manuallyEnterSerialNumber = true;
  }

  ngOnDestroy(): void {
    this.backButtonSubscription?.unsubscribe();
    this.harwareSubscription?.unsubscribe();
  }
}
