// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-footer {
  background: var(--ion-color-primary);
}

ion-spinner {
  height: 20px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImJsZS1kZXZpY2VzLmNvbXBvbmVudC5zY3NzIiwiLi5cXC4uXFwuLlxcLi5cXC4uXFwuLlxcLi5cXC4uXFxOZXclMjBmb2xkZXJcXGNvbm5lY3QtNC4wXFxzcmNcXGFwcFxcbGF5b3V0c1xcbWFpblxccGFnZXNcXGJsZS1kZXZpY2VzXFxibGUtZGV2aWNlcy5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNDLG9DQUFBO0FDQ0Q7O0FERUE7RUFDQyxZQUFBO0FDQ0QiLCJmaWxlIjoiYmxlLWRldmljZXMuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJpb24tZm9vdGVyIHtcclxuXHRiYWNrZ3JvdW5kOiB2YXIoLS1pb24tY29sb3ItcHJpbWFyeSk7XHJcbn1cclxuXHJcbmlvbi1zcGlubmVyIHtcclxuXHRoZWlnaHQ6IDIwcHg7XHJcbn0iLCJpb24tZm9vdGVyIHtcbiAgYmFja2dyb3VuZDogdmFyKC0taW9uLWNvbG9yLXByaW1hcnkpO1xufVxuXG5pb24tc3Bpbm5lciB7XG4gIGhlaWdodDogMjBweDtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/layouts/main/pages/ble-devices/ble-devices.component.scss","webpack://./../../New%20folder/connect-4.0/src/app/layouts/main/pages/ble-devices/ble-devices.component.scss"],"names":[],"mappings":"AAAA;EACC,oCAAA;ACCD;;ADEA;EACC,YAAA;ACCD;ADCA,guBAAguB","sourcesContent":["ion-footer {\r\n\tbackground: var(--ion-color-primary);\r\n}\r\n\r\nion-spinner {\r\n\theight: 20px;\r\n}","ion-footer {\n  background: var(--ion-color-primary);\n}\n\nion-spinner {\n  height: 20px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
