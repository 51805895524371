// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-icon {
  font-size: 10rem;
}

.button-icon {
  width: 1.5rem;
}

.button-icon-smaller {
  width: 1rem;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImR5bmFtaWMtbW9kYWwuY29tcG9uZW50LnNjc3MiLCIuLlxcLi5cXC4uXFwuLlxcLi5cXC4uXFwuLlxcLi5cXE5ldyUyMGZvbGRlclxcY29ubmVjdC00LjFcXHNyY1xcYXBwXFxsYXlvdXRzXFxtYWluXFxwYWdlc1xcZHluYW1pYy1tb2RhbFxcZHluYW1pYy1tb2RhbC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLGdCQUFBO0FDQ0o7O0FERUE7RUFDSSxhQUFBO0FDQ0o7O0FERUE7RUFDSSxXQUFBO0FDQ0oiLCJmaWxlIjoiZHluYW1pYy1tb2RhbC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5tYWluLWljb24ge1xyXG4gICAgZm9udC1zaXplOiAxMHJlbTtcclxufVxyXG5cclxuLmJ1dHRvbi1pY29uIHtcclxuICAgIHdpZHRoOiAxLjVyZW07XHJcbn1cclxuXHJcbi5idXR0b24taWNvbi1zbWFsbGVyIHtcclxuICAgIHdpZHRoOiAxcmVtO1xyXG59IiwiLm1haW4taWNvbiB7XG4gIGZvbnQtc2l6ZTogMTByZW07XG59XG5cbi5idXR0b24taWNvbiB7XG4gIHdpZHRoOiAxLjVyZW07XG59XG5cbi5idXR0b24taWNvbi1zbWFsbGVyIHtcbiAgd2lkdGg6IDFyZW07XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/layouts/main/pages/dynamic-modal/dynamic-modal.component.scss","webpack://./../../New%20folder/connect-4.1/src/app/layouts/main/pages/dynamic-modal/dynamic-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;ACCJ;;ADEA;EACI,aAAA;ACCJ;;ADEA;EACI,WAAA;ACCJ;ADCA,g2BAAg2B","sourcesContent":[".main-icon {\r\n    font-size: 10rem;\r\n}\r\n\r\n.button-icon {\r\n    width: 1.5rem;\r\n}\r\n\r\n.button-icon-smaller {\r\n    width: 1rem;\r\n}",".main-icon {\n  font-size: 10rem;\n}\n\n.button-icon {\n  width: 1.5rem;\n}\n\n.button-icon-smaller {\n  width: 1rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
