import { Injectable } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { AngularFirestore } from '@angular/fire/compat/firestore';

import { CONFIG } from '@constants/config';

import { IAlarm } from '@interfaces/alarm.interface';
import {
  ICurrentDevice,
  IHardwareVersion,
  IParameterData,
  IPeriodicData,
  stringparametertemp,
} from '@interfaces/device.interface';
//import * as firebase from 'firebase';
import { DatabaseReference, getDatabase, ref, set } from 'firebase/database';

import { AlarmQuery } from '@state/alarm/alarm.query';
import { DeviceQuery } from '@state/device/device.query';
import { UserQuery } from '@shared/state/user/user.query';
import { IAlarmLog } from '@shared/interfaces/alarm-log.interface';

//import { Timestamp } from 'rxjs/internal/operators/timestamp';

import { UpgradeFwStatusEvent } from 'connectapi/src/ApiMessage/apiResponseTypes';
import { IFirmwareFile } from '@shared/interfaces/firmware.interface';

@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  private address: string;

  private tcuAddress: string;
  private ccuAddress: string;
  private hmiAddress: string;

  private blueToothConnectorsTotal: string[] = [];

  private connectedStatus: string;

  private isConnected = false;

  private lastConnectedIsSet = false;

  private firstTcuUploadDone = false;
  private firstCcuUploadDone = false;
  private firstHmiUploadDone = false;

  private firebaseInterval: Subscription;
  private updateFirebaseSubscription: Subscription;
  private oldAlarms: IAlarm[];

  private oldAlarmLog: IAlarmLog[];

  private oldHardwareVersions: IHardwareVersion[] = [];
  private oldCurrentDevice: ICurrentDevice;

  private oldParameterData: IParameterData[];
  private oldStringParameterData: stringparametertemp[];

  private oldPeriodicData: IPeriodicData[];

  // 24 timmar
  private timerMs = 86400000;

  private databaseRefPeriodicDataTcu: DatabaseReference;
  private databaseRefLastConnectedTcu: DatabaseReference;
  private databaseRefLastConnectedUserTcu: DatabaseReference;

  private databaseRefPeriodicDataCcu: DatabaseReference;
  private databaseRefLastConnectedCcu: DatabaseReference;
  private databaseRefLastConnectedUserCcu: DatabaseReference;

  // HMI HAS PERIODIC DATA
  private databaseRefPeriodicDataHmi: DatabaseReference;
  private databaseRefLastConnectedHmi: DatabaseReference;
  private databaseRefLastConnectedUserHmi: DatabaseReference;

  public forceFlag = false;

  constructor(
    private angularFirestore: AngularFirestore,
    private deviceQuery: DeviceQuery,
    private alarmQuery: AlarmQuery,
    private userQuery: UserQuery
  ) {
    this.oldAlarms = [];
    this.deviceQuery.currentDevice$?.subscribe((res) => {
      if (res?.status === ('connected' as string)) {
        this.connectedStatus = res.status;
        const db = getDatabase();

        if (res?.tcuAddress) {
          this.tcuAddress = res.tcuAddress;

          this.databaseRefPeriodicDataTcu = ref(db, '/devices/' + this.tcuAddress + '/periodicData');
          this.databaseRefLastConnectedTcu = ref(db, '/devices/' + this.tcuAddress + '/lastConnected');
          this.databaseRefLastConnectedUserTcu = ref(db, '/devices/' + this.tcuAddress + '/lastConnectedUser');
        }

        if (res?.ccuAddress) {
          this.ccuAddress = res.ccuAddress;
          this.databaseRefPeriodicDataCcu = ref(db, '/devices/' + this.ccuAddress + '/periodicData');
          this.databaseRefLastConnectedCcu = ref(db, '/devices/' + this.ccuAddress + '/lastConnected');
          this.databaseRefLastConnectedUserCcu = ref(db, '/devices/' + this.ccuAddress + '/lastConnectedUser');
        }
        if (res?.hmiAddress) {
          this.hmiAddress = res.hmiAddress;
          this.databaseRefPeriodicDataHmi = ref(db, '/devices/' + this.hmiAddress + '/periodicData');
          this.databaseRefLastConnectedHmi = ref(db, '/devices/' + this.hmiAddress + '/lastConnected');
          this.databaseRefLastConnectedUserHmi = ref(db, '/devices/' + this.hmiAddress + '/lastConnectedUser');
        }

        this.address = res.address;
        this.isConnected = true;
      } else if (res?.status === 'offline') {
        this.connectedStatus = res.status;

        if (res?.tcuAddress) {
          this.tcuAddress = res.tcuAddress;
        }

        if (res?.ccuAddress) {
          this.ccuAddress = res.ccuAddress;
        }

        if (res?.hmiAddress) {
          this.hmiAddress = res.hmiAddress;
        }

        this.address = res.address;
      } else {
        this.isConnected = false;
      }
    });
  }

  public startFirestoreLink() {
    this.firstTcuUploadDone = false;
    this.firstCcuUploadDone = false;
    setTimeout(() => {
      if (
        (this.tcuAddress || this.ccuAddress || this.hmiAddress) &&
        this.isConnected &&
        this.connectedStatus === 'connected'
      ) {
        this.updateFireStorePeriodicValues();
      }
    }, 10000);

    this.sendLiveData(86400000);
    // x(second) * 60(min) * 1000(milliseconds)
    // const ms = 30000;
    const ms = 10000;
    this.updateFirebaseSubscription = interval(ms).subscribe(() => {
      if (
        (this.tcuAddress || this.ccuAddress || this.hmiAddress) &&
        this.isConnected &&
        this.connectedStatus === 'connected'
      ) {
        // console.log("START TO SET DATA");
        this.updateFirestoreDevice();
        this.updateAlarms();
        this.updateAlarmLog();
        this.updateFireStoreParameterValues();
        // this.updateFireStoreStringParameterValues();
        // this.updateServices();
        let forceFlagCopy = this.forceFlag;
        setTimeout(() => {
          if (forceFlagCopy) {
            this.forceFlag = false;
          }
        }, 2000);
      }
      if (this.connectedStatus === 'offline') {
        // console.log("START TO SET offline DATA");
        this.updateFirestoreDevice();
        this.updateAlarms();
        this.updateAlarmLog();
        this.updateFireStoreParameterValues();
        // this.updateFireStoreStringParameterValues();
        // this.updateServices();
      }
    });
  }

  public cancelFirestoreLink() {
    this.updateFirebaseSubscription?.unsubscribe();
  }

  private sendLiveData(ms: number) {
    this.firebaseInterval = interval(ms)
      .pipe(takeWhile(() => this.isConnected))
      .subscribe(() => {
        if ((this.tcuAddress || this.ccuAddress || this.hmiAddress) && this.connectedStatus === 'connected') {
          this.updateFireStorePeriodicValues();
        }
      });
  }

  public startLiveData(): void {
    // console.log('live session started');
    this.firebaseInterval?.unsubscribe();
    this.sendLiveData(50);
    setTimeout(() => {
      // console.log('live session ended');
      this.firebaseInterval?.unsubscribe();
      this.sendLiveData(86400000);
    }, 60000 * 15);
  }

  public endLiveData(): void {
    // console.log('live session ended');
    this.firebaseInterval?.unsubscribe();
    // this.sendLiveData(86400000);
  }

  private updateAlarms(): void {
    let alarms = this.alarmQuery.alarms;

    if (this.forceFlag || JSON.stringify(alarms) !== JSON.stringify(this.oldAlarms)) {
      JSON.parse(JSON.stringify(alarms));
      var tcuAlarms = alarms.filter((x) => x.ecuId === 1);
      var ccuAlarms = alarms.filter((x) => x.ecuId !== 1);

      if (this.tcuAddress) {
        // console.log('UPLOADING TCU ALARMS');
        this.angularFirestore
          .collection(CONFIG.firebaseCollection.devices)
          .doc(this.tcuAddress)
          .collection(CONFIG.firebaseCollection.alarms)
          .doc(CONFIG.firebaseCollection.activeAlarms)
          .set({ alarms: tcuAlarms });
      }

      if (this.ccuAddress) {
        // console.log('UPLOADING CCU ALARMS');
        this.angularFirestore
          .collection(CONFIG.firebaseCollection.devices)
          .doc(this.ccuAddress)
          .collection(CONFIG.firebaseCollection.alarms)
          .doc(CONFIG.firebaseCollection.activeAlarms)
          .set({ alarms: ccuAlarms });
      }

      // TODO: TA BORT KODEN NEDAN VILKET ÄR EN GAMMAL LÖSNING PÅ ALARMUPPLADDNING SOM TOG FÖR MYCKET RESURSER I FIREBASE
      // for (const alarm of alarms) {
      //   this.angularFirestore
      //     .collection(CONFIG.firebaseCollection.devices)
      //     .doc(this.address)
      //     .collection(CONFIG.firebaseCollection.alarms)
      //     .doc(CONFIG.firebaseCollection.previousAlarms)
      //     .collection(CONFIG.firebaseCollection.alarms)
      //     .doc(`${alarm.ecuId}-${alarm.errorCodeId}`)
      //     .set(alarm);
      // }

      this.oldAlarms = [...alarms];
    }
  }

  public updateLastUpdated(fw: UpgradeFwStatusEvent, fwFile: IFirmwareFile, ecuNameAsString: string) {
    var ecuName = ecuNameAsString ?? ecuId.toString();
    var ecuId = fw['ecuId'];
    if (ecuId === 1 && this.tcuAddress) {
      this.setLastUpdatedForDevice(this.tcuAddress, ecuName, fwFile);
    } else if (ecuId === 6 && this.hmiAddress) {
      this.setLastUpdatedForDevice(this.hmiAddress, ecuName, fwFile);
    } else if (ecuId && this.ccuAddress) {
      this.setLastUpdatedForDevice(this.ccuAddress, ecuName, fwFile);
    }
  }

  public updateLatestConnection(uid: string) {
    // console.log('tcu', serialNumber)

    if (uid) {
      this.angularFirestore.collection(CONFIG.firebaseCollection.users).doc(uid).update({
        latestConnection: '',
        hardwareVersions: '',
      });
    }
  }

  public updateLastConnected(address: string, uid: string, date: Date) {
    if (address) {
      this.angularFirestore
        .collection(CONFIG.firebaseCollection.devices)
        .doc<ICurrentDevice>(address)
        .update({ lastConnected: date });
    }

    if (address && uid) {
      this.angularFirestore
        .collection(CONFIG.firebaseCollection.users)
        .doc(uid)
        .collection(CONFIG.firebaseCollection.previousDevices)
        .doc<ICurrentDevice>(address)
        .update({ lastConnected: date });
    }
  }

  private updateAlarmLog(): void {
    let alarmLog = this.alarmQuery.alarmLog;

    if (this.forceFlag || JSON.stringify(alarmLog) !== JSON.stringify(this.oldAlarmLog)) {
      JSON.parse(JSON.stringify(alarmLog));
      var tcuAlarms = alarmLog.filter((x) => x.ecuId === 1);
      var ccuAlarms = alarmLog.filter((x) => x.ecuId !== 1);

      if (this.tcuAddress) {
        // console.log('UPLOADING TCU ALARM LOG');
        this.angularFirestore
          .collection(CONFIG.firebaseCollection.devices)
          .doc(this.tcuAddress)
          .collection(CONFIG.firebaseCollection.alarms)
          .doc(CONFIG.firebaseCollection.alarmLog)
          .set({ alarmLog: tcuAlarms });
      }

      if (this.ccuAddress) {
        // console.log('UPLOADING CCU ALARM LOG');
        this.angularFirestore
          .collection(CONFIG.firebaseCollection.devices)
          .doc(this.ccuAddress)
          .collection(CONFIG.firebaseCollection.alarms)
          .doc(CONFIG.firebaseCollection.alarmLog)
          .set({ alarmLog: ccuAlarms });
      }

      this.oldAlarmLog = [...alarmLog];
    }
  }

  private updateFireStoreParameterValues(): void {
    const parameterData = this.deviceQuery.parameterData;
    const stringParameterData = this.deviceQuery.stringParameterData;
    const { currentDevice } = this.deviceQuery;

    if (
      (this.forceFlag && parameterData?.length > 0) ||
      (parameterData?.length > 0 && JSON.stringify(parameterData) !== JSON.stringify(this.oldParameterData)) ||
      (stringParameterData?.length > 0 &&
        JSON.stringify(stringParameterData) !== JSON.stringify(this.oldStringParameterData) &&
        currentDevice)
    ) {
      var tcuParameters = parameterData.filter((x) => x.ecuId === 1);
      var hmiParameters = parameterData.filter((x) => x.ecuId === 6);
      var ccuParameters = parameterData.filter((x) => x.ecuId !== 1 && x.ecuId !== 6);

      var tcuSparePartFlag = parameterData.find((x) => x.parameterId === 1151);
      var ccuSparePartFlag = parameterData.find((x) => x.parameterId === 2162);
      var hmiSparePartFlag = parameterData.find((x) => x.parameterId === 6107);

      if (this.tcuAddress && tcuSparePartFlag?.value === 0) {
        // console.log('UPLOADING TCU PARAMETERS');
        this.angularFirestore
          .collection(CONFIG.firebaseCollection.devices)
          .doc(this.tcuAddress)
          .collection(CONFIG.firebaseCollection.parameterData)
          .doc(CONFIG.firebaseCollection.parameterData)
          .set({
            parameterData: JSON.parse(JSON.stringify(tcuParameters)),
          });
      }

      if (this.ccuAddress && ccuSparePartFlag?.value === 0) {
        // console.log('UPLOADING CCU PARAMETERS');
        this.angularFirestore
          .collection(CONFIG.firebaseCollection.devices)
          .doc(this.ccuAddress)
          .collection(CONFIG.firebaseCollection.parameterData)
          .doc(CONFIG.firebaseCollection.parameterData)
          .set({
            parameterData: JSON.parse(JSON.stringify(ccuParameters)),
          });
      }

      if (this.hmiAddress && hmiSparePartFlag?.value === 0) {
        // console.log('UPLOADING CCU PARAMETERS');
        this.angularFirestore
          .collection(CONFIG.firebaseCollection.devices)
          .doc(this.hmiAddress)
          .collection(CONFIG.firebaseCollection.parameterData)
          .doc(CONFIG.firebaseCollection.parameterData)
          .set({
            parameterData: JSON.parse(JSON.stringify(hmiParameters)),
            stringParameterData: JSON.parse(JSON.stringify(stringParameterData)),
          });
      }

      // for (const data of parameterData) {
      //   this.angularFirestore
      //     .collection(CONFIG.firebaseCollection.devices)
      //     .doc(this.address)
      //     .collection(CONFIG.firebaseCollection.parameterData)
      //     .doc<IParameterData>(`${data.ecuId}-${data.parameterId}`)
      //     .set(JSON.parse(JSON.stringify(data)));
      // }
      this.oldParameterData = [...parameterData];
      this.oldStringParameterData = [...stringParameterData];
    }
  }

  // private updateFireStoreStringParameterValues(): void {
  //   const stringParameterData = this.deviceQuery.stringParameterData;

  //   if (stringParameterData.length > 0 && JSON.stringify(stringParameterData) !== JSON.stringify(this.oldStringParameterData)) {
  //     this.angularFirestore
  //       .collection(CONFIG.firebaseCollection.devices)
  //       .doc(this.address)
  //       .collection(CONFIG.firebaseCollection.parameterData)
  //       .doc(CONFIG.firebaseCollection.parameterData)
  //       .update({ stringParameterData: JSON.parse(JSON.stringify(stringParameterData)) });

  //     // for (const data of parameterData) {
  //     //   this.angularFirestore
  //     //     .collection(CONFIG.firebaseCollection.devices)
  //     //     .doc(this.address)
  //     //     .collection(CONFIG.firebaseCollection.parameterData)
  //     //     .doc<IParameterData>(`${data.ecuId}-${data.parameterId}`)
  //     //     .set(JSON.parse(JSON.stringify(data)));
  //     // }
  //     this.oldStringParameterData = [...stringParameterData];
  //   }
  // }

  private updateFireStorePeriodicValues(): void {
    //ÄNDRAT FÖR ATT KUNNA KÖRA 100 ms
    // console.log("updated periodic data");
    const periodicData = this.deviceQuery.periodicData;
    var tcuPeriodicData = periodicData.filter((x) => x.ecuId === 1);
    var ccuPeriodicData = periodicData.filter((x) => x.ecuId === 2);
    var hmiPeriodicData = periodicData.filter((x) => x.ecuId === 6);

    set(this.databaseRefPeriodicDataTcu, tcuPeriodicData);
    set(this.databaseRefPeriodicDataCcu, ccuPeriodicData);
    set(this.databaseRefPeriodicDataHmi, hmiPeriodicData);
    /*this.databaseRefPeriodicDataTcu?.set(tcuPeriodicData);
    this.databaseRefPeriodicDataCcu?.set(ccuPeriodicData);*/
  }

  // private updateServices(): void {
  //   const services = this.deviceQuery.currentDevice.services;

  //   if (services.length > 0 && JSON.stringify(services) !== JSON.stringify(this.oldServices)) {
  //     for (const service of services) {
  //       this.angularFirestore
  //         .collection(CONFIG.firebaseCollection.devices)
  //         .doc(this.address)
  //         .collection(CONFIG.firebaseCollection.services)
  //         .doc(service.service)
  //         .set(service);
  //     }
  //     this.oldServices = [...services];
  //   }
  // }

  public uploadDeviceData(userUid: string, address: string, data: IHardwareVersion) {
    // console.log(`UPLOADING DATA FROM ECU: ${data?.ecuId},  ${address},  ${userUid}`);

    if (address && data && userUid) {
      // console.log(`UPLOADING DATA FROM ECU: ${data?.ecuId} , ${address},  ${userUid}`);
      try {
        this.angularFirestore
          .collection(CONFIG.firebaseCollection.devices)
          .doc(address)
          .set({ ...data, lastConnected: new Date() }, { merge: true });

        this.angularFirestore
          .collection(CONFIG.firebaseCollection.users)
          .doc(userUid)
          .collection(CONFIG.firebaseCollection.previousDevices)
          .doc<IHardwareVersion>(address)
          .set({ ...data, lastConnected: new Date() }, { merge: true });

        return Promise.resolve('ok');
      } catch (error) {
        return Promise.reject(`Something went wrong with upload to firebase: ${error}`);
      }
    } else {
      console.error(`Some of the following data was missing. userUid: ${userUid}, address: ${address}, data: ${data}`);
    }
  }

  public async updateFirestoreDevice(): Promise<void> {
    const { userUid } = this.userQuery;
    const { currentDevice, hardwareVersions, parameterData } = this.deviceQuery;

    // console.log('tcuaddress', currentDevice?.tcuAddress);

    var tcuData = await this.handleFirstTcuUpload(hardwareVersions?.find((x) => x.ecuId === 1));

    tcuData = {
      ...tcuData,
      ccuSerialNumber: currentDevice?.ccuAddress ? currentDevice?.ccuAddress : '',
      hmiSerialNumber: currentDevice?.hmiAddress ? currentDevice?.hmiAddress : '',
    };

    var ccuData = await this.handleFirstCcuUpload(hardwareVersions?.find((x) => x.ecuId === 2));
    ccuData = {
      ...ccuData,
      tcuSerialNumber: currentDevice?.tcuAddress ? currentDevice?.tcuAddress : '',
      hmiSerialNumber: currentDevice?.hmiAddress ? currentDevice?.hmiAddress : '',
    };
    var hmiData = await this.handleFirstHmiUpload(hardwareVersions?.find((x) => x.ecuId === 6));
    hmiData = {
      ...hmiData,
      tcuSerialNumber: currentDevice?.tcuAddress ? currentDevice?.tcuAddress : '',
      ccuSerialNumber: currentDevice?.ccuAddress ? currentDevice?.ccuAddress : '',
    };
    var tcuSparePartFlag = parameterData?.find((x) => x.parameterId === 1151);
    var ccuSparePartFlag = parameterData?.find((x) => x.parameterId === 2162);
    var hmiSparePartFlag = parameterData?.find((x) => x.parameterId === 6107);

    if (
      (this.forceFlag && hardwareVersions) ||
      (hardwareVersions && JSON.stringify(hardwareVersions) !== JSON.stringify(this.oldHardwareVersions)) ||
      (currentDevice && JSON.stringify(currentDevice) !== JSON.stringify(this.oldCurrentDevice))
    ) {
      if (this.tcuAddress && userUid && tcuData && tcuSparePartFlag?.value === 0) {
        this.uploadDeviceData(userUid, this.tcuAddress, { ...tcuData, isSparePart: false }).then(() => {
          this.firstTcuUploadDone = true;
        });
      }

      if (this.ccuAddress && userUid && ccuData && ccuSparePartFlag?.value === 0) {
        this.uploadDeviceData(userUid, this.ccuAddress, { ...ccuData, isSparePart: false }).then(() => {
          this.firstCcuUploadDone = true;
          // console.log('firstCcuUploadDone', this.firstCcuUploadDone);
        });
      }

      if (this.hmiAddress && userUid && hmiData && hmiSparePartFlag?.value === 0) {
        this.uploadDeviceData(userUid, this.hmiAddress, { ...hmiData, isSparePart: false }).then(() => {
          this.firstHmiUploadDone = true;
          // console.log('firstHmiUploadDone', this.firstHmiUploadDone);
        });
      }

      //console.log('UPLOADING USER DATA');

      if (userUid && hardwareVersions && hardwareVersions?.length > 0 && currentDevice) {
        this.angularFirestore.collection(CONFIG.firebaseCollection.users).doc(userUid).set(
          {
            hardwareVersions: hardwareVersions,
            latestConnection: currentDevice,
          },
          { merge: true }
        );

        this.oldHardwareVersions = hardwareVersions;
        this.oldCurrentDevice = currentDevice;
      }
    }

    var now = new Date().getTime();

    if (this.tcuAddress) {
      set(this.databaseRefLastConnectedTcu, now);
      //this.databaseRefLastConnectedTcu?.set(now);
      set(this.databaseRefLastConnectedUserTcu, this.userQuery.userUid ?? 'This device has no previous connection');
      //this.databaseRefLastConnectedUserTcu?.set(this.userQuery.userUid ?? 'This device has no previous connection');
    }

    if (this.ccuAddress) {
      set(this.databaseRefLastConnectedCcu, now);
      // this.databaseRefLastConnectedCcu?.set(now);
      set(this.databaseRefLastConnectedUserCcu, this.userQuery.userUid ?? 'This device has no previous connection');
      //this.databaseRefLastConnectedUserCcu?.set(this.userQuery.userUid ?? 'This device has no previous connection');
    }

    if (this.hmiAddress) {
      set(this.databaseRefLastConnectedHmi, now);
      // this.databaseRefLastConnectedHmi?.set(now);
      set(this.databaseRefLastConnectedUserHmi, this.userQuery.userUid ?? 'This device has no previous connection');
      //this.databaseRefLastConnectedUserHmi?.set(this.userQuery.userUid ?? 'This device has no previous connection');
    }
  }

  private async handleFirstTcuUpload(hardwareVersion: IHardwareVersion) {
    // console.log('i am in tcuaddress handel');
    var data: IHardwareVersion;
    if (hardwareVersion) {
      if (!this.firstTcuUploadDone && this.tcuAddress) {
        // console.log(this.tcuAddress);
        await this.angularFirestore
          .collection(CONFIG.firebaseCollection.devices)
          .doc(this.tcuAddress)
          .get()
          .toPromise()
          .then((docSnapshot) => {
            if (!docSnapshot.exists) {
              data = {
                ...hardwareVersion,
                address: this.tcuAddress?.split('-')[0],
                installationDate: new Date(),
                lastConnected: new Date(),
              };
            } else {
              var cloudData = docSnapshot.data() as IHardwareVersion;
              data = { ...cloudData, ...hardwareVersion, address: this.tcuAddress?.split('-')[0] };
            }
          });
      } else {
        data = { ...hardwareVersion, address: this.tcuAddress?.split('-')[0] };
      }
    }

    return data;
  }

  private async handleFirstCcuUpload(hardwareVersion: IHardwareVersion) {
    var data: IHardwareVersion;
    // console.log('CCU', hardwareVersion);
    if (hardwareVersion) {
      if (!this.firstCcuUploadDone && this.ccuAddress) {
        await this.angularFirestore
          .collection(CONFIG.firebaseCollection.devices)
          .doc(this.ccuAddress)
          .get()
          .toPromise()
          .then((docSnapshot) => {
            if (!docSnapshot.exists) {
              data = {
                ...hardwareVersion,
                address: this.ccuAddress.split('-')[0],
                installationDate: new Date(),
                lastConnected: new Date(),
              };
            } else {
              var cloudData = docSnapshot.data() as IHardwareVersion;
              data = { ...cloudData, address: this.ccuAddress.split('-')[0], ...hardwareVersion };
            }
          });
      } else {
        data = { ...hardwareVersion, address: this.ccuAddress?.split('-')[0] };
      }
    }

    return data;
  }

  private async handleFirstHmiUpload(hardwareVersion: IHardwareVersion) {
    var data: IHardwareVersion;
    // console.log('HMI', hardwareVersion);
    if (hardwareVersion) {
      if (!this.firstHmiUploadDone && this.hmiAddress) {
        await this.angularFirestore
          .collection(CONFIG.firebaseCollection.devices)
          .doc(this.hmiAddress)
          .get()
          .toPromise()
          .then((docSnapshot) => {
            if (!docSnapshot.exists) {
              data = {
                ...hardwareVersion,
                address: this.hmiAddress,
                installationDate: new Date(),
                lastConnected: new Date(),
              };
            } else {
              var cloudData = docSnapshot.data() as IHardwareVersion;
              data = { ...cloudData, address: this.hmiAddress, ...hardwareVersion };
            }
          });
      } else {
        data = { ...hardwareVersion, address: this.hmiAddress };
      }
    }

    return data;
  }

  public updateLatestTcuConnection(serialNumber: string) {
    // console.log('tcu', serialNumber)
    if (this.userQuery.userUid) {
      this.angularFirestore
        .collection(CONFIG.firebaseCollection.users)
        .doc(this.userQuery.userUid)
        .update({ latestTcuConnection: serialNumber });
    }
  }

  public updateLatestCcuConnection(serialNumber: string) {
    if (this.userQuery.userUid) {
      this.angularFirestore
        .collection(CONFIG.firebaseCollection.users)
        .doc(this.userQuery.userUid)
        .update({ latestCcuConnection: serialNumber });
    }
  }

  public updateLatestHmiConnection(serialNumber: string) {
    if (this.userQuery.userUid) {
      this.angularFirestore
        .collection(CONFIG.firebaseCollection.users)
        .doc(this.userQuery.userUid)
        .update({ latestHmiConnection: serialNumber });
    }
  }

  private setLastUpdatedForDevice(address: string, ecuName: string, fwFile: IFirmwareFile) {
    this.angularFirestore
      .collection(CONFIG.firebaseCollection.devices)
      .doc(address)
      .collection(CONFIG.firebaseCollection.lastUpdated)
      .doc(ecuName)
      .set({
        lastUpdated: new Date(),
        user: this.userQuery.name,
        userId: this.userQuery.userUid,
        firwareVersion: fwFile.buildNumber,
      });
  }

  public async fetchBackupParametersForSerialNumber(
    serialNumber: string,
    ecuId: number,
    useFactorySettings: boolean,
    currentHardwareNumber: string
  ) {
    if (serialNumber) {
      var unitFound = await this.angularFirestore
        .collection(CONFIG.firebaseCollection.devices, (ref) => ref.where('address', '==', serialNumber))
        .get()
        .toPromise();

      var maped = unitFound.docs.map((y) => ({ ...(y.data() as any), uid: y.id }));

      let unitData;

      var unitDataList = maped.filter(
        (dev: IHardwareVersion) =>
          !dev.isSparePart &&
          (!dev.isInactive ||
            (ecuId === 6 ? dev.uid === serialNumber : dev.uid === `${serialNumber}-${currentHardwareNumber}`))
      ) as IHardwareVersion[];
      if (unitDataList && unitDataList.length > 0) {
        if (unitDataList.length > 1) {
          unitData = unitDataList.find((unit) =>
            ecuId === 6 ? unit.uid === serialNumber : unit.uid === `${serialNumber}-${currentHardwareNumber}`
          );
        } else {
          unitData = unitDataList[0];
        }
      }

      if (unitData?.ecuId === ecuId) {
        var backupParameters = {
          parameterData: await this.angularFirestore
            .collection(useFactorySettings ? CONFIG.firebaseCollection.backups : CONFIG.firebaseCollection.devices)
            .doc(useFactorySettings ? `${serialNumber}-0` : unitData.uid)
            .collection(
              useFactorySettings ? CONFIG.firebaseCollection.backupParameters : CONFIG.firebaseCollection.parameterData
            )
            .doc(CONFIG.firebaseCollection.parameterData)
            .get()
            .toPromise(),
          deviceData: unitData,
          unitsToDeactivate: maped as IHardwareVersion[],
        };
        if (backupParameters.parameterData.exists) {
          return backupParameters;
        } else {
          return Promise.reject();
        }
      } else {
        return Promise.reject();
      }
    } else {
      return Promise.reject();
    }
  }

  public setOldDeviceAsSparePart(device: IHardwareVersion) {
    this.angularFirestore.collection(CONFIG.firebaseCollection.devices).doc(device.uid).update({ isSparePart: true });
  }

  public setDeviceAsInactive(device: IHardwareVersion) {
    if (device) {
      this.angularFirestore.collection(CONFIG.firebaseCollection.devices).doc(device.uid).update({ isInactive: true });
    } else {
      console.error('Could not find device to set as inactive');
    }
  }

  public setDeviceAsActive(device: IHardwareVersion) {
    if (device) {
      this.angularFirestore.collection(CONFIG.firebaseCollection.devices).doc(device.uid).update({ isInactive: false });
    } else {
      console.error('Could not find device to set as active');
    }
  }

  private handleIfDeviceBecomesInactive(address: string) {
    return this.angularFirestore
      .collection(CONFIG.firebaseCollection.devices)
      .doc<IHardwareVersion>(address)
      .valueChanges();
  }

  public logBackup(oldSerialNumber: string, newSerialNumber: string, typeOfUnit: string) {
    var user = this.userQuery.currentUser;
    return this.angularFirestore.collection(CONFIG.firebaseCollection.logs).add({
      user: `${user?.firstName ?? 'Could not find users first name'} ${user?.lastName ?? 'Could not users last name'}`,
      email: user.email ?? 'Could not find users email',
      date: new Date(),
      oldSerialNumber: newSerialNumber ?? 'Could not find old serial number',
      newSerialNumber: oldSerialNumber ?? 'Could not find new serial number',
      typeOfUnit: typeOfUnit ?? 'Could not find which type of unit',
    });
  }
}
