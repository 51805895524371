// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ion-img-icon {
  height: 60px;
  width: 60px;
  margin-left: -20px;
}`, "",{"version":3,"sources":["webpack://./src/app/layouts/main/pages/system-details/system-details.component.scss","webpack://./../../New%20folder/connect-4.1/src/app/layouts/main/pages/system-details/system-details.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,WAAA;EACA,kBAAA;ACCJ","sourcesContent":[".ion-img-icon {\r\n    height: 60px;\r\n    width: 60px;\r\n    margin-left: -20px;\r\n}",".ion-img-icon {\n  height: 60px;\n  width: 60px;\n  margin-left: -20px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
