import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { AlertController, Platform } from '@ionic/angular';
// import { FileTransfer, FileUploadOptions, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';

import { IFirmwareFile } from '@interfaces/firmware.interface';

import { ToastService } from './toast.service';
import { environment } from '@env/environment';
import { FirmwareService } from '@shared/state/firmware/firmware.service';
import { TranslateService } from '@ngx-translate/core';
declare var cordova: any;

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  // fileTransferObject: FileTransferObject = this.fileTransfer.create();

  public storageDirectory: string;

  constructor(
    private platform: Platform,
    // private fileTransfer: FileTransfer,
    private httpClient: HttpClient,
    private alertController: AlertController,
    private toastService: ToastService,
    private firmwareService: FirmwareService,
    private translateService: TranslateService
  ) {
    this.platform.ready().then(() => {
      if (!this.platform.is('cordova')) {
        return false;
      }

      if (this.platform.is('ios')) {
        this.storageDirectory = cordova.file.documentsDirectory;
      } else if (this.platform.is('android')) {
        this.storageDirectory = cordova.file.dataDirectory;
      }

      Filesystem.requestPermissions().then((gg) => {
        console.log('requestPermissions: ', gg);
      });

      // this.fileTransferObject.onProgress = (test) => {
      //   console.log('test: ', test);
      // };
    });
  }

  public async beginDownload(fullPath: string): Promise<boolean> {
    var message = this.translateService.instant('alerts.downloading file');
    const body = `
      <div class="text-center">
        <p class="text-22pt text-roto-green py-2 font-semibold">${message}</p>
      </div>`;

    const alert = await this.alertController.create({
      // header: 'Downloading',
      message: body,
      keyboardClose: false,
      backdropDismiss: false,
    });
    alert.present();

    const jsonData = await this.httpClient
      .get<IFirmwareFile>(environment.functions.storageDownload, { responseType: 'json', params: { path: fullPath } })
      .toPromise();

    await Filesystem.rmdir({
      path: 'firmware',
      directory: Directory.Data,
      recursive: true,
    })
      .then(async (res) => {
        console.log('Deletion of existing firmwares succeeded');
        // for (const file of res.files) {
        //   await Filesystem.deleteFile({
        //     path: 'firmware/' + file,
        //     directory: Directory.Data,
        //   }).then((res) => {
        //     console.log(`Deletion of ${file} succeeded`);
        //   }).catch(err => {
        //     console.log(`Deletion of ${file} failed`);
        //   });
        // }
      })
      .catch((err) => {
        console.log('Deletion of existing firmwares failed', err);
      });

    const fileIsSaved = await this.fileWrite({ ...jsonData, hasBeenUsed: false }, fullPath);

    this.firmwareService.addFileToStore(fullPath);

    alert.dismiss();

    if (fileIsSaved) {
      this.toastService.successToast('toasts.file downloaded', true, 'softwareupdate.software update');
    } else {
      this.toastService.dangerToast('toasts.file download error', true);
    }

    return fileIsSaved;
  }

  public async fileWrite(data: IFirmwareFile, path: string): Promise<boolean> {
    const result = await Filesystem.writeFile({
      path,
      recursive: true,
      data: JSON.stringify(data),
      directory: Directory.Data,
      encoding: Encoding.UTF8,
    });

    return result ? true : false;
  }
}
