import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { first, map, take } from 'rxjs/operators';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Timestamp } from '@firebase/firestore-types';

import { CONFIG } from '@constants/config';
import { ICurrentUser, IEmailTemplate } from '@interfaces/user.interface';
import { IRequest } from '@interfaces/request.interface';

import { RemoteSupportService } from '@services/remote-support.service';

import { UserStore } from './user.store';
import { UserQuery } from './user.query';
import { DeviceService } from '../device/device.service';
import { ISignUp } from '@shared/interfaces/sign-up.interface';

import { getFirestore, doc, updateDoc, serverTimestamp as firestoreServerTimestamp } from '@firebase/firestore';
import {
  getDatabase,
  ref,
  update,
  onValue,
  onDisconnect,
  serverTimestamp,
  update as updateRTDB,
} from 'firebase/database';
import { getAuth, EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth';

//import * as firebase from 'firebase';

import { INewPassword } from '@shared/interfaces/new-password.interface';
import { ToastService } from '@shared/services/toast.service';
import { FirestoreService } from '@shared/services/firestore.service';
import { AlarmService } from '../alarm/alarm.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, Platform } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private handledRequests: string[] = [];

  private userSubscription: Subscription;
  private remoteSubscription: Subscription;
  private roleSubscription: Subscription;

  private db = getDatabase();
  private firestore = getFirestore();

  constructor(
    private angularFirestore: AngularFirestore,
    private userStore: UserStore,
    private userQuery: UserQuery,
    private remoteSupportService: RemoteSupportService,
    private deviceService: DeviceService,
    private toastService: ToastService,
    private firestoreService: FirestoreService,
    private translateService: TranslateService,
    private alertController: AlertController,
    private platform: Platform,
    private alarmService: AlarmService,
    private httpClient: HttpClient
  ) {}

  public setUserState(userUid: string): void {
    this.userSubscription = this.angularFirestore
      .collection(CONFIG.firebaseCollection.users)
      .doc<ICurrentUser>(userUid)
      .valueChanges()
      .subscribe((user) => {
        if (user) {
          this.userStore.update((state) => {
            return {
              ...state,
              uid: userUid,
              currentUser: user,
            };
          });
          this.userStore.setLoading(false);
        }
      });

    this.roleSubscription = this.userQuery.role$?.pipe(take(2)).subscribe((role) => {
      // console.log('role: ', role);
      if (role === 'operator' || role === 'developer') {
        this.remoteSubscription = this.angularFirestore
          .collection(CONFIG.firebaseCollection.users)
          .doc<ICurrentUser>(userUid)
          .collection<IRequest>(CONFIG.firebaseCollection.remoteRequests)
          .valueChanges({ idField: 'uid' })
          .pipe(
            map((request) => request.filter((data) => !data.responded)),
            map((request) =>
              request.map((data) => ({
                ...data,
                requestDate: data.requestDate && (data.requestDate as Timestamp).toDate(),
                responseDate: data.responseDate && (data.responseDate as Timestamp).toDate(),
              }))
            )
          )
          .subscribe((request) => {
            // console.log('request: ', request);
            // Start some form of modal, grab data from constats or something
            // Require user to either accept/decline
            if (request.length > 0) {
              let date = null;
              request.forEach((element) => {
                if (!date) {
                  date = element.requestDate;
                } else if (element.requestDate > date) {
                  date = element.requestDate;
                }
              });
              const theRequest = request.find((x) => x.requestDate === date);
              //console.log('auto', theRequest);
              // const requestRecievedTime = new Date();
              // requestRecievedTime.setMinutes(requestRecievedTime.getMinutes() - 1);
              // var liveRequest = requestRecievedTime < theRequest.requestDate;

              if (!this.handledRequests.includes(theRequest.uid)) {
                this.handledRequests.push(theRequest.uid);

                var isOnTime = false;
                var dateNow = new Date();
                dateNow.setSeconds(dateNow.getSeconds() - 10);
                if (dateNow < theRequest.requestDate) {
                  isOnTime = true;
                }
                // console.log('auto', theRequest.type);
                switch (theRequest.type) {
                  case 'remote-support':
                    //  console.log('REMOTE SUPPORT');
                    if (isOnTime) {
                      this.remoteSupportService.showOperatorRemoteSupportAlert(userUid, theRequest);
                    }
                    break;
                  case 'remote-support-stop':
                    //console.log('REMOTE SUPPORT STOP');
                    this.firestoreService.endLiveData();
                    if (isOnTime) {
                      this.remoteSupportService.showOperatorRemoteSupportStopAlert(userUid, theRequest);
                    }

                    this.remoteSupportService.updateFirestoreRequest(userUid, theRequest.uid, true);
                    break;
                  case 'download-firmware':
                    //console.log('DOWNLOAD FIRMWARE');
                    this.remoteSupportService.showOperatorFirmwareDownloadAlert(userUid, theRequest);
                    break;
                  case 'update-parameters':
                    // console.log('update-parameters', isOnTime);
                    if (isOnTime) {
                      this.deviceService.addParameterForUpdate(theRequest);
                    }
                    this.remoteSupportService.updateFirestoreRequest(userUid, theRequest.uid, true);
                    // this.remoteSupportService.showOperatorParameterUpdateAlert(theRequest);
                    break;
                  case 'update-string-parameters':
                    //console.log('update-string-parameters');
                    if (isOnTime) {
                      this.deviceService.addStringParameterForUpdate(theRequest);
                    }
                    this.remoteSupportService.updateFirestoreRequest(userUid, theRequest.uid, true);
                    // this.remoteSupportService.showOperatorParameterUpdateAlert(theRequest);
                    break;
                  case 'auto-calibrate':
                    if (theRequest.stopCalibrate) {
                      this.deviceService.stopAutoCalibration(theRequest.stopCalibrate);
                      this.remoteSupportService.alertOperatorOnAutoCalibrate(theRequest, theRequest.stopCalibrate);
                    } else {
                      //  console.log('auto-calibrate');
                      const dateNow = new Date();
                      dateNow.setSeconds(dateNow.getSeconds() - 10);
                      if (dateNow < theRequest.requestDate) {
                        this.deviceService.addParameterForAutoCalibration(theRequest.autoCalibrateParameters);
                        this.remoteSupportService.alertOperatorOnAutoCalibrate(theRequest, theRequest.stopCalibrate);
                      }
                    }
                    this.remoteSupportService.updateFirestoreRequest(userUid, theRequest.uid, true);
                    break;
                  case 'live-data':
                    //console.log('live-data');
                    if (isOnTime) {
                      this.remoteSupportService.showOperatorLiveDataAlert(theRequest);
                      this.firestoreService.startLiveData();
                    }

                    this.remoteSupportService.updateFirestoreRequest(userUid, theRequest.uid, true);

                    break;
                  case 'clear-alarm':
                    //console.log('clear-alarm');
                    if (isOnTime) {
                      this.alarmService.addAlarmToBeCleared(theRequest.alarmToClear, theRequest.uid);
                    }

                    this.remoteSupportService.updateFirestoreRequest(userUid, theRequest.uid, true);
                    break;
                  case 'clear-alarm-log':
                    //console.log('clear-alarm-log');
                    // this.alarmService.clearLog()
                    if (isOnTime) {
                      this.alarmService.addClearAlarmLog(theRequest.clearType, theRequest.uid);
                    }

                    this.remoteSupportService.updateFirestoreRequest(userUid, theRequest.uid, true);
                    break;
                  case 'get-alarm-log':
                    //console.log('get-alarm-log');

                    if (isOnTime) {
                      this.alarmService.addGetAlarmLog();
                    }
                    this.remoteSupportService.updateFirestoreRequest(userUid, theRequest.uid, true);
                    break;
                  case 'get-parameters':
                    //console.log('get-parameters');
                    if (isOnTime) {
                      this.deviceService.getStringParameters(true, theRequest.parametersToFetch);
                    }

                    this.remoteSupportService.updateFirestoreRequest(userUid, theRequest.uid, true);
                  default:
                    break;
                }
              }
            }
          });
      } else if (role === 'service' || role === 'superadmin' || role === 'admin' || role === 'localadmin') {
        this.remoteSubscription = this.angularFirestore
          .collection(CONFIG.firebaseCollection.users)
          .doc<ICurrentUser>(userUid)
          .collection<IRequest>(CONFIG.firebaseCollection.remoteRequests)
          .valueChanges({ idField: 'uid' })
          .pipe(
            map((request) => request.filter((data) => !data.responded)),
            map((request) =>
              request.map((data) => ({
                ...data,
                requestDate: data.requestDate && (data.requestDate as Timestamp).toDate(),
                responseDate: data.responseDate && (data.responseDate as Timestamp).toDate(),
              }))
            )
          )
          .subscribe((request) => {
            ////console.log('request: ', request);
            // Start some form of modal, grab data from constats or something
            // Require user to either accept/decline
            if (request.length > 0) {
              let date;
              request.forEach((element) => {
                if (!date) {
                  date = element.requestDate;
                } else if (element.requestDate > date) {
                  date = element.requestDate;
                }
              });
              const theRequest = request.find((x) => x.requestDate === date);

              var isOnTime = false;
              var dateNow = new Date();
              dateNow.setSeconds(dateNow.getSeconds() - 10);
              if (dateNow < theRequest.requestDate) {
                isOnTime = true;
              }
              // const requestRecievedTime = new Date();
              // requestRecievedTime.setMinutes(requestRecievedTime.getMinutes() - 1);
              // var liveRequest = requestRecievedTime < theRequest.requestDate;
              switch (theRequest.type) {
                case 'remote-support-stop':
                  //console.log('REMOTE SUPPORT STOP');
                  if (isOnTime) {
                    this.remoteSupportService.showServiceRemoteSupportStopAlert(userUid, theRequest);
                  }

                  this.remoteSupportService.updateFirestoreRequest(userUid, theRequest.uid, true);
                  break;
                default:
                  break;
              }
            }
          });
      }
    });
  }

  public serviceToolBleIsActive(bool: boolean) {
    this.userStore.update(() => ({ serviceToolHasBleConnection: bool }));
  }

  public setStopAutoCalibration(bool: boolean) {
    this.userStore.update((state) => {
      return {
        ...state,
        autoCalibrationStarted: bool,
      };
    });
  }

  public updateLatestConnectionInfo(): void {
    //this.subscribeCurrentDeviceSubscription?.unsubscribe();
    this.firestoreService.updateLatestConnection(this.userQuery.userUid);
  }

  public notConnectedToDevice(bool: boolean) {
    this.userStore.update(() => ({ notConnectedToDevice: bool }));
  }

  public unsubscribe() {
    this.userSubscription?.unsubscribe();
    this.roleSubscription?.unsubscribe();

    if (this.remoteSubscription) {
      this.remoteSubscription.unsubscribe;
    }
  }

  // OLD FUNCTION FOR SEARCHING, NOT IN USE ANYMORE
  // public async findUserWithSerialNumber(serialNumber: string) {
  //   let devices: { deviceUid: string, serialNumber: string }[] = [];
  //   var deviceList = await this.angularFirestore.collection(CONFIG.firebaseCollection.devices).get().toPromise().then(val => {
  //     // return val;
  //     var user = val.docs.map((x) => {
  //       const data = x.data() as IPreviousDevice;
  //       return {
  //         ...data
  //       };
  //     })
  //     return user;
  //   });

  //   await Promise.all(deviceList.map((x) => this.angularFirestore.collection(CONFIG.firebaseCollection.devices)
  //     .doc(x.address)
  //     .collection(CONFIG.firebaseCollection.parameterData)
  //     .doc<{ parameterData: IParameterData[] }>(CONFIG.firebaseCollection.parameterData).get().toPromise().then(kal => {
  //       // .doc<IParameterData>('1-1074').get().toPromise().then(kal => {
  //       if (kal && kal.data()) {
  //         var serialNumberParameter = (kal.data()?.parameterData as IParameterData[]).find(x => x.parameterId === 1074);
  //         if (serialNumberParameter && serialNumberParameter.value && serialNumberParameter.value.toString() === serialNumber) {
  //           devices.push({ deviceUid: x.address, serialNumber: serialNumberParameter.value.toString() });
  //         }
  //       }
  //     })));
  //   console.log(devices);
  //   return devices;
  // }

  public changeRole(userId: string, newRole: string) {
    this.angularFirestore.collection(CONFIG.firebaseCollection.users).doc(userId).update({ role: newRole });
  }

  public updateUser(data: ISignUp): Promise<void> {
    const { firstName, lastName, email, phoneNumber } = data;
    const userProfile = {
      firstName,
      lastName,
      email,
      phoneNumber,
    };
    const userUid = this.userQuery.userUid;
    return this.angularFirestore
      .collection(CONFIG.firebaseCollection.users)
      .doc(userUid)
      .update(userProfile)
      .then((res) => {
        this.toastService.successToast('toasts.profile update succeeded', true, 'toasts.profile update');
      })
      .catch((err) => {
        this.toastService.warningToast('toasts.profile update failed', true);
        throw 'something went wrong';
      });
  }

  async changePassword(data: INewPassword): Promise<void> {
    const { password, newPassword, confirmNewPassword } = data;

    if (newPassword !== confirmNewPassword) {
      this.toastService.dangerToast('Passwords do not match', true);
      return Promise.reject('Passwords do not match');
    }

    if (newPassword === confirmNewPassword) {
      const user = getAuth().currentUser;
      const credentials = EmailAuthProvider.credential(user.email, password);

      try {
        // Reauthenticate with the current user's credentials
        await reauthenticateWithCredential(user, credentials);

        // Update the user's password
        await updatePassword(user, newPassword);

        this.toastService.successToast('auth.password update succeeed', true, 'toasts.profile update');
      } catch (error) {
        if (error.code === 'auth/wrong-password') {
          this.toastService.warningToast('auth.wrong password', true);
          throw 'wrong password';
        } else {
          this.toastService.dangerToast('auth.something went wrong', true);
          throw 'something went wrong';
        }
      }
    }
    /* const { password, newPassword, confirmNewPassword } = data;
    if (newPassword === confirmNewPassword) {
      const user = firebase.auth().currentUser;
      const credentials = firebase.auth.EmailAuthProvider.credential(user.email, password);
      return user
        .reauthenticateWithCredential(credentials)
        .then(() => {
          user
            .updatePassword(newPassword)
            .then(() => {
              this.toastService.successToast('auth.password update succeeed', true, 'toasts.profile update');
            })
            .catch(() => {
              this.toastService.dangerToast('auth.something went wrong', true);
              throw 'something went wrong';
            });
        })
        .catch(() => {
          this.toastService.warningToast('auth.wrong password', true);
          throw 'wrong password'
        });
    } else {
      return Promise.reject('passwords does not match');
    }*/
  }

  public goOffline() {
    var uid = getAuth().currentUser.uid;
    if (uid) {
      var userStatusFirestoreRef = doc(this.firestore, '/users/' + uid);
      var userStatusDatabaseRef = ref(this.db, '/status/' + uid);
      var isOfflineForFirestore = {
        state: 'offline',
        last_changed: firestoreServerTimestamp(),
      };
      var isOfflineForDatabase = {
        state: 'offline',
        last_changed: { '.sv': 'timestamp' },
      };

      try {
        // Update Firestore document
        updateDoc(userStatusFirestoreRef, isOfflineForFirestore);

        // Update Realtime Database reference
        update(userStatusDatabaseRef, isOfflineForDatabase);
      } catch (error) {
        console.error('Error updating user status:', error);
      }
      if (this.userSubscription) {
        this.userSubscription.unsubscribe();
      }
      if (this.remoteSubscription) {
        this.remoteSubscription.unsubscribe;
      }
      if (this.roleSubscription) {
        this.roleSubscription.unsubscribe;
      }
    }

    /*
    var uid = firebase.auth()?.currentUser?.uid;
    if (uid) {
      var userStatusFirestoreRef = firebase.firestore().doc('/users/' + uid);
      var userStatusDatabaseRef = firebase.database().ref('/status/' + uid);
      var isOfflineForFirestore = {
        state: 'offline',
        last_changed: firebase.firestore.FieldValue.serverTimestamp(),
      };
      var isOfflineForDatabase = {
        state: 'offline',
        last_changed: firebase.database.ServerValue.TIMESTAMP,
      };
      userStatusFirestoreRef.update(isOfflineForFirestore);

      userStatusDatabaseRef.update(isOfflineForDatabase);
    }
*/
  }

  // I princip tagen direkt från https://firebase.google.com/docs/firestore/solutions/presence

  public rtdb_and_local_fs_presence() {
    var uid = getAuth().currentUser.uid;
    var userStatusDatabaseRef = ref(this.db, '/status/' + uid);
    var userStatusFirestoreRef = doc(this.firestore, '/users/' + uid);

    const connectedRef = ref(this.db, '.info/connected');

    onValue(connectedRef, (snapshot) => {
      if (snapshot.val() === false) {
        // Set Firestore's state to 'offline'
        updateDoc(userStatusFirestoreRef, {
          state: 'offline',
          last_changed: firestoreServerTimestamp(),
        });
        return;
      }

      onDisconnect(userStatusDatabaseRef)
        .update({
          state: 'offline',
          last_changed: serverTimestamp(),
        })
        .then(() => {
          updateRTDB(userStatusDatabaseRef, {
            state: 'online',
            last_changed: serverTimestamp(),
          });

          // Also update Firestore when we come online
          updateDoc(userStatusFirestoreRef, {
            state: 'online',
            last_changed: firestoreServerTimestamp(),
          });
        });
    });

    /*
    var uid = firebase.auth().currentUser.uid;
    var userStatusDatabaseRef = firebase.database().ref('/status/' + uid);
    var userStatusFirestoreRef = firebase.firestore().doc('/users/' + uid);

    firebase
      .database()
      .ref('.info/connected')
      .on('value', function (snapshot) {
        if (snapshot.val() == false) {
          // Instead of simply returning, we'll also set Firestore's state
          // to 'offline'. This ensures that our Firestore cache is aware
          // of the switch to 'offline.'
          userStatusFirestoreRef.update({
            state: 'offline',
            last_changed: firebase.firestore.FieldValue.serverTimestamp(),
          });
          return;
        }

        userStatusDatabaseRef
          .onDisconnect()
          .update({ state: 'offline', last_changed: firebase.database.ServerValue.TIMESTAMP })
          .then(function () {
            userStatusDatabaseRef.update({ state: 'online', last_changed: firebase.database.ServerValue.TIMESTAMP });

            // We'll also add Firestore set here for when we come online.
            userStatusFirestoreRef.update({
              state: 'online',
              last_changed: firebase.firestore.FieldValue.serverTimestamp(),
            });
          });
      });*/
  }

  public reRenderDataTable(bool: boolean) {
    this.userStore.update(() => ({ dataTableBugFix: bool }));
  }

  public async deleteUserAlert() {
    const message = this.translateService.instant('alerts.delete account message');
    const doneMessage = this.translateService.instant('alerts.delete account message done');
    const failedMessage = this.translateService.instant('alerts.delete account message failed');
    const header = this.translateService.instant('alerts.delete account header');
    const cancelButton = this.translateService.instant('alerts.cancel');
    const deleteButton = this.translateService.instant('alerts.delete');

    const body = `
      <div class="text-center">
        <div class="m-auto flex justify-center">
          <ion-icon class="delete-alert-icon" name="trash"></ion-icon>
        </div>
      
        <p class="text-26pt text-roto-green py-2 font-semibold">${header}</p>
        <p class="text-18pt text-roto-green">${message}</p>
      </div>`;

    const waiting = `
      <div class="text-center">
        <div class="m-auto flex justify-center">
          <ion-icon class="delete-alert-icon" name="trash"></ion-icon>
        </div>

      <p class="text-26pt text-roto-green py-2 font-semibold">${header}</p>
      <div class="py-4">
        <ion-spinner></ion-spinner>
      </div>
        
      </div>`;

    const done = `
      <div class="text-center">
        <div class="m-auto flex justify-center">
          <ion-icon class="delete-alert-icon" name="trash"></ion-icon>
        </div>
    
        <p class="text-26pt text-roto-green py-2 font-semibold">${header}</p>
        <p class="text-18pt text-roto-green">${doneMessage}</p>
        
      </div>`;

    const failed = `
      <div class="text-center">
        <div class="m-auto flex justify-center">
          <ion-icon class="delete-alert-icon" name="trash"></ion-icon>
        </div>
    
        <p class="text-26pt text-roto-green py-2 font-semibold">${header}</p>
        <p class="text-18pt text-roto-green">${failedMessage}</p>
        
      </div>`;

    const alert = await this.alertController.create({
      message: body,
      keyboardClose: false,
      backdropDismiss: false,
      cssClass: 'delete-user',
      buttons: [
        {
          text: cancelButton,
          role: 'cancel',
        },
        {
          text: deleteButton,
          // role: 'delete',
          cssClass: 'text-roto-blue',
          handler: () => {
            alert.message = waiting;
            this.sendDeleteEmail()
              .then((res: { status: number; message: string }) => {
                //console.log(res);
                if (res?.status !== 200) {
                  alert.message = failed;
                } else {
                  alert.message = done;
                  alert.buttons = [
                    {
                      text: 'OK',
                      role: 'ok',
                      cssClass: 'no-button-borders',
                      handler: () => {
                        return true;
                      },
                    },
                  ];
                }
              })
              .catch((err) => {
                console.error(err);
                alert.message = failed;
              });

            return false;
          },
        },
      ],
    });

    await alert.present();
  }

  private async sendDeleteEmail() {
    var curUser = this.userQuery.currentUser;
    const { phoneNumber, email, customerNumber, company, lastName, firstName } = curUser;
    const subject = this.translateService.instant('email.subject');
    const text = this.translateService.instant('email.body', { user: curUser });
    var data: IEmailTemplate = {
      firstName,
      lastName,
      company,
      customerNumber,
      email,
      phoneNumber,
      subject,
      text,
      uid: this.userQuery.userUid,
    };

    /* return this.httpClient.post(environment.functions.sendEmail, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise();*/

    return await this.httpClient
      .post(environment.functions.sendEmail, data, {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
      })
      .toPromise();
  }
}
