import { Injectable, NgZone } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map, mergeMap } from 'rxjs/operators';
import { Timestamp } from '@firebase/firestore-types';

import { CONFIG } from '@constants/config';

import {
  IHardwareVersion,
  IParameterData,
  IPeriodicData,
  IPreviousDevice,
  stringparametertemp,
} from '@interfaces/device.interface';
import { ICurrentUser } from '@interfaces/user.interface';
import { IparameterForUpdate, IRequest, IstringParameterForUpdate } from '@interfaces/request.interface';

import { DatabaseReference, getDatabase, ref, off, onValue } from 'firebase/database';

import { RemoteUserStore } from './remote-user.store';
import { DeviceStore } from '../device/device.store';
import { AlarmStore } from '../alarm/alarm.store';
import { IAlarm } from '@shared/interfaces/alarm.interface';
import { IAlarmLog } from '@shared/interfaces/alarm-log.interface';
import { ToastService } from '@shared/services/toast.service';
import { AlarmQuery } from '../alarm/alarm.query';
import { Subscription, combineLatest } from 'rxjs';
import { Router } from '@angular/router';
import { RemoteUserQuery } from './remote-user.query';
import { UserQuery } from '../user/user.query';
import { FirestoreService } from '@services/firestore.service';

@Injectable({
  providedIn: 'root',
})
export class RemoteUserService {
  private currentDevice: string;

  private subscribeDeviceSubscription: Subscription;
  private subscribeCurrentDeviceSubscription: Subscription;

  private tcuAddress;
  private ccuAddress;
  private hmiAddress;

  private remoteUserSubscription: Subscription;

  private tcuParameterDataSubscription: Subscription;
  private tcuAlarmSubscription: Subscription;
  private tcuAlarmLogSubscription: Subscription;

  private ccuParameterDataSubscription: Subscription;
  private ccuAlarmSubscription: Subscription;
  private ccuAlarmLogSubscription: Subscription;

  private hmiParameterDataSubscription: Subscription;

  private alarmSubscription: Subscription;
  private alarmLogSubscription: Subscription;
  private parameterDataSubscription: Subscription;

  private tcuPeriodicDataSubscriptionRef: DatabaseReference;
  private tcuLastConnectedDataSubscriptionRef: DatabaseReference;
  private ccuPeriodicDataSubscriptionRef: DatabaseReference;
  private ccuLastConnectedDataSubscriptionRef: DatabaseReference;
  private hmiLastConnectedDataSubscriptionRef: DatabaseReference;

  private tcuPeriodicDataSubscriptionFunc: any;
  private tcuLastConnectedDataSubscriptionFunc: any;
  private ccuPeriodicDataSubscriptionFunc: any;
  private ccuLastConnectedDataSubscriptionFunc: any;
  private hmiLastConnectedDataSubscriptionFunc: any;
  private hmiPeriodicDataSubscriptionFunc: any;

  constructor(
    private angularFirestore: AngularFirestore,
    private ngZone: NgZone,
    private remoteUserStore: RemoteUserStore,
    private deviceStore: DeviceStore,
    private alarmStore: AlarmStore,
    private toastService: ToastService,
    private alarmQuery: AlarmQuery,
    private router: Router,
    private remoteUserQuery: RemoteUserQuery,
    private userQuery: UserQuery,
    private firestoreService: FirestoreService
  ) {}

  public reset(): void {
    this.ngZone.run(() => {
      this.remoteUserStore.update((state) => ({ ...state, isActive: false, connectionIsApproved: false }));
    });
  }

  public initialize(user: ICurrentUser, request: IRequest): void {
    //console.log(user);
    this.remoteUserStore.update((state) => ({
      ...state,
      isActive: true,
      connectedUser: user,
      remoteRequest: request,
      autoCalibrationStarted: false,
    }));
  }

  public resetStore(): void {
    this.remoteUserStore.update(() => ({
      isActive: false,
      connectionIsApproved: false,
    }));
  }

  public setRemoteSessionActive(serviceUid: string): void {
    this.remoteUserStore.update((state) => ({
      isActive: true,
      connectedUser: { ...state.connectedUser, uid: serviceUid },
    }));
  }

  public setRemoteSessionInActive(): void {
    this.remoteUserStore.update((state) => ({
      isActive: false,
      connectedUser: {} as ICurrentUser,
    }));
  }

  public resetStoreTotally(): void {
    this.remoteUserStore.reset();
    console.log('resetStoreTotally');
  }

  public setConnectionApproval(bool: boolean) {
    this.remoteUserStore.update((state) => ({
      connectionIsApproved: bool,
    }));
  }

  public initializeServiceToolBleConnection(user: ICurrentUser): void {
    this.remoteUserStore.update((state) => ({
      ...state,
      isActive: true,
      connectedUser: user,
      autoCalibrationStarted: false,
    }));
  }

  private startSubscriptionOnTcuUnit(serialNumber: string) {
    // console.log(serialNumber);

    const db = getDatabase();
    // PERIODIC DATA
    if (this.tcuPeriodicDataSubscriptionFunc) {
      off(this.tcuPeriodicDataSubscriptionRef, 'value', this.tcuPeriodicDataSubscriptionFunc);
    }

    this.tcuPeriodicDataSubscriptionRef = ref(db, '/devices/' + serialNumber + '/periodicData');
    this.tcuPeriodicDataSubscriptionFunc = this.tcuPeriodicDataSubscriptionFuncBound.bind(this);
    onValue(this.tcuPeriodicDataSubscriptionRef, this.tcuPeriodicDataSubscriptionFunc);

    // LAST CONNECTED
    if (this.tcuLastConnectedDataSubscriptionFunc) {
      off(this.tcuLastConnectedDataSubscriptionRef, 'value', this.tcuLastConnectedDataSubscriptionFunc);
    }

    this.tcuLastConnectedDataSubscriptionRef = ref(db, '/devices/' + serialNumber + '/lastConnected');
    this.tcuLastConnectedDataSubscriptionFunc = this.lastConnectedDataSubscriptionFuncBound.bind(this);
    onValue(this.tcuLastConnectedDataSubscriptionRef, this.tcuLastConnectedDataSubscriptionFunc);

    //PARAMETERS
    this.tcuParameterDataSubscription = this.angularFirestore
      .collection(CONFIG.firebaseCollection.devices)
      .doc(serialNumber)
      .collection(CONFIG.firebaseCollection.parameterData)
      .doc(CONFIG.firebaseCollection.parameterData)
      .valueChanges()
      .subscribe((data: any) => {
        this.remoteUserStore.update((state) => {
          var otherParameters = state.parameterData?.filter((x) => x.ecuId !== 1);
          return {
            ...state,
            parameterData: otherParameters ? [...otherParameters, ...data.parameterData] : data.parameterData,
          };
        });

        this.deviceStore.update((state) => {
          var otherParameters = state.parameterData?.filter((x) => x.ecuId !== 1);
          return {
            ...state,
            parameterData: otherParameters ? [...otherParameters, ...data.parameterData] : data.parameterData,
          };
        });
      });

    // ALARM
    this.tcuAlarmSubscription = this.angularFirestore
      .collection(CONFIG.firebaseCollection.devices)
      .doc(serialNumber)
      .collection(CONFIG.firebaseCollection.alarms)
      .doc<{ alarms: IAlarm[] }>(CONFIG.firebaseCollection.activeAlarms)
      .valueChanges()
      .subscribe((alarms) => {
        this.alarmStore.update((state) => {
          var ccuAlarms = state.alarms?.filter((x) => x.ecuId !== 1);
          const tcuAlarms = alarms.alarms.map((x) => ({
            ...x,
            timeGenerated: (x.timeGenerated as any).seconds
              ? new Date((x.timeGenerated as any).seconds * 1000)
              : x.timeGenerated,
          }));

          return {
            ...state,
            alarms: ccuAlarms ? [...ccuAlarms, ...tcuAlarms] : tcuAlarms,
          };
        });
      });

    // ALARM LOG
    this.tcuAlarmLogSubscription = this.angularFirestore
      .collection(CONFIG.firebaseCollection.devices)
      .doc(serialNumber)
      .collection(CONFIG.firebaseCollection.alarms)
      .doc(CONFIG.firebaseCollection.alarmLog)
      .valueChanges()
      .subscribe((alarms: any) => {
        const alarmLog = (alarms.alarmLog as IAlarmLog[]).map((x) => ({
          ...x,
          firstAsDate: (x.firstAsDate as any).seconds ? new Date((x.firstAsDate as any).seconds * 1000) : x.firstAsDate,
          lastAsDate: (x.lastAsDate as any).seconds ? new Date((x.lastAsDate as any).seconds * 1000) : x.lastAsDate,
        }));
        this.alarmStore.update((state) => {
          var ccuAlarmLog = state.alarmLog?.filter((x) => x.ecuId !== 1);
          return {
            ...state,
            alarmLog: ccuAlarmLog ? [...ccuAlarmLog, ...alarmLog] : alarmLog,
          };
        });
      });
  }

  private startSubscriptionOnCcu(serialNumber: string) {
    const db = getDatabase();
    // PERIODIC DATA
    if (this.ccuPeriodicDataSubscriptionFunc) {
      off(this.ccuPeriodicDataSubscriptionRef, 'value', this.ccuPeriodicDataSubscriptionFunc);
    }

    this.ccuPeriodicDataSubscriptionRef = ref(db, '/devices/' + serialNumber + '/periodicData');
    this.ccuPeriodicDataSubscriptionFunc = this.ccuPeriodicDataSubscriptionFuncBound.bind(this);
    onValue(this.ccuPeriodicDataSubscriptionRef, this.ccuPeriodicDataSubscriptionFunc);

    // LAST CONNECTED
    if (this.ccuLastConnectedDataSubscriptionFunc) {
      off(this.ccuLastConnectedDataSubscriptionRef, 'value', this.ccuLastConnectedDataSubscriptionFunc);
    }

    this.ccuLastConnectedDataSubscriptionRef = ref(db, '/devices/' + serialNumber + '/lastConnected');
    this.ccuLastConnectedDataSubscriptionFunc = this.lastConnectedDataSubscriptionFuncBound.bind(this);
    onValue(this.ccuLastConnectedDataSubscriptionRef, this.ccuLastConnectedDataSubscriptionFunc);

    //PARAMETERS
    this.ccuParameterDataSubscription = this.angularFirestore
      .collection(CONFIG.firebaseCollection.devices)
      .doc(serialNumber)
      .collection(CONFIG.firebaseCollection.parameterData)
      .doc(CONFIG.firebaseCollection.parameterData)
      .valueChanges()
      .subscribe((data: any) => {
        this.remoteUserStore.update((state) => {
          // CCU IS HADNLING PARAMETERS FOR BOTH CCU AND HCU
          var otherParameters = state.parameterData?.filter((x) => x.ecuId !== 2 && x.ecuId !== 3);
          return {
            ...state,
            parameterData: otherParameters ? [...otherParameters, ...data.parameterData] : data.parameterData,
          };
        });

        this.deviceStore.update((state) => {
          // CCU IS HADNLING PARAMETERS FOR BOTH CCU AND HCU
          var otherParameters = state.parameterData?.filter((x) => x.ecuId !== 2 && x.ecuId !== 3);
          return {
            ...state,
            parameterData: otherParameters ? [...otherParameters, ...data.parameterData] : data.parameterData,
          };
        });
      });

    // ALARM
    this.ccuAlarmSubscription = this.angularFirestore
      .collection(CONFIG.firebaseCollection.devices)
      .doc(serialNumber)
      .collection(CONFIG.firebaseCollection.alarms)
      .doc<{ alarms: IAlarm[] }>(CONFIG.firebaseCollection.activeAlarms)
      .valueChanges()
      .subscribe((alarms) => {
        this.alarmStore.update((state) => {
          var tcuAlarms = state.alarms?.filter((x) => x.ecuId === 1);
          const ccuAlarms = alarms.alarms.map((x) => ({
            ...x,
            timeGenerated: (x.timeGenerated as any).seconds
              ? new Date((x.timeGenerated as any).seconds * 1000)
              : x.timeGenerated,
          }));

          return {
            ...state,
            alarms: tcuAlarms ? [...tcuAlarms, ...ccuAlarms] : ccuAlarms,
          };
        });
      });

    // ALARM LOG
    this.ccuAlarmLogSubscription = this.angularFirestore
      .collection(CONFIG.firebaseCollection.devices)
      .doc(serialNumber)
      .collection(CONFIG.firebaseCollection.alarms)
      .doc(CONFIG.firebaseCollection.alarmLog)
      .valueChanges()
      .subscribe((alarms: any) => {
        const alarmLog = (alarms.alarmLog as IAlarmLog[]).map((x) => ({
          ...x,
          firstAsDate: (x.firstAsDate as any).seconds ? new Date((x.firstAsDate as any).seconds * 1000) : x.firstAsDate,
          lastAsDate: (x.lastAsDate as any).seconds ? new Date((x.lastAsDate as any).seconds * 1000) : x.lastAsDate,
        }));
        this.alarmStore.update((state) => {
          var tcuAlarmLog = state.alarmLog?.filter((x) => x.ecuId === 1);
          return {
            ...state,
            alarmLog: tcuAlarmLog ? [...tcuAlarmLog, ...alarmLog] : alarmLog,
          };
        });
      });
  }

  private startSubscriptionOnHmi(serialNumber: string) {
    const db = getDatabase();
    // PERIODIC DATA
    // DOES NOT EXIST ON HMI

    // LAST CONNECTED
    if (this.hmiLastConnectedDataSubscriptionFunc) {
      off(this.hmiLastConnectedDataSubscriptionRef, 'value', this.hmiLastConnectedDataSubscriptionFunc);
      // this.hmiLastConnectedDataSubscriptionRef.off('value', this.hmiLastConnectedDataSubscriptionFunc);
    }

    this.hmiLastConnectedDataSubscriptionRef = ref(db, '/devices/' + serialNumber + '/lastConnected');
    this.hmiLastConnectedDataSubscriptionFunc = this.lastConnectedDataSubscriptionFuncBound.bind(this);
    onValue(this.hmiLastConnectedDataSubscriptionRef, this.hmiLastConnectedDataSubscriptionFunc);
    // this.hmiLastConnectedDataSubscriptionRef.on('value', this.hmiLastConnectedDataSubscriptionFunc);

    //PARAMETERS
    this.hmiParameterDataSubscription = this.angularFirestore
      .collection(CONFIG.firebaseCollection.devices)
      .doc(serialNumber)
      .collection(CONFIG.firebaseCollection.parameterData)
      .doc(CONFIG.firebaseCollection.parameterData)
      .valueChanges()
      .subscribe((data: any) => {
        this.remoteUserStore.update((state) => {
          var otherParameters = state.parameterData?.filter((x) => x.ecuId !== 6);
          return {
            ...state,
            parameterData: otherParameters ? [...otherParameters, ...data.parameterData] : data.parameterData,
            stringParameterData: data.stringParameterData,
          };
        });

        this.deviceStore.update((state) => {
          var otherParameters = state.parameterData?.filter((x) => x.ecuId !== 6);
          return {
            ...state,
            parameterData: otherParameters ? [...otherParameters, ...data.parameterData] : data.parameterData,
            stringParameterData: data.stringParameterData,
          };
        });
      });

    // ALARM
    // DOES NOT EXIST ON HMI

    // ALARM LOG
    // DOES NOT EXIST ON HMI
  }

  public subscribeDevice(user: ICurrentUser): void {
    // console.log('in subscribe device', user);
    this.currentDevice = undefined;
    this.remoteUserSubscription = this.angularFirestore
      .collection(CONFIG.firebaseCollection.users)
      .doc(user?.uid)
      .valueChanges()
      .subscribe((user: ICurrentUser) => {
        var tcuAddress = user?.latestTcuConnection;
        var ccuAddress = user?.latestCcuConnection;
        var hmiAddress = user?.latestHmiConnection;

        var systype = user?.hardwareVersions?.find((item) => item.ecuId === 1 || item.ecuId === 2 || item.ecuId === 6);
        // console.log(systype);
        // console.log(tcuAddress, ccuAddress, hmiAddress, user?.uid);

        var reMappedHardwareVersions = user?.hardwareVersions?.map((data) => {
          return {
            ...data,
            lastConnected: data.lastConnected ? new Date(data.lastConnected) : undefined,
            installationDate: data.installationDate ? new Date(data.installationDate) : undefined,
            softwareVarient: data.softwareVarient ? data.softwareVarient : undefined,
            isSparePart: data.isSparePart ? data.isSparePart : undefined,
            isExperimental: data.isExperimental ? data.isExperimental : undefined,
            isUpgradeable: data.isUpgradeable ? data.isUpgradeable : undefined,
            softwareSubVarient: data.softwareSubVarient ? data.softwareSubVarient : undefined,
          };
        });
        this.deviceStore.update((state) => ({
          ...state,
          hardwareVersions: reMappedHardwareVersions ?? [],
          currentDevice: user?.latestConnection,
          systemType: this.getSystemAbbreviation(systype.softwareVarient),
        }));

        this.remoteUserStore.update((state) => ({
          ...state,
          hardwareVersions: reMappedHardwareVersions ?? [],
          device: user?.latestConnection,
        }));

        // console.log(this.tcuAddress);
        if (tcuAddress && tcuAddress !== this.tcuAddress) {
          this.tcuAddress = tcuAddress;
          this.startSubscriptionOnTcuUnit(tcuAddress);
        }

        if (ccuAddress && ccuAddress !== this.ccuAddress) {
          this.ccuAddress = ccuAddress;
          this.startSubscriptionOnCcu(ccuAddress);
        }

        if (hmiAddress && hmiAddress !== this.hmiAddress) {
          this.hmiAddress = hmiAddress;
          this.startSubscriptionOnHmi(hmiAddress);
        }
      });
  }
  public subscribeOfflineDevice(address: string, partnerOneAddress: string, partnerTwoAddress: string): void {
    this.currentDevice = undefined;

    const data$ = this.angularFirestore
      .collection(CONFIG.firebaseCollection.devices)
      .doc(address)
      .collection(CONFIG.firebaseCollection.parameterData)
      .doc(CONFIG.firebaseCollection.parameterData)
      .valueChanges();

    const partnerOneData$ = this.angularFirestore
      .collection(CONFIG.firebaseCollection.devices)
      .doc(partnerOneAddress)
      .collection(CONFIG.firebaseCollection.parameterData)
      .doc(CONFIG.firebaseCollection.parameterData)
      .valueChanges();

    const partnerTwoData$ = this.angularFirestore
      .collection(CONFIG.firebaseCollection.devices)
      .doc(partnerTwoAddress)
      .collection(CONFIG.firebaseCollection.parameterData)
      .doc(CONFIG.firebaseCollection.parameterData)
      .valueChanges();

    this.parameterDataSubscription = combineLatest([data$, partnerOneData$, partnerTwoData$])
      .pipe(
        map(([data1, data2, data3]) => {
          // Merge the data from both collections into a single array
          const mergedData = [...data1.parameterData, ...data2.parameterData, ...data3.parameterData];
          return mergedData;
        })
      )
      .subscribe((data: any) => {
        this.deviceStore.update((state) => {
          return {
            ...state,
            parameterData: data,
            stringParameterData: data.find((x) => x.ecuId === 6),
          };
        });
      });
    this.alarmSubscription = this.angularFirestore
      .collection(CONFIG.firebaseCollection.devices)
      .doc(address)
      .collection(CONFIG.firebaseCollection.alarms)
      .doc<{ alarms: IAlarm[] }>(CONFIG.firebaseCollection.activeAlarms)
      .valueChanges()
      .subscribe((alarms) => {
        var oldAlarms = this.alarmQuery.alarms;
        const oldAlarmCounter = oldAlarms.length;
        const newAlarmCounter = alarms.alarms.length;
        if (newAlarmCounter > oldAlarmCounter) {
          var alarmsDif = alarms.alarms.filter(
            ({ errorCodeId: id1 }) => !oldAlarms.some(({ errorCodeId: id2 }) => id2 === id1)
          );
          var dif = newAlarmCounter - oldAlarmCounter;
          let newAlarms = alarmsDif.map((alarm) => alarm.errorCodeId.toString());
          // this.toastService.infoToast(`${dif} new alarms, ids: ${newAlarms} `)
        } else if (oldAlarmCounter > newAlarmCounter) {
          var alarmsDif = oldAlarms.filter(
            ({ errorCodeId: id1 }) => !alarms.alarms.some(({ errorCodeId: id2 }) => id2 === id1)
          );
          var dif = oldAlarmCounter - newAlarmCounter;
          let newAlarms = alarmsDif.map((alarm) => alarm.errorCodeId.toString());
          // this.toastService.infoToast(`${dif} alarms removed, ids: ${newAlarms} `)
        }

        this.alarmStore.update((state) => ({
          ...state,
          alarms: alarms.alarms.map((x) => ({
            ...x,
            timeGenerated: (x.timeGenerated as any).seconds
              ? new Date((x.timeGenerated as any).seconds * 1000)
              : x.timeGenerated,
          })),
        }));
      });

    this.alarmLogSubscription = this.angularFirestore
      .collection(CONFIG.firebaseCollection.devices)
      .doc(address)
      .collection(CONFIG.firebaseCollection.alarms)
      .doc(CONFIG.firebaseCollection.alarmLog)
      .valueChanges()
      .subscribe((alarms: any) => {
        const alarmLog = (alarms.alarmLog as IAlarmLog[]).map((x) => ({
          ...x,
          firstAsDate: (x.firstAsDate as any).seconds ? new Date((x.firstAsDate as any).seconds * 1000) : x.firstAsDate,
          lastAsDate: (x.lastAsDate as any).seconds ? new Date((x.lastAsDate as any).seconds * 1000) : x.lastAsDate,
        }));
        this.alarmStore.update((state) => ({ ...state, alarmLog }));
      });
  }

  public updateLatestConnectionInfo(): void {
    this.firestoreService.updateLatestConnection(this.userQuery.userUid);
  }

  public updatePreviousDevices(previousDevices: IPreviousDevice[]): void {
    if (this.currentDevice !== previousDevices[0].address) {
      this.remoteUserStore.update((state) => {
        return {
          ...state,
          previousDevices,
        };
      });

      this.currentDevice = previousDevices[0].address;

      this.subscribeCurrentDevice(previousDevices[0].address);
    }
  }

  private tcuPeriodicDataSubscriptionFuncBound(snapshot) {
    var newData = snapshot.val();
    var oldData = this.remoteUserQuery.periodicData?.filter((x) => x.ecuId !== 1);
    var mergedData = oldData ? [...oldData, ...newData] : newData;

    this.remoteUserStore.update({ periodicData: mergedData });
    this.deviceStore.update({ periodicData: mergedData });
  }

  private ccuPeriodicDataSubscriptionFuncBound(snapshot) {
    var newData = snapshot.val();
    var oldData = this.remoteUserQuery.periodicData?.filter((x) => x.ecuId === 1);
    var mergedData = oldData ? [...oldData, ...newData] : newData;

    this.remoteUserStore.update({ periodicData: mergedData });
    this.deviceStore.update({ periodicData: mergedData });
  }

  private lastConnectedDataSubscriptionFuncBound(snapshot) {
    this.deviceStore.update((store) => {
      return {
        currentDevice: {
          ...store.currentDevice,
          lastConnected: new Date(snapshot.val()),
        },
      };
    });

    this.remoteUserStore.update((store) => {
      return {
        device: {
          ...store.device,
          lastConnected: new Date(snapshot.val()),
        },
      };
    });
  }

  private subscribeCurrentDevice(address: string): void {
    this.subscribeCurrentDeviceSubscription?.unsubscribe();
    this.subscribeCurrentDeviceSubscription = this.angularFirestore
      .collection(CONFIG.firebaseCollection.devices)
      .doc<IPreviousDevice>(address)
      .valueChanges()
      .pipe(
        map((data) => ({
          ...data,
          lastConnected: (data.lastConnected as Timestamp) ? (data.lastConnected as Timestamp).toDate() : undefined,
        }))
      )
      .subscribe((device) => {
        this.remoteUserStore.update((state) => ({ ...state, device }));
        this.deviceStore.update((state) => ({ ...state, device }));
      });

    this.parameterDataSubscription = this.angularFirestore
      .collection(CONFIG.firebaseCollection.devices)
      .doc(address)
      .collection(CONFIG.firebaseCollection.parameterData)
      .doc(CONFIG.firebaseCollection.parameterData)
      .valueChanges()
      .subscribe((data: any) => {
        this.remoteUserStore.update((state) => {
          return {
            ...state,
            parameterData: data.parameterData,
            stringParameterData: data.stringParameterData,
          };
        });
        this.deviceStore.update((state) => {
          return {
            ...state,
            parameterData: data.parameterData,
            stringParameterData: data.stringParameterData,
          };
        });
      });

    this.alarmSubscription = this.angularFirestore
      .collection(CONFIG.firebaseCollection.devices)
      .doc(address)
      .collection(CONFIG.firebaseCollection.alarms)
      .doc<{ alarms: IAlarm[] }>(CONFIG.firebaseCollection.activeAlarms)
      .valueChanges()
      .subscribe((alarms) => {
        var oldAlarms = this.alarmQuery.alarms;
        const oldAlarmCounter = oldAlarms.length;
        const newAlarmCounter = alarms.alarms.length;
        if (newAlarmCounter > oldAlarmCounter) {
          var alarmsDif = alarms.alarms.filter(
            ({ errorCodeId: id1 }) => !oldAlarms.some(({ errorCodeId: id2 }) => id2 === id1)
          );
          var dif = newAlarmCounter - oldAlarmCounter;
          let newAlarms = alarmsDif.map((alarm) => alarm.errorCodeId.toString());
          // this.toastService.infoToast(`${dif} new alarms, ids: ${newAlarms} `)
        } else if (oldAlarmCounter > newAlarmCounter) {
          var alarmsDif = oldAlarms.filter(
            ({ errorCodeId: id1 }) => !alarms.alarms.some(({ errorCodeId: id2 }) => id2 === id1)
          );
          var dif = oldAlarmCounter - newAlarmCounter;
          let newAlarms = alarmsDif.map((alarm) => alarm.errorCodeId.toString());
          // this.toastService.infoToast(`${dif} alarms removed, ids: ${newAlarms} `)
        }

        this.alarmStore.update((state) => ({
          ...state,
          alarms: alarms.alarms.map((x) => ({
            ...x,
            timeGenerated: (x.timeGenerated as any).seconds
              ? new Date((x.timeGenerated as any).seconds * 1000)
              : x.timeGenerated,
          })),
        }));
      });

    this.alarmLogSubscription = this.angularFirestore
      .collection(CONFIG.firebaseCollection.devices)
      .doc(address)
      .collection(CONFIG.firebaseCollection.alarms)
      .doc(CONFIG.firebaseCollection.alarmLog)
      .valueChanges()
      .subscribe((alarms: any) => {
        const alarmLog = (alarms.alarmLog as IAlarmLog[]).map((x) => ({
          ...x,
          firstAsDate: (x.firstAsDate as any).seconds ? new Date((x.firstAsDate as any).seconds * 1000) : x.firstAsDate,
          lastAsDate: (x.lastAsDate as any).seconds ? new Date((x.lastAsDate as any).seconds * 1000) : x.lastAsDate,
        }));
        this.alarmStore.update((state) => ({ ...state, alarmLog }));
      });
  }

  public setStopAutoCalibration(bool: boolean) {
    this.remoteUserStore.update((state) => {
      return {
        ...state,
        autoCalibrationStarted: bool,
      };
    });
  }

  public showSupportStop(operatorUid: string) {
    const userUid = this.userQuery.userUid;
    const userName = this.userQuery.name;
    this.angularFirestore
      .collection(CONFIG.firebaseCollection.users)
      .doc(operatorUid)
      .collection<IRequest>(CONFIG.firebaseCollection.remoteRequests)
      .add({
        requestDate: new Date(),
        type: 'remote-support-stop',
        responseDate: null,
        responded: false,
        response: null,
        serviceUserUid: userUid,
        serviceUserName: userName,
      });
  }

  public addPendingParameters(parameters: IparameterForUpdate[]) {
    this.ngZone.run(() => {
      this.remoteUserStore.update({
        pendingParameters: parameters,
      });
    });
  }

  public addPendingStringParameters(parameters: IstringParameterForUpdate[]) {
    this.ngZone.run(() => {
      this.remoteUserStore.update({
        pendingStringParameters: parameters,
      });
    });
  }

  public stopRemoteSupportFromServiceTool() {
    this.showSupportStop(this.remoteUserQuery.connectedUser.uid);

    this.removeDataSubscriptions();
    this.setRemoteSessionInActive();
  }

  public stopRemoteSupportFromOperator() {
    this.showSupportStop(this.remoteUserQuery.connectedUser.uid);
    this.setRemoteSessionInActive();
  }

  private getSystemAbbreviation(SV: number): string {
    switch (SV) {
      case 0:
        return 'not a system';

      case 1:
        return 'Test';

      case 2:
        // return 'MIC 4.0';  /***** added to test TCS */
        return 'RCS';

      case 3:
        return 'Standalone';

      case 4:
        return 'MIC 4.0';

      case 5:
        return 'TCS';

      default:
        return 'Unknown';
    }
  }

  public removeDataSubscriptions() {
    if (this.tcuPeriodicDataSubscriptionRef) {
      off(this.tcuPeriodicDataSubscriptionRef, 'value');
    }
    if (this.tcuLastConnectedDataSubscriptionRef) {
      off(this.tcuLastConnectedDataSubscriptionRef, 'value');
    }
    if (this.ccuPeriodicDataSubscriptionRef) {
      off(this.ccuPeriodicDataSubscriptionRef, 'value');
    }

    if (this.ccuLastConnectedDataSubscriptionRef) {
      off(this.ccuLastConnectedDataSubscriptionRef, 'value');
    }

    if (this.hmiLastConnectedDataSubscriptionRef) {
      off(this.hmiLastConnectedDataSubscriptionRef, 'value');
    }

    this.remoteUserSubscription?.unsubscribe();

    this.tcuParameterDataSubscription?.unsubscribe();
    this.tcuAlarmSubscription?.unsubscribe();
    this.tcuAlarmLogSubscription?.unsubscribe();

    this.ccuParameterDataSubscription?.unsubscribe();
    this.ccuAlarmSubscription?.unsubscribe();
    this.ccuAlarmLogSubscription?.unsubscribe();

    this.hmiParameterDataSubscription?.unsubscribe();
    this.subscribeDeviceSubscription?.unsubscribe();
    this.subscribeCurrentDeviceSubscription?.unsubscribe();
    this.alarmSubscription?.unsubscribe();
    this.alarmLogSubscription?.unsubscribe();
    this.parameterDataSubscription?.unsubscribe();
    this.resetStoreTotally();
    this.tcuAddress = '';
    this.ccuAddress = '';
    this.hmiAddress = '';
    this.router.navigate(['/']);
  }
}
