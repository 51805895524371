// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-icon {
  font-size: 2.2rem;
}

ion-card {
  box-shadow: none;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInJlc2V0LXBhc3N3b3JkLmNvbXBvbmVudC5zY3NzIiwiLi5cXC4uXFwuLlxcLi5cXC4uXFwuLlxcLi5cXC4uXFxOZXclMjBmb2xkZXJcXGNvbm5lY3QtNC4wXFxzcmNcXGFwcFxcbGF5b3V0c1xcYXV0aFxccGFnZXNcXHJlc2V0LXBhc3N3b3JkXFxyZXNldC1wYXNzd29yZC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNDLGlCQUFBO0FDQ0Q7O0FERUE7RUFDQyxnQkFBQTtBQ0NEIiwiZmlsZSI6InJlc2V0LXBhc3N3b3JkLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiaW9uLWljb24ge1xyXG5cdGZvbnQtc2l6ZTogMi4ycmVtO1xyXG59XHJcblxyXG5pb24tY2FyZCB7XHJcblx0Ym94LXNoYWRvdzogbm9uZTtcclxufVxyXG4iLCJpb24taWNvbiB7XG4gIGZvbnQtc2l6ZTogMi4ycmVtO1xufVxuXG5pb24tY2FyZCB7XG4gIGJveC1zaGFkb3c6IG5vbmU7XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/layouts/auth/pages/reset-password/reset-password.component.scss","webpack://./../../New%20folder/connect-4.0/src/app/layouts/auth/pages/reset-password/reset-password.component.scss"],"names":[],"mappings":"AAAA;EACC,iBAAA;ACCD;;ADEA;EACC,gBAAA;ACCD;ADCA,gsBAAgsB","sourcesContent":["ion-icon {\r\n\tfont-size: 2.2rem;\r\n}\r\n\r\nion-card {\r\n\tbox-shadow: none;\r\n}\r\n","ion-icon {\n  font-size: 2.2rem;\n}\n\nion-card {\n  box-shadow: none;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
