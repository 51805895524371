// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-icon {
  font-size: 2.2rem;
}

ion-card {
  box-shadow: none;
}`, "",{"version":3,"sources":["webpack://./src/app/layouts/auth/pages/reset-password/reset-password.component.scss","webpack://./../../New%20folder/connect-4.1/src/app/layouts/auth/pages/reset-password/reset-password.component.scss"],"names":[],"mappings":"AAAA;EACC,iBAAA;ACCD;;ADEA;EACC,gBAAA;ACCD","sourcesContent":["ion-icon {\r\n\tfont-size: 2.2rem;\r\n}\r\n\r\nion-card {\r\n\tbox-shadow: none;\r\n}\r\n","ion-icon {\n  font-size: 2.2rem;\n}\n\nion-card {\n  box-shadow: none;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
