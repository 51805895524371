// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-button {
  --background: #576D7C;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  height: 43px;
  border-radius: 8px !important;
  --border-radius: 8px !important;
  min-width: 80% !important;
  text-transform: none;
}

ion-text {
  font-size: 20px;
  color: #FFFFFF;
  font-weight: normal;
}

@media screen and (max-width: 355px) {
  ion-text {
    font-size: 18px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/button/button.component.scss","webpack://./../../New%20folder/connect-4.1/src/app/shared/components/button/button.component.scss"],"names":[],"mappings":"AAAA;EACC,qBAAA;EACA,2CAAA;EACA,YAAA;EACA,6BAAA;EACA,+BAAA;EACA,yBAAA;EACA,oBAAA;ACCD;;ADCA;EACC,eAAA;EACA,cAAA;EACA,mBAAA;ACED;;ADCA;EACC;IACC,eAAA;ECEA;AACF","sourcesContent":["ion-button {\r\n\t--background: #576D7C;\r\n\tbox-shadow: 0px 0px 15px #00000033;\r\n\theight: 43px;\r\n\tborder-radius: 8px !important;\r\n\t--border-radius: 8px !important;\r\n\tmin-width: 80% !important;\r\n\ttext-transform: none;\r\n}\r\nion-text {\r\n\tfont-size: 20px;\r\n\tcolor: #FFFFFF;\r\n\tfont-weight: normal;\r\n}\r\n\r\n@media screen and (max-width: 355px) {\r\n\tion-text {\r\n\t\tfont-size: 18px;\r\n\t}\r\n}\r\n","ion-button {\n  --background: #576D7C;\n  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);\n  height: 43px;\n  border-radius: 8px !important;\n  --border-radius: 8px !important;\n  min-width: 80% !important;\n  text-transform: none;\n}\n\nion-text {\n  font-size: 20px;\n  color: #FFFFFF;\n  font-weight: normal;\n}\n\n@media screen and (max-width: 355px) {\n  ion-text {\n    font-size: 18px;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
