// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-input {
  color: black;
  --padding-top: 0;
  --padding-end: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  background: white;
  font-size: 30px;
}

.spinner-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(240, 240, 240, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

ion-spinner {
  width: 30px;
  height: 30px;
  color: black;
}

ion-icon {
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/app/layouts/main/pages/spare-part-modal/spare-part-modal.component.scss","webpack://./../../New%20folder/connect-4.1/src/app/layouts/main/pages/spare-part-modal/spare-part-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;ACCF;;ADEA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,OAAA;EACA,oCAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;ACCF;;ADEA;EACE,WAAA;EACA,YAAA;EACA,YAAA;ACCF;;ADEA;EACE,YAAA;ACCF","sourcesContent":["ion-input {\r\n  color: black;\r\n  --padding-top: 0;\r\n  --padding-end: 0;\r\n  --padding-bottom: 0;\r\n  --padding-start: 0;\r\n  background: white;\r\n  font-size: 30px;\r\n}\r\n\r\n.spinner-container {\r\n  position: absolute;\r\n  width: 100%;\r\n  height: 100%;\r\n  top: 0;\r\n  right: 0;\r\n  bottom: 0;\r\n  left: 0;\r\n  background: rgba(240, 240, 240, 0.9);\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  z-index: 100;\r\n}\r\n\r\nion-spinner {\r\n  width: 30px;\r\n  height: 30px;\r\n  color: black;\r\n}\r\n\r\nion-icon {\r\n  color: black;\r\n}\r\n","ion-input {\n  color: black;\n  --padding-top: 0;\n  --padding-end: 0;\n  --padding-bottom: 0;\n  --padding-start: 0;\n  background: white;\n  font-size: 30px;\n}\n\n.spinner-container {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  background: rgba(240, 240, 240, 0.9);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 100;\n}\n\nion-spinner {\n  width: 30px;\n  height: 30px;\n  color: black;\n}\n\nion-icon {\n  color: black;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
