import { Injectable, NgZone } from '@angular/core';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { arrayUpsert } from '@datorama/akita';
import { IFirmwareFile, IUpgradeProgress } from '@shared/interfaces/firmware.interface';
import { UpgradeFwStatusEvent } from 'connectapi/src/ApiMessage/apiResponseTypes';
import { FirmwareStore } from './firmware.store';

@Injectable({
  providedIn: 'root',
})
export class FirmwareService {
  public existingFiles: string[] = [];

  constructor(private firmwareStore: FirmwareStore, private ngZone: NgZone) {}

  public async loadFirmwares(): Promise<void> {
    await this.updateExistingFiles();
    //console.log('this.existingFiles: ', this.existingFiles);

    await this.setupFirmewareStore();
  }

  public async addFileToStore(path: string): Promise<void> {
    this.ngZone.run(() => {
      this.firmwareStore.update((state) => ({
        firmwares: [],
      }));
    });

    const file = await Filesystem.readFile({
      path,
      directory: Directory.Data,
      encoding: Encoding.UTF8,
    });

    if (file?.data) {
      let firmware = {} as IFirmwareFile;
      firmware = { ...JSON.parse(file.data as string) };

      this.ngZone.run(() => {
        this.firmwareStore.update((state) => ({
          firmwares: arrayUpsert(state.firmwares, firmware.buildNumber, firmware),
        }));
      });
    }
  }

  private async updateExistingFiles(): Promise<void> {
    await Filesystem.readdir({ directory: Directory.Data, path: 'firmware' })
      .then((readResult) => {
        //console.log('readResult: ', readResult);
        this.existingFiles = readResult.files.map((x) => x.name);
      })
      .catch((err) => {
        console.error(err);
        Filesystem.mkdir({ directory: Directory.Data, path: 'firmware' });
        this.updateExistingFiles();
      });
  }

  private async setupFirmewareStore(): Promise<void> {
    for (const existingFile of this.existingFiles) {
      setTimeout(() => {
        this.addFileToStore('firmware/' + existingFile);
      }, 500);
    }
  }

  public setOngoing(started: boolean) {
    this.ngZone.run(() => {
      this.firmwareStore.update({
        ongoingUpgrade: started,
      });
    });
  }

  public async updateFirmwareHasBeenUsed(firmware: IFirmwareFile) {
    var dir = await Filesystem.readdir({
      path: 'firmware',
      directory: Directory.Data,
    });

    if (dir && dir.files?.length > 0) {
      var path = 'firmware/' + dir.files[0].name;
      var fileHasBeenUsed: IFirmwareFile = { ...firmware, hasBeenUsed: true };
      var success = await Filesystem.writeFile({
        path,
        recursive: true,
        data: JSON.stringify(fileHasBeenUsed),
        directory: Directory.Data,
        encoding: Encoding.UTF8,
      });

      if (success) {
        this.addFileToStore(path);
      }

      return success;
    }
  }

  public updateFirmwareCount() {
    this.ngZone.run(() => {
      this.firmwareStore.update((state) => ({
        numberOfUpdates: state.numberOfUpdates + 1,
      }));
    });
  }

  public updateSetAuto(val: boolean) {
    this.ngZone.run(() => {
      this.firmwareStore.update({
        keepGoing: val,
      });
    });
  }

  public updateProgress(fwStatusEvent: UpgradeFwStatusEvent) {
    const data: IUpgradeProgress = {
      currentUpgradeIdx: fwStatusEvent['currentUpgradeIdx'],
      ecuId: fwStatusEvent['ecuId'],
      ecuName: this.getEcuNameFromEcuId(fwStatusEvent['ecuId']),
      noOfUpgrades: fwStatusEvent['noOfUpgrades'],
      progress: Math.floor(fwStatusEvent['progress']) / 100,
      responseType: fwStatusEvent['responseType'],
      status: fwStatusEvent['status'],
      updgradeStatus: this.getUpgradeStatus(fwStatusEvent['status']),
    };

    this.ngZone.run(() => {
      this.firmwareStore.update((state) => ({
        upgradeProgress: arrayUpsert(state.upgradeProgress, data.ecuId, data),
      }));
    });
  }

  private getEcuNameFromEcuId(EcuID: number): string {
    switch (EcuID) {
      case 1:
        return 'TCU';

      case 2:
        return 'CCU';

      case 3:
        return 'HCU';

      case 4:
        return 'JLE';

      case 5:
        return 'JRI';

      case 6:
        return 'HMI';

      case 7:
        return 'CAC1';

      case 8:
        return 'CAC2';

      case 9:
        return 'DCU';

      default:
        return 'Unknown';
    }
  }

  private getUpgradeStatus(status: number) {
    switch (status) {
      case 1:
        return 'Starting...';

      case 2:
        return 'Running...';

      case 3:
        return 'Aborted';

      case 4:
        return 'Finished';

      default:
        return '';
    }
  }

  public resetUpdateProgress() {
    this.ngZone.run(() => {
      this.firmwareStore.update((state) => ({
        upgradeProgress: [],
      }));
    });
  }
}
