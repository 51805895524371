// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

ion-spinner {
  width: 30px;
  height: 30px;
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/app/layouts/main/pages/spare-part-modal-service/spare-part-modal-service.component.scss","webpack://./../../New%20folder/connect-4.1/src/app/layouts/main/pages/spare-part-modal-service/spare-part-modal-service.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EAEA,aAAA;EACA,uBAAA;EACA,mBAAA;ACAF;;ADGA;EACE,WAAA;EACA,YAAA;EACA,YAAA;ACAF","sourcesContent":[".spinner-container {\r\n  position: relative;\r\n\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\nion-spinner {\r\n  width: 30px;\r\n  height: 30px;\r\n  color: black;\r\n}\r\n",".spinner-container {\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\nion-spinner {\n  width: 30px;\n  height: 30px;\n  color: black;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
