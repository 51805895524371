// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card {
  --ion-card-background: transparent;
  box-shadow: none !important;
  padding: 0 1rem;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImF1dGgtY2FyZC5jb21wb25lbnQuc2NzcyIsIi4uXFwuLlxcLi5cXC4uXFwuLlxcLi5cXC4uXFwuLlxcTmV3JTIwZm9sZGVyXFxjb25uZWN0LTQuMVxcc3JjXFxhcHBcXGxheW91dHNcXGF1dGhcXGNvbXBvbmVudHNcXGF1dGgtY2FyZFxcYXV0aC1jYXJkLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0Msa0NBQUE7RUFDQSwyQkFBQTtFQUNBLGVBQUE7QUNDRCIsImZpbGUiOiJhdXRoLWNhcmQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJpb24tY2FyZCB7XHJcblx0LS1pb24tY2FyZC1iYWNrZ3JvdW5kOiB0cmFuc3BhcmVudDtcclxuXHRib3gtc2hhZG93OiBub25lICFpbXBvcnRhbnQ7XHJcblx0cGFkZGluZzogMCAxcmVtO1xyXG59IiwiaW9uLWNhcmQge1xuICAtLWlvbi1jYXJkLWJhY2tncm91bmQ6IHRyYW5zcGFyZW50O1xuICBib3gtc2hhZG93OiBub25lICFpbXBvcnRhbnQ7XG4gIHBhZGRpbmc6IDAgMXJlbTtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/layouts/auth/components/auth-card/auth-card.component.scss","webpack://./../../New%20folder/connect-4.1/src/app/layouts/auth/components/auth-card/auth-card.component.scss"],"names":[],"mappings":"AAAA;EACC,kCAAA;EACA,2BAAA;EACA,eAAA;ACCD;ADCA,ovBAAovB","sourcesContent":["ion-card {\r\n\t--ion-card-background: transparent;\r\n\tbox-shadow: none !important;\r\n\tpadding: 0 1rem;\r\n}","ion-card {\n  --ion-card-background: transparent;\n  box-shadow: none !important;\n  padding: 0 1rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
