import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-auth-checkbox',
  templateUrl: './auth-checkbox.component.html',
  styleUrls: ['./auth-checkbox.component.scss'],
})
export class AuthCheckboxComponent {
  @Input()
  public checkboxText: string;

  @Input()
  public parentForm: UntypedFormGroup;

  @Input()
  public text: string;

  @Input()
  public controlName: string;

  @Input()
  public checkedBox: string;
}
