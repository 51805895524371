import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

import {
  IDevice,
  IHardwareVersion,
  IParameterData,
  IPreviousDevice,
  stringparametertemp,
} from '@interfaces/device.interface';
import { Observable } from 'rxjs';
import { DeviceStore } from './device.store';

import { IPeriodicData } from '@interfaces/device.interface';
import { PARAMETERDATA } from '@constants/ble';
import {
  IparameterDidUpdate,
  IparameterForUpdate,
  IstringParameterForUpdate,
} from '@shared/interfaces/request.interface';

@Injectable({ providedIn: 'root' })
export class DeviceQuery extends Query<IDevice> {
  constructor(protected store: DeviceStore) {
    super(store);
  }

  deviceState$ = this.select();

  name$ = this.select((state) => state.currentDevice?.name);

  currentDevice$ = this.select((state) => state.currentDevice);

  connecting$ = this.select((state) => state.connecting);

  previousDevices$ = this.select((state) => state.previousDevices);

  devicesInRange$ = this.select((state) => state.devicesInRange);

  scanResult$ = this.select((state) => state.scanResult);

  isOldData$ = this.select((state) => state.isOldData);

  isScanning$ = this.select((state) => state.isScanning);

  isOffline$ = this.select((state) => state.isOffline);

  isScanningForPreviousDevices$ = this.select((state) => state.isScanningForPreviousDevices);

  bluetoothIsActive$ = this.select((state) => state.bluetoothIsActive);

  reConnectIn$ = this.select((state) => state.reConnectIn);

  functions$ = this.select((state) => state.functions);

  activeFunction$ = this.select((state) => state.activeFunction);

  activeFunctionMIC$ = this.select((state) => state.activeFunctionMIC); //for MIC 4.0

  periodicData$ = this.select((state) => state.periodicData);

  parameterData$ = this.select((state) => state.parameterData);

  stringParameterData$ = this.select((state) => state.stringParameterData);

  parametersForUpdate$ = this.select((state) => state.parametersForUpdate);

  stringParametersForUpdate$ = this.select((state) => state.stringParametersForUpdate);

  parametersForUpdateServiceTool$ = this.select((state) => state.parametersForUpdateServiceTool);

  parametersDidUpdateServiceTool$ = this.select((state) => state.parametersDidUpdateServiceTool);

  stringParametersForUpdateServiceTool$ = this.select((state) => state.stringParametersForUpdateServiceTool);

  stringParametersDidUpdateServiceTool$ = this.select((state) => state.stringParametersDidUpdateServiceTool);

  hardwareVersions$ = this.select((state) => state.hardwareVersions);

  autoCalibrationParameters$ = this.select((state) => state.autoCalibrationParameters);

  stopAutoCalibration$ = this.select((state) => state.stopAutoCalibrate);

  getStringParameters$ = this.select((state) => state.getStringParameters);

  timedOutParameters$ = this.select((state) => state.timedOutParameters);

  profiles$ = this.select((state) => state.profiles);

  parametersAndStringParametersForBackup$ = this.select((state) => state.parametersAndStringParametersForBackup);

  isBackuping$ = this.select((state) => state.isBackuping);

  systemType$ = this.select((state) => state?.systemType);

  specificPeriodicData$(fieldId: number): Observable<IPeriodicData> {
    return this.select(
      (state) => state.periodicData?.length > 0 && state.periodicData.find((data) => data.fieldId === fieldId)
    );
  }

  specificParameterData$(parameterId: number): Observable<IParameterData> {
    return this.select(
      (state) => state.parameterData?.length > 0 && state.parameterData.find((data) => data.parameterId === parameterId)
    );
  }

  getDataFromPeriodics$(parameterIds: number[]): Observable<IPeriodicData[]> {
    return this.select((state) => state.periodicData.filter((x) => parameterIds.includes(x.fieldId)));
  }

  getDataFromParameter$(parameterId: number): Observable<IParameterData> {
    return this.select((state) =>
      state.parameterData ? state.parameterData.find((data) => data.parameterId === parameterId) : PARAMETERDATA
    );
  }

  getDataFromParameters$(parameterIds: number[]): Observable<IParameterData[]> {
    return this.select(
      (state) => state.parameterData?.filter((x) => parameterIds.includes(x.parameterId))
      // state.parameterData ?  : [PARAMETERDATA]
    );
  }

  getLatestConnectedDevice$(): Observable<IPreviousDevice> {
    return this.select(
      (state) =>
        state.previousDevices.sort(
          (a, b) => (b.lastConnected as Date)?.getTime() - (a.lastConnected as Date)?.getTime()
        )[0]
    );
  }

  public specificParameterData(parameterId: number): IParameterData {
    //console.log(this.parameterData)

    //console.log(this.parameterData.find((data) => data.parameterId === parameterId))
    return this.parameterData.find((data) => data.parameterId === parameterId);
  }
  get parametersAndStringParametersForBackup() {
    return this.getValue().parametersAndStringParametersForBackup;
  }

  get stringParameterData(): stringparametertemp[] {
    return this.getValue().stringParameterData;
  }

  public specificPeriodicData(fieldId: number): IPeriodicData {
    return this.periodicData.find((data) => data.fieldId === fieldId);
  }

  public hwActive(ecuId: number): Observable<IHardwareVersion> {
    return this.select((state) => state.hardwareVersions.find((hw) => hw.ecuId === ecuId));
  }

  get isOldData(): boolean {
    return this.getValue().isOldData;
  }

  get periodicData(): IPeriodicData[] {
    return this.getValue().periodicData;
  }

  get parameterData(): IParameterData[] {
    return this.getValue().parameterData;
  }

  get timedOutParameters(): IParameterData[] {
    return this.getValue().timedOutParameters;
  }

  get hardwareVersions(): IHardwareVersion[] {
    return this.getValue().hardwareVersions;
  }

  get currentDevice() {
    return this.getValue().currentDevice;
  }

  get previousDevices() {
    return this.getValue().previousDevices;
  }

  get devicesInRange() {
    return this.getValue().devicesInRange;
  }

  get serviceUuid(): string {
    return this.getValue().serviceUuid;
  }

  get characteristicsUuid(): string {
    return this.getValue().characteristicsUuid;
  }

  get parametersForUpdate(): IparameterForUpdate[] {
    return this.getValue().parametersForUpdate;
  }

  get stringParametersForUpdate(): IstringParameterForUpdate[] {
    return this.getValue().stringParametersForUpdate;
  }

  get parametersForUpdateServiceTool(): IparameterForUpdate[] {
    return this.getValue().parametersForUpdateServiceTool;
  }

  get parametersDidUpdateServiceTool(): IparameterDidUpdate[] {
    return this.getValue().parametersDidUpdateServiceTool;
  }

  get stringParametersForUpdateServiceTool(): IstringParameterForUpdate[] {
    return this.getValue().stringParametersForUpdateServiceTool;
  }

  get stringParametersDidUpdateServiceTool(): IstringParameterForUpdate[] {
    return this.getValue().stringParametersDidUpdateServiceTool;
  }

  get requestUid(): string {
    return this.getValue().requestUid;
  }

  get stringRequestUid(): string {
    return this.getValue().stringRequestUid;
  }

  /*
   *For TCS*/

  get systemType() {
    return this.getValue()?.systemType;
  }

  /*********************** */
}
