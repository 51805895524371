// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-spinner {
  animation-duration: 0ms !important;
  -webkit-animation-duration: 0ms !important;
  -moz-animation-duration: 0ms !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  height: 50px;
  width: 50px;
  color: #576D7C;
}

.loading-container {
  top: 0;
  background: rgba(0, 0, 0, 0.25);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 100;
}

.menu-img {
  width: 36px;
  height: 36px;
}`, "",{"version":3,"sources":["webpack://./src/app/layouts/main/pages/change-password/change-password.component.scss","webpack://./../../New%20folder/connect-4.1/src/app/layouts/main/pages/change-password/change-password.component.scss"],"names":[],"mappings":"AAAA;EACI,kCAAA;EACA,0CAAA;EACA,uCAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,cAAA;ACCJ;;ADEA;EACI,MAAA;EACA,+BAAA;EACA,eAAA;EACA,YAAA;EACA,aAAA;EACA,YAAA;ACCJ;;ADEA;EACI,WAAA;EACA,YAAA;ACCJ","sourcesContent":["ion-spinner {\r\n    animation-duration: 0ms !important;\r\n    -webkit-animation-duration: 0ms !important;\r\n    -moz-animation-duration: 0ms !important;\r\n    top: 50%;\r\n    left: 50%;\r\n    transform: translate(-50%, -50%);\r\n    position: absolute;\r\n    height: 50px;\r\n    width: 50px;\r\n    color: #576D7C;\r\n}\r\n\r\n.loading-container {\r\n    top: 0;\r\n    background: rgba(0,0,0,0.25);\r\n    position: fixed;\r\n    width: 100vw;\r\n    height: 100vh;\r\n    z-index: 100;\r\n}\r\n\r\n.menu-img {\r\n    width: 36px;\r\n    height: 36px;\r\n}\r\n","ion-spinner {\n  animation-duration: 0ms !important;\n  -webkit-animation-duration: 0ms !important;\n  -moz-animation-duration: 0ms !important;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  position: absolute;\n  height: 50px;\n  width: 50px;\n  color: #576D7C;\n}\n\n.loading-container {\n  top: 0;\n  background: rgba(0, 0, 0, 0.25);\n  position: fixed;\n  width: 100vw;\n  height: 100vh;\n  z-index: 100;\n}\n\n.menu-img {\n  width: 36px;\n  height: 36px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
