// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item {
  border-top: 1px solid #ffd278;
  --ion-safe-area-left: 0;
  --inner-padding-end: 0;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFsYXJtLXZpZXcuY29tcG9uZW50LnNjc3MiLCIuLlxcLi5cXC4uXFwuLlxcLi5cXC4uXFwuLlxcLi5cXE5ldyUyMGZvbGRlclxcY29ubmVjdC00LjBcXHNyY1xcYXBwXFxsYXlvdXRzXFxtYWluXFxwYWdlc1xcYWxhcm0tdmlld1xcYWxhcm0tdmlldy5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNDLDZCQUFBO0VBQ0EsdUJBQUE7RUFDQSxzQkFBQTtBQ0NEIiwiZmlsZSI6ImFsYXJtLXZpZXcuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJpb24taXRlbSB7XHJcblx0Ym9yZGVyLXRvcDogMXB4IHNvbGlkICNmZmQyNzg7XHJcblx0LS1pb24tc2FmZS1hcmVhLWxlZnQ6IDA7XHJcblx0LS1pbm5lci1wYWRkaW5nLWVuZDogMDtcclxufSIsImlvbi1pdGVtIHtcbiAgYm9yZGVyLXRvcDogMXB4IHNvbGlkICNmZmQyNzg7XG4gIC0taW9uLXNhZmUtYXJlYS1sZWZ0OiAwO1xuICAtLWlubmVyLXBhZGRpbmctZW5kOiAwO1xufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/layouts/main/pages/alarm-view/alarm-view.component.scss","webpack://./../../New%20folder/connect-4.0/src/app/layouts/main/pages/alarm-view/alarm-view.component.scss"],"names":[],"mappings":"AAAA;EACC,6BAAA;EACA,uBAAA;EACA,sBAAA;ACCD;ADCA,gvBAAgvB","sourcesContent":["ion-item {\r\n\tborder-top: 1px solid #ffd278;\r\n\t--ion-safe-area-left: 0;\r\n\t--inner-padding-end: 0;\r\n}","ion-item {\n  border-top: 1px solid #ffd278;\n  --ion-safe-area-left: 0;\n  --inner-padding-end: 0;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
